import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import * as moment from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';

import { AddOrderBagModalComponent } from '../modals/add-order-bag-modal/add-order-bag-modal.component';
import { IAddressOrder, IOrderBag } from '../../../lib/lib-ngx/web-services/api.service.type';
import { IOrderAdmin } from '../../../lib/lib-ngx/web-services/api-lavandier.service.type';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { ApiService } from '../../../lib/lib-ngx/web-services/api.service';
import { UserDataVerificationModalComponent } from '../modals/user-data-verification-modal/user-data-verification-modal.component';
import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ListToMap } from '../../../lib/lib-ngx/utils/ListToMap';
import { OrderAddressType } from '../../../lib/lib-shared/types/OrderAddressType';
import { AuthService } from '../../../lib/lib-ngx/web-services/auth.service';
import { ErrorService } from '../../../lib/lib-ngx/services/error.service';
import { ArticleFamilyType } from '../../../lib/lib-shared/types/ArticleFamilyType';
import { PRICEMULTIPLIER } from '../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-counter-pick-up',
  templateUrl: './counter-pick-up.component.html',
  styleUrls: ['./counter-pick-up.component.scss']
})
export class CounterPickUpComponent implements OnInit {
  public ArticleFamilyType = ArticleFamilyType;
  public PRICEMULTIPLIER = PRICEMULTIPLIER;

  public cAndC = false;
  public userId = null;
  public eventId = null;
  public userDetails = null;
  public orderBagList: IOrderBag[] = [];
  public shopList = [];
  public articleRangeMap = new Map();
  public articleOptionMap = new Map();
  public deadlineList = [];
  public express = null;
  public billingAddressIndex = null;
  public discountCodeFormControl = new FormControl('');
  public discountCodeSelected = null;
  public discountCodeUnknown = false;

  public form: FormGroup;
  public minEstimatedDeliveryAt: NgbDateStruct = null;

  constructor(
    private apiLavandierService: ApiLavandierService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    private fb: FormBuilder,
    private errorService: ErrorService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
  ) {
  }

  ngOnInit() {
    this.cAndC = this.activatedRoute.snapshot.data.cAndC;
    this.activatedRoute.params.subscribe(
      (params) => {
        this.userId = params.userId ? parseInt(params.userId, 10) : null;
        this.eventId = params.eventId ? parseInt(params.eventId, 10) : null;
        this.loadData();
      }
    );
  }

  loadData() {
    const observable = this.eventId ? this.apiLavandierService.getIncidentDetails(this.eventId) : of(null);
    observable.pipe(
      mergeMap((incidentDetail: any) => {
        if (this.eventId) {
          this.userId = incidentDetail.order.userId;
        }
        return forkJoin([
          this.apiLavandierService.getUser(this.userId),
          this.apiLavandierService.getShops(),
          this.apiLavandierService.getArticleOptionPublicList(),
          this.apiService.getDeadlineList(),
        ]);
      })
    ).pipe(
      concatMap(([userDetails, shopList, articleOptionList, deadlineList]: [any, any[], any[], any[]]) => {
        return this.apiLavandierService.getArticleRangeUserTypeList(userDetails.userTypeId)
          .pipe(map(articleRangeList => [userDetails, shopList, articleRangeList, articleOptionList, deadlineList]));
      }),
      map(([userDetails, shopList, articleRangeList, articleOptionList, deadlineList]: [any, any[], any[], any[], any[]]) => {
        return [
          userDetails,
          shopList,
          ListToMap.convert(articleRangeList),
          ListToMap.convert(articleOptionList),
          deadlineList,
        ];
      })
    ).subscribe(([userDetails, shopList, articleRangeMap, articleOptionMap, deadlineList]) => {
      this.userDetails = userDetails;
      this.shopList = shopList;
      this.articleRangeMap = articleRangeMap;
      this.articleOptionMap = articleOptionMap;
      this.deadlineList = deadlineList;
      this.openUserVerificationModal();

      this.buildForm();
    });
  }

  buildForm() {
    const now = moment();
    const deadline = this.deadlineList.find(d => d.made === now.day());
    let daysToAdd = this.express ? deadline.express : deadline.normal;
    daysToAdd = this.cAndC ? daysToAdd.orderCAndC : daysToAdd.orderClassic;
    const estimatedDeliveryAt = now.clone().add(daysToAdd, 'days');
    this.minEstimatedDeliveryAt = this.ngbDateParserFormatter.parse(estimatedDeliveryAt.format());

    const lavandierShop = this.shopList.find(shop => shop.lavandiers.find(lavandier => lavandier.userId === this.authService.getDecodeToken().id));

    this.form = this.fb.group({
      discountCodeCode: [null],
      estimatedDeliveryAt: [this.minEstimatedDeliveryAt, Validators.required],
    });

    if (!this.cAndC) {
      this.form.addControl('shop', this.fb.control(lavandierShop ? lavandierShop : null, Validators.required));
    }

    if (this.userDetails.orderName) {
      this.form.addControl('name', this.fb.control(null, Validators.required));
    }
  }

  openUserVerificationModal() {
    const userDataVerificationModal = this.modalService.open(UserDataVerificationModalComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true
    });
    userDataVerificationModal.componentInstance.userDetails = this.userDetails;
    userDataVerificationModal.componentInstance.userAddresses = this.userDetails.addresses;
    userDataVerificationModal.result
      .then(() => null)
      .catch(() => this.router.navigate(['/user-profile', this.userId]));
  }

  get invoiceAddressList() {
    const addressList = [];
    if (this.form.value.shop) {
      addressList.push({
        ...this.form.value.shop.address,
        ...{firstName: this.userDetails.firstName, lastName: this.userDetails.lastName, phoneNumber: this.userDetails.phoneNumber},
      });
    }

    if (this.userDetails) {
      addressList.push(...this.userDetails.addresses);
    }

    return addressList;
  }

  addOrderBag() {
    const addOrderBagModal = this.modalService.open(AddOrderBagModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    addOrderBagModal.componentInstance.cAndC = this.cAndC;
    addOrderBagModal.componentInstance.orderBagList = this.orderBagList;
    addOrderBagModal.componentInstance.bagNumber = this.orderBagList.length + 1;
    addOrderBagModal.componentInstance.articleRangeMap = this.articleRangeMap;
    addOrderBagModal.componentInstance.articleOptionMap = this.articleOptionMap;
    addOrderBagModal.result
      .then((orderBag: IOrderBag) => this.orderBagList.push(orderBag))
      .catch(() => null);
  }

  onEditOrderBag(orderBag: IOrderBag) {
    const index = this.orderBagList.indexOf(orderBag);
    const editOrderBagModal = this.modalService.open(AddOrderBagModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    editOrderBagModal.componentInstance.cAndC = this.cAndC;
    editOrderBagModal.componentInstance.orderBagList = this.orderBagList;
    editOrderBagModal.componentInstance.bagNumber = index + 1;
    editOrderBagModal.componentInstance.articleRangeMap = this.articleRangeMap;
    editOrderBagModal.componentInstance.articleOptionMap = this.articleOptionMap;
    editOrderBagModal.componentInstance.articleFamilyTypeSelected = orderBag.type;
    editOrderBagModal.componentInstance.articleRangeSelected = this.articleRangeMap.get(orderBag.rangeId);
    editOrderBagModal.componentInstance.editOrderBag = orderBag;
    editOrderBagModal.result
      .then((editedOrderBag: IOrderBag) => this.orderBagList[index] = editedOrderBag)
      .catch(() => null);
  }

  onDiscountCode() {
    forkJoin(
      this.apiLavandierService.getDiscountCodeIsValid({userId: this.userDetails.id, code: this.discountCodeFormControl.value.toString()}),
      this.apiLavandierService.getSponsorshipIsValid({userId: this.userDetails.id, code: this.discountCodeFormControl.value.toString()})
    ).subscribe(([discountCode, sponsorshipCode]) => {
      if (discountCode || sponsorshipCode) {
        this.discountCodeSelected = discountCode || sponsorshipCode;
      } else {
        this.discountCodeUnknown = true;
        this.discountCodeFormControl.setValue('');
      }
    });
  }

  clearDiscountCode() {
    this.discountCodeUnknown = false;
    this.discountCodeFormControl.setValue('');
    this.discountCodeSelected = null;
  }

  onValidate() {
    const addressesData = [
      {
        type: OrderAddressType.INVOICE,
        data: this.invoiceAddressList[this.billingAddressIndex],
      },
    ];
    if (!this.cAndC) {
      addressesData.push({
        type: OrderAddressType.PICKUP,
        data: {
          ...this.form.value.shop.address,
          ...{firstName: this.userDetails.firstName, lastName: this.userDetails.lastName, phoneNumber: this.userDetails.phoneNumber},
        },
      });
    }
    const addresses = [];

    if (!this.cAndC) {
      addressesData.forEach((addressData) => {
        const address: IAddressOrder = {
          type: addressData.type,
          firstName: addressData.data.firstName,
          lastName: addressData.data.lastName,
          streetNumber: addressData.data.streetNumber,
          route: addressData.data.route,
          locality: addressData.data.locality,
          country: addressData.data.country,
          postalCode: addressData.data.postalCode,
          formattedAddress: addressData.data.formattedAddress,
          lat: addressData.data.lat,
          lng: addressData.data.lng,
          phoneNumber: addressData.data.phoneNumber,
          additional: addressData.data.additional,
        };
        addresses.push(address);
      });
    }

    const now = moment();
    const order: IOrderAdmin = {
      userId: this.userId,
      incidentId: this.eventId,
      express: this.express,
      discountCodeCode: this.discountCodeSelected !== null ? this.discountCodeSelected.code : null,
      stripeCardId: null,
      name: this.userDetails.orderName ? this.form.value.name : null,
      estimatedDeliveryAt: this.ngbDateParserFormatter.format(this.form.value.estimatedDeliveryAt),
      date: now.format(),
      start: now.format('HH:mm:ss'),
      end: now.add(+1, 'seconds').format('HH:mm:ss'),
      addresses: addresses,
      bagList: this.orderBagList,
      inShopId: !this.cAndC ? this.form.value.shop.id : null,
    };

    this.apiLavandierService.postOrder(order)
      .subscribe((data: any) => {
        if (!this.errorService.manageError(data)) {
          const confirmationModal = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
          confirmationModal.componentInstance.confirmationDesc = 'Enlèvement effectué !';
          confirmationModal.componentInstance.validateButtonText = 'Ok';
          confirmationModal.result
            .then(() => this.router.navigate(['/order-details', data.order.id]))
            .catch(() => this.router.navigate(['/order-details', data.order.id]));
        }
      });
  }
}
