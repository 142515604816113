import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';

import { OrderAddressType } from '../../../../lib/lib-shared/types/OrderAddressType';
import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { OrderTransportType } from '../../../../lib/lib-shared/types/OrderTransportType';

@Component({
  selector: 'lm-quarterback-order-details',
  templateUrl: './quarterback-order-details.component.html',
  styleUrls: ['./quarterback-order-details.component.scss']
})
export class QuarterbackOrderDetailsComponent implements OnInit {
  @Input() public trackingSheetStorageList: any[] = [];
  @Input() public order: any = null;
  @Input() public quarterbacking: boolean;

  @Output() missingTrackingSheetStorage = new EventEmitter<any>();

  public shopMap = new Map();

  constructor(
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.loadShopMap();
  }

  loadShopMap() {
    this.apiLavandierService.getShops()
      .pipe(map(ListToMap.convert))
      .subscribe(
        (shopMap: Map<number, any>) => this.shopMap = shopMap
      );
  }

  getTrackingSheetStoredNumber() {
    let storedNumber = 0;

    this.trackingSheetStorageList.forEach(trackingSheetStorage => {
      if (this.quarterbacking) {
        if (trackingSheetStorage.preparedAt) {
          storedNumber = storedNumber + 1;
        }
      } else {
        if (trackingSheetStorage.tidiedAt) {
          storedNumber = storedNumber + 1;
        }
      }
    });

    return storedNumber;
  }

  getDeliveryAddress() {
    const orderAddressListOfType = this.order.orderAddresses.filter(orderAddress => orderAddress.type === OrderAddressType.DELIVERY);
    return orderAddressListOfType[orderAddressListOfType.length - 1];
  }

  getDeliveryTransport() {
    const orderTransportListOfType = this.order.orderTransports.filter(orderTransport => orderTransport.type === OrderTransportType.DELIVERY);
    return orderTransportListOfType[orderTransportListOfType.length - 1];
  }
}
