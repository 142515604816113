import { Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TitleCasePipe } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-new-lavandier',
  templateUrl: './new-lavandier.component.html',
  styleUrls: ['./new-lavandier.component.scss']
})
export class NewLavandierComponent implements OnInit {
  public newLavandierForm: FormGroup;
  public shopList = [];
  public lavandierMap = new Map();
  public userDetails = null;

  @ViewChild('addressElementRef')
  public addressElementRef: ElementRef;
  public address: google.maps.places.PlaceResult = null;

  constructor(
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private renderer: Renderer2,
    public router: Router,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.loadShopList();
    this.loadLavandierMap();

    this.search = this.search.bind(this);
    this.formatter = this.formatter.bind(this);
  }

  buildForm() {
    this.newLavandierForm = this.fb.group({
      existingUser: this.fb.group({
        shop: [null],
        admin: [false],
        internalAdmin: [false],
      }),
      newUser: this.fb.group({
        lastName: ['', Validators.required],
        firstName: ['', Validators.required],
        address: ['', Validators.required],
        phoneNumber: ['', Validators.required],
        mail: ['', Validators.required],
        password: ['', Validators.required],
        shop: ['', Validators.required]
      })
    });
    // this.initGMaps(); todo remove comment when add user not exist feature
  }

  initGMaps() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.addressElementRef.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          this.address = autocomplete.getPlace();
        });
      });
      this.renderer.listen(this.addressElementRef.nativeElement, 'change', () => {
        this.ngZone.run(() => {
          this.address = null;
        });
      });
    });
  }

  /**
   * Method called to get the shop list
   */
  loadShopList() {
    this.apiLavandierService.getShops()
      .subscribe(
        (shopList: Object[]) => this.shopList = shopList
      );
  }

  loadLavandierMap() {
    this.apiLavandierService.getLavandiers()
      .pipe(map((lavandierList: any) => ListToMap.list(lavandierList.rows, 'userId')))
      .subscribe(
        (lavandierMap: Map<number, Object>) => this.lavandierMap = lavandierMap
      );
  }

  search(text: Observable<any>): Observable<any> {
    return text.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(inputData => {
        this.userDetails = null;
        if (inputData !== '') {
          let obs = this.apiLavandierService.getUserList({name: inputData});
          if (!isNaN(inputData)) {
            obs = this.apiLavandierService.getUserList({id: inputData});
          }
          return obs.pipe(map((data: any) => data.rows.filter(user => !this.lavandierMap.has(user.id))));
        } else {
          return of([]);
        }
      })
    );
  }

  formatter(user: any) {
    return new TitleCasePipe().transform(user.id + ' ' + user.lastName + ' ' + user.firstName);
  }

  selectUser(user) {
    this.userDetails = null;
    if (!this.lavandierMap.has(user.id)) {
      this.userDetails = user;
    }
  }

  /**
   * Method called to add a new lavandier
   * @param lavandierForm Must be newLavandierForm.controls['existingUser'] or newLavandierForm.controls['newUser']
   */
  onNewLavandier(lavandierForm) {
    this.apiLavandierService.postLavandier({
      userId: this.userDetails.id,
      shopId: this.newLavandierForm.controls.existingUser.value.shop !== null ? this.newLavandierForm.controls.existingUser.value.shop.id : null,
      admin: this.newLavandierForm.controls.existingUser.value.admin,
      internalAdmin: this.newLavandierForm.controls.existingUser.value.internalAdmin,
    })
      .subscribe((lavandier: any) => this.router.navigate(['/lavandier/details', lavandier.id]));
  }
}
