import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiLavandierService } from '../../lib/lib-ngx/web-services/api-lavandier.service';

@Pipe({
  name: 'lmUpload'
})
export class LmUploadPipe implements PipeTransform {

  constructor(private apiLavandierService: ApiLavandierService) {
  }

  transform(uploadId: number) {

    return new Observable<string>((observer) => {
      this.apiLavandierService.getUpload(uploadId)
        .subscribe(
          (data: Blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function () {
              observer.next(reader.result);
            };
          }
        );

      return {
        unsubscribe() {
        }
      };
    });
  }
}
