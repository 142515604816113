import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { IDiscountCode } from '../../../../lib/lib-ngx/web-services/api-lavandier.service.type';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { DiscountCodeType } from '../../../../lib/lib-shared/types/DiscountCodeType';
import { PRICEMULTIPLIER } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-discount-code-add',
  templateUrl: './discount-code-add.component.html',
  styleUrls: ['./discount-code-add.component.scss']
})
export class DiscountCodeAddComponent implements OnInit {
  public DiscountCodeType = DiscountCodeType;

  public discountForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private apiLavandierService: ApiLavandierService,
    private router: Router,
    private ngbDateParserFormatter: NgbDateParserFormatter,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.discountForm = this.fb.group({
      type: [DiscountCodeType.PERCENT, Validators.required],
      amount: [null, Validators.required],
      minOrder: [null, Validators.required],
      code: ['', Validators.required],
      description: ['', Validators.required],
      onlyFirstOrder: [false, Validators.required],
      uniqueByUser: [false, Validators.required],
      unique: [false, Validators.required],
      start: [null, Validators.required],
      end: [null, Validators.required],
    });
  }

  onSubmit() {
    const discountCode: IDiscountCode = {
      type: this.discountForm.value.type,
      amount: this.discountForm.value.amount * PRICEMULTIPLIER,
      minOrder: this.discountForm.value.minOrder * PRICEMULTIPLIER,
      code: this.discountForm.value.code,
      description: this.discountForm.value.description,
      onlyFirstOrder: this.discountForm.value.onlyFirstOrder,
      uniqueByUser: this.discountForm.value.uniqueByUser,
      unique: this.discountForm.value.unique,
      start: this.ngbDateParserFormatter.format(this.discountForm.value.start),
      end: this.ngbDateParserFormatter.format(this.discountForm.value.end),
    };

    this.apiLavandierService.postDiscountCode(discountCode)
      .subscribe(
        () => this.router.navigate(['/discount-code/list'])
      );
  }

  markAllAsTouched() {
    Object.keys(this.discountForm.controls).forEach(key => {
      this.discountForm.get(key).markAsTouched();
    });
  }
}
