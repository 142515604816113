import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { NgbCalendar, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ErrorService } from '../../../../lib/lib-ngx/services/error.service';
import { AddOrderWantedDeliveryModalComponent } from '../../modals/add-order-wanted-delivery-modal/add-order-wanted-delivery-modal.component';
import { OrderStoredModalComponent } from '../../modals/order-stored-modal/order-stored-modal.component';
import { OrderTemporary } from '../../../../lib/lib-shared/types/OrderTemporary';
import { QuarterbackTransferModalComponent } from '../../modals/quarterback-transfer-modal/quarterback-transfer-modal.component';

@Component({
  selector: 'lm-order-wanted-delivery',
  templateUrl: './order-wanted-delivery.component.html',
  styleUrls: ['./order-wanted-delivery.component.scss']
})
export class OrderWantedDeliveryComponent implements OnInit {
  public OrderTemporary = OrderTemporary;

  public orderList = [];
  public shopList = [];

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private apiLavandierService: ApiLavandierService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.loadData();
    this.form.updateValueAndValidity();
  }

  loadData() {
    return forkJoin([
      this.apiLavandierService.getShops(),
    ])
      .pipe(
        map(([shopList]: [any[]]) => {
          return [
            shopList,
          ];
        })
      )
      .subscribe(([shopList]) => {
        this.shopList = shopList;
      });
  }

  buildForm() {
    this.form = this.fb.group({
      date: [this.calendar.getToday()],
      shop: [null],
    });

    this.form.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(data => {
          return this.apiLavandierService.getOrderList({
            estimatedDeliveryAt: this.ngbDateParserFormatter.format(data.date),
            pickupShopId: data.shop ? data.shop.id : '',
          });
        }),
      )
      .subscribe((orderList: any) => {
        this.orderList = orderList.rows;
      });
  }

  openOrderStoredModal(order) {
    return forkJoin([
      this.apiLavandierService.getOrderIdDetails(order.id),
      this.apiLavandierService.getTrackingSheetOrderId(order.id),
    ])
      .subscribe(([orderDetails, trackingSheetList]: [any, any[]]) => {
        const trackingSheetStorageList = [];
        trackingSheetList.forEach(trackingSheet => {
          trackingSheetStorageList.push(trackingSheet.trackingSheetStorageList[trackingSheet.trackingSheetStorageList.length - 1]);
        });

        const orderStoredModalComponent = this.modalService.open(OrderStoredModalComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static',
          keyboard: false
        });
        orderStoredModalComponent.componentInstance.order = orderDetails;
        orderStoredModalComponent.componentInstance.trackingSheetStorageList = trackingSheetStorageList;
        orderStoredModalComponent.result
          .then(() => null)
          .catch(() => null);
      });
  }

  openAddOrderWantedDeliveryModal(order) {
    const addOrderWantedDeliveryModalComponent = this.modalService.open(AddOrderWantedDeliveryModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    addOrderWantedDeliveryModalComponent.componentInstance.order = order;
    addOrderWantedDeliveryModalComponent.result
      .then((res) => this.apiLavandierService.postOrderIdOrderWantedDelivery(order.id, {
        addNoShowOrderLine: res.addNoShowOrderLine,
        wantedDeliveryDate: res.date,
      })
        .subscribe(data => {
          if (!this.errorService.manageError(data)) {
            this.ngOnInit();
          }
        }))
      .catch(() => null);
  }

  openTransferModal(order) {
    const quarterbackTransferModal = this.modalService.open(QuarterbackTransferModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    quarterbackTransferModal.componentInstance.orderId = order.id;
    quarterbackTransferModal.result
      .then(() => null)
      .catch(() => null);
  }
}
