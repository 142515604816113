import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { PRICEMULTIPLIER } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-membership-discount-setting',
  templateUrl: './membership-discount-setting.component.html',
  styleUrls: ['./membership-discount-setting.component.scss']
})
export class MembershipDiscountSettingComponent implements OnInit {
  public PRICEMULTIPLIER = PRICEMULTIPLIER;

  public membershipDiscountList = null;
  public rangeMap = new Map();

  constructor(
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.loadMemberDiscount();
  }

  loadMemberDiscount() {
    forkJoin([
      this.apiLavandierService.getMembershipDiscountList(),
      this.apiLavandierService.getArticleRangeList(),
    ]).pipe(map(([membershipDiscount, articleRangeList]: [any, any[]]) => {
      return [
        membershipDiscount,
        ListToMap.convert(articleRangeList),
      ];
    })).subscribe(([membershipDiscount, articleRangeList]) => {
      this.membershipDiscountList = membershipDiscount;
      this.rangeMap = articleRangeList;
    });
  }
}
