import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Page } from '../../../../lib/lib-ngx/utils/Page';

@Component({
  selector: 'lm-lavandier-list',
  templateUrl: './lavandier-list.component.html',
  styleUrls: ['./lavandier-list.component.scss']
})
export class LavandierListComponent implements OnInit {
  public lavandierListForm: FormGroup;
  public lavandierList = [];
  public shopList = [];
  public page = new Page();

  @ViewChild(DatatableComponent)
  table: DatatableComponent;

  constructor(
    private fb: FormBuilder,
    private apiLavandierService: ApiLavandierService,
    public router: Router,
  ) {
    this.page.setPageNumber(0);
    this.page.size = 10;
  }

  ngOnInit() {
    this.buildForm();
    this.loadLavandierList();
    this.loadShopList();

    this.lavandierListForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(data => {
          this.page.checkPage();
          return this.apiLavandierService.getLavandiers({
            name: data.name,
            admin: data.admin,
            enabled: data.enabled,
            internalAdmin: data.internalAdmin,
            shopId: data.shop ? data.shop.id : null,
          }, this.page.toQueryObject());
        }),
      )
      .subscribe((lavandierList: any) => {
        this.page.totalElements = lavandierList.count;
        this.page.totalPages = this.page.totalElements / this.page.size;
        this.lavandierList = lavandierList.rows;
      });
    this.lavandierListForm.updateValueAndValidity();
  }

  buildForm() {
    this.lavandierListForm = this.fb.group({
      name: [''],
      shop: [null],
      enabled: [null],
      admin: [null],
      internalAdmin: [null],
    });
  }

  setPage(pageInfo) {
    this.page.setPageNumber(pageInfo.offset);
    this.lavandierListForm.updateValueAndValidity();
  }

  /**
   * Method called to get the lavandier list
   */
  loadLavandierList() {
    this.apiLavandierService.getLavandiers()
      .subscribe(
        (data: any) => {
          this.lavandierList = data.rows;
        }
      );
  }

  /**
   * Method called to get the shop list
   */
  loadShopList() {
    this.apiLavandierService.getShops()
      .subscribe(
        (data: Object[]) => this.shopList = data
      );
  }

  /**
   * Method called after a lavandier's current lmp change
   * @param lavandier Which lavandier
   * @param newLMP New LMP name
   */
  changeLavandierLMP(lavandier, newLMP) {
    const lmp = newLMP === '' ? null : newLMP;
    this.apiLavandierService.putLavandier(lavandier.id, {shopId: lmp}).subscribe(
      () => this.ngOnInit()
    );
  }
}
