import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

export const Messages = {
  retard_SAV: {
    title: 'retard de commande SAV sans délai indicatif ',
    sms: 'Oups, toutes nos excuses, le traitement de votre SAV a nécessité un traitement plus long, ' +
      'vous recevrez un SMS lorsqu\'il sera prêt !',
    email: 'Cher(e) client(e), <br>' +
      'Toutes nos excuses, le traitement de votre SAV a nécessité un traitement plus long, vous recevrez un SMS lorsqu\'il sera prêt !<br>' +
      '<br>' +
      'Nous nous excusons pour la gêne occasionnée.<br>' +
      '<br>' +
      'Merci pour votre confiance,<br>' +
      'L’équipe du Lavoir Moderne<br>'
  },
  retard_lavandier: {
    title: 'retard retrait ou livraison sans délai indicatif',
    sms: 'Oups, toutes nos excuses, votre Lavandier a un peu de retard mais il arrive au plus vite ! Une question ? ' +
      'contact@lavoirmoderne.com ou 01 76 39 03 79',
    email: 'Cher(e) client(e),<br>' +
      '<br>' +
      'Toutes nos excuses, votre Lavandier a un peu de retard mais il arrive au plus vite ! <br>' +
      'Vous recevrez un SMS dès que votre Lavandier aura pris en charge votre commande !<br>' +
      '<br>' +
      'Si toutefois vous n’étiez plus disponible, n\'hésitez pas à nous contacter au 01 76 39 03 79.<br>' +
      '<br>' +
      'Merci de votre confiance,<br>' +
      'L’équipe du Lavoir Moderne<br>'
  },
  retard_lavandier_5m: {
    title: 'retard retrait ou livraison estimé à 5 minutes ',
    sms: 'Oups, toutes nos excuses, votre Lavandier a un retard estimé de 5 minutes. ' +
      'Vous recevrez un SMS dès que votre Lavandier aura pris en charge votre commande !',
    email: 'Cher(e) client(e),<br>' +
      '<br>' +
      'Nous sommes navrés de vous informer que votre Lavandier a un retard estimé à 5 minutes. <br>' +
      'Vous recevrez un SMS dès que votre Lavandier aura pris en charge votre commande !<br>' +
      '<br>' +
      'Si toutefois vous vous n’étiez plus disponible, n\'hésitez  pas à nous contacter au 01 76 39 03 79.<br>' +
      '<br>' +
      'Merci de votre confiance,<br>' +
      'L’équipe du Lavoir Moderne<br>'
  },
  retard_lavandier_10m: {
    title: 'retard retrait ou livraison estimé à 10 minutes ',
    sms: 'Oups, toutes nos excuses, votre Lavandier a un retard estimé de 10 minutes. ' +
      'Vous recevrez un SMS dès que votre Lavandier aura pris en charge votre commande !',
    email: 'Cher(e) client(e),<br>' +
      '<br>' +
      'Nous sommes navrés de vous informer que votre Lavandier a un retard estimé à 10 minutes. <br>' +
      'Vous recevrez un SMS dès que votre Lavandier aura pris en charge votre commande !<br>' +
      '<br>' +
      'Si toutefois vous vous n’étiez plus disponible, n\'hésitez  pas à nous contacter au 01 76 39 03 79.<br>' +
      '<br>' +
      'Merci de votre confiance,<br>' +
      'L’équipe du Lavoir Moderne<br>'
  },
  retard_lavandier_15m: {
    title: 'retard retrait ou livraison estimé à 15 minutes ',
    sms: 'Oups, toutes nos excuses, votre Lavandier a un retard estimé de 15 minutes. ' +
      'Vous recevrez un SMS dès que votre Lavandier aura pris en charge votre commande !',
    email: 'Cher(e) client(e),<br>' +
      '<br>' +
      'Nous sommes navrés de vous informer que votre Lavandier a un retard estimé à 15 minutes. <br>' +
      'Vous recevrez un SMS dès que votre Lavandier aura pris en charge votre commande !<br>' +
      '<br>' +
      'Si toutefois vous vous n’étiez plus disponible, n\'hésitez  pas à nous contacter au 01 76 39 03 79.<br>' +
      '<br>' +
      'Merci de votre confiance,<br>' +
      'L’équipe du Lavoir Moderne<br>'
  },
  retard_lavandier_30m: {
    title: 'retard retrait ou livraison estimé à 30 minutes ',
    sms: 'Oups, toutes nos excuses, votre Lavandier a un retard estimé de 30 minutes. ' +
      'Vous recevrez un SMS dès que votre Lavandier aura pris en charge votre commande !',
    email: 'Cher(e) client(e),<br>' +
      '<br>' +
      'Nous sommes navrés de vous informer que votre Lavandier a un retard estimé à 30 minutes. <br>' +
      'Vous recevrez un SMS dès que votre Lavandier aura pris en charge votre commande !<br>' +
      '<br>' +
      'Si toutefois vous vous n’étiez plus disponible, n\'hésitez  pas à nous contacter au 01 76 39 03 79.<br>' +
      '<br>' +
      'Merci de votre confiance,<br>' +
      'L’équipe du Lavoir Moderne<br>'
  },
  retard_lavandier_45m: {
    title: 'retard de retrait ou livraison estimé à 45 minutes ',
    sms: 'Oups, toutes nos excuses, votre Lavandier a un retard estimé à 45 minutes. ' +
      'Vous recevrez un SMS dès que votre Lavandier aura pris en charge votre commande !',
    email: 'Cher(e) client(e), <br>' +
      '<br>' +
      'Nous sommes navrés de vous informer que votre lavandier a un retard estimé à 45 minutes. <br>' +
      'Vous recevrez un SMS dès que votre Lavandier aura pris en charge votre commande !<br>' +
      '<br>' +
      'Si toutefois vous n’étiez plus disponible après votre créneau, n\'hésitez pas à nous contacter au 01 76 39 03 79.<br>' +
      '<br>' +
      'Merci pour votre confiance,<br>' +
      'L\'équipe du Lavoir Moderne.<br>'
  },
  retard_mouvements_sociaux: {
    title: 'retard retrait ou livraison mouvements sociaux sans délai indicatif',
    sms: 'Bonjour, nos Lavandiers rencontrent des difficultés de circulation suite aux mouvements sociaux. ' +
      'Vous recevrez un SMS dès que votre Lavandier sera en route !',
    email: 'Cher(e) client(e), <br>' +
      '<br>' +
      'Nous vous informons que suite aux différents mouvements sociaux qui se produisent actuellement sur l’intégralité de la région ' +
      'parisienne, nos Lavandiers rencontrent des difficultés de circulation. Vous recevrez un SMS dès que votre ' +
      'Lavandier aura pris en charge votre commande.<br>' +
      '<br>' +
      'Si toutefois vous n’étiez plus disponible après votre créneau, n\'hésitez à nous contacter au 01 76 39 03 79 ' +
      'ou à reprogrammer votre demande sur notre site www.lavoirmoderne.com<br>' +
      '<br>' +
      'Nous nous excusons pour la gêne occasionnée.<br>' +
      '<br>' +
      'Merci pour votre confiance,<br>' +
      'L’équipe du Lavoir Moderne<br>'
  },
  absent: {
    title: 'client absent retrait ou livraison ',
    sms: 'Bonjour, votre Lavandier s’est présenté à votre domicile et nous n\'avons pas réussi à vous joindre. ' +
      'N’hésitez pas à vous programmer à nouveau sur notre site !',
    email: 'Cher(e) client(e), <br>' +
      '<br>' +
      'Nous vous informons que votre Lavandier s’est présenté à votre domicile et nous n\'avons malheureusement pas ' +
      'réussi à vous joindre.<br><br>' +
      'N’hésitez pas à vous reprogrammer sur notre site www.lavoirmoderne.com !<br>' +
      '<br>' +
      'Merci de votre confiance,<br>' +
      'L\'équipe du Lavoir Moderne.<br>'
  },
  not_paid_order: {
    title: 'commande non payée',
    sms: 'Votre commande est prete et nous pourrions vous la livrer des aujourd\'hui. Pour cela, il vous faut la régler des que possible : rendez-vous dans votre compte.',
    email: 'Cher(e) client(e), <br>' +
      '<br>' +
      'Votre commande est prete et nous pourrions vous la livrer des aujourd\'hui. Pour cela, il vous faut la régler des que possible : rendez-vous dans votre compte.<br>' +
      '<br>' +
      'Merci de votre confiance,<br>' +
      'L\'équipe du Lavoir Moderne.<br>'
  },
  cancel_ordertransport: {
    title: 'Annulation Retrait/livraison',
    sms: 'Malheureusement votre Lavandier ne peut pas passer ce jour. Nous vous présentons nos excuses et vous invitons à vous reprogrammer.',
    email: 'Cher(e) client(e), <br>' +
      '<br>' +
      'À notre grand regret, nous ne serons malheureusement pas en mesure de faire la livraison ou le retrait de votre linge aujourd’hui.<br>' +
      'Nous vous remercions de bien vouloir vous reprogrammer pour demain ou à une date ultérieure, selon vos disponibilités.<br>' +
      '<br>' +
      'Cette situation, exceptionnelle, est loin des standards Qualité du Lavoir Moderne, et nous faisons notre possible pour ne pas laisser ce genre d’incidents affecter l’expérience de nos clients de nouveau.<br>' +
      'En comptant sur votre fidélité, l’équipe du Lavoir Moderne reste à votre disposition pour toute information complémentaire.<br>' +
      '<br>' +
      'Merci de votre confiance,<br>' +
      'L\'équipe du Lavoir Moderne.<br>'
  },
};

@Component({
  selector: 'lm-user-profile-send-message-modal',
  templateUrl: './user-profile-send-message-modal.component.html',
  styleUrls: ['./user-profile-send-message-modal.component.scss']
})

export class UserProfileSendMessageModalComponent implements OnInit {
  public user;
  keys = Object.keys;
  messages = Messages;
  @ViewChild('attachment') attachment;
  public sendMessageForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.sendMessageForm = this.fb.group({
      type: ['', Validators.required],
      sms: true,
      email: true,
    });
  }

  onSubmit() {
    const params = {
      userId: this.user.id,
      messageSMS: this.sendMessageForm.value.sms ? this.messages[this.sendMessageForm.value.type].sms : null,
      messageEmail: this.sendMessageForm.value.email ? this.messages[this.sendMessageForm.value.type].email : null,
    };
    this.apiLavandierService.postNotification(params)
      .subscribe(() => this.activeModal.close());
  }

}
