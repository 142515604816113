import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { OrderTransportType } from '../../../../lib/lib-shared/types/OrderTransportType';
import { SearchFilter, SearchFilterName, SearchFilterService } from '../../../services/search-filter.service';

@Component({
  selector: 'lm-quarterbacking-list',
  templateUrl: './quarterbacking-list.component.html',
  styleUrls: ['./quarterbacking-list.component.scss']
})
export class QuarterbackingListComponent implements OnInit {
  public filterForm: FormGroup;
  public orderTransportList = [];
  public shopList = [];
  public lavandierMap = new Map();
  public prepared = false;

  constructor(
    private apiLavandierService: ApiLavandierService,
    private fb: FormBuilder,
    private calendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private searchFilterService: SearchFilterService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.loadShopList();
    this.loadLavandiersMap();

    this.filterForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(filterData => {
          if (moment().isSame(moment(this.ngbDateParserFormatter.format(filterData.date)), 'days')) {
            this.searchFilterService.set(SearchFilter.QUARTERBACK, SearchFilterName.DATE, null);
          } else {
            this.searchFilterService.set(SearchFilter.QUARTERBACK, SearchFilterName.DATE, filterData.date);
          }
          this.searchFilterService.set(SearchFilter.QUARTERBACK, SearchFilterName.SHOP, filterData.shop);

          return this.apiLavandierService.getOrderTransportList({
            canceled: false.toString(),
            simultaneous: false.toString(),
            shopId: filterData.shop !== null ? filterData.shop.id : '',
            type: OrderTransportType.DELIVERY,
            inShop: false.toString(),
            date: this.ngbDateParserFormatter.format(filterData.date),
          });
        }),
      )
      .subscribe((orderTransportList: any) => {
        this.orderTransportList = orderTransportList
          .filter(orderTransport => {
            const trackingSheetNotPrepared = orderTransport.trackingSheetStorageList.some(trackingSheetStorage => {
              return !trackingSheetStorage.preparedAt;
            });
            if (this.prepared && !trackingSheetNotPrepared) {
              return true;
            } else if (!this.prepared && trackingSheetNotPrepared) {
              return true;
            }
            return false;
          });
      });
    this.filterForm.updateValueAndValidity();
  }

  compareById(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  buildForm() {
    this.filterForm = this.fb.group({
      date: [this.searchFilterService.get(SearchFilter.QUARTERBACK, SearchFilterName.DATE) ? this.searchFilterService.get(SearchFilter.QUARTERBACK, SearchFilterName.DATE) : this.calendar.getToday()],
      shop: [this.searchFilterService.get(SearchFilter.QUARTERBACK, SearchFilterName.SHOP) ? this.searchFilterService.get(SearchFilter.QUARTERBACK, SearchFilterName.SHOP) : null],
    });
  }

  loadShopList() {
    this.apiLavandierService.getShops()
      .subscribe(
        (shopList: Object[]) => this.shopList = shopList
      );
  }

  loadLavandiersMap() {
    this.apiLavandierService.getLavandiers()
      .pipe(map((lavandierList: any) => ListToMap.convert(lavandierList.rows)))
      .subscribe(
        (lavandierMap: Map<number, Object>) => this.lavandierMap = lavandierMap
      );
  }

  changePrepared(prepared: boolean) { // todo button should be in form to use valueChanges
    this.prepared = prepared;
    this.filterForm.updateValueAndValidity();
  }

  getAddress(orderTransport) {
    const sameTypeOrderAddress = orderTransport.order.orderAddresses.filter(orderAddress => orderAddress.type === orderTransport.type);
    return sameTypeOrderAddress[sameTypeOrderAddress.length - 1];
  }

  statusOrder(orderTransport): string {
    let ratio = 1;
    if (!this.prepared) {
      ratio = 2;
    }
    if ((moment.duration(moment(orderTransport.date + ' ' + orderTransport.start).diff(moment())).asMinutes() <= 10 * ratio) ||
      orderTransport.lavandierId !== null) {
      return 'quarterbacking-order-transport-status-red';
    } else if (moment.duration(moment(orderTransport.date + ' ' + orderTransport.start).diff(moment())).asMinutes() <= 20 * ratio &&
      moment.duration(moment(orderTransport.date + ' ' + orderTransport.start).diff(moment())).asMinutes() > 10 * ratio) {
      return 'quarterbacking-order-transport-status-orange';
    }
    return 'quarterbacking-order-transport-status-green';
  }
}
