import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IOrderBag } from '../../../../lib/lib-ngx/web-services/api.service.type';
import { ArticleFamilyType } from '../../../../lib/lib-shared/types/ArticleFamilyType';

@Component({
  selector: 'lm-add-order-bag-modal',
  templateUrl: './add-order-bag-modal.component.html',
  styleUrls: ['./add-order-bag-modal.component.scss']
})
export class AddOrderBagModalComponent implements OnInit {
  public ArticleFamilyType = ArticleFamilyType;

  public cAndC = false;
  public orderBagList = [];
  public bagNumber = 0;
  public articleRangeMap = new Map();
  public articleOptionMap = [];
  public articleOptionFilteredList = [];
  public articleFamilyTypeSelected = null;
  public articleRangeSelected = null;
  public editOrderBag: IOrderBag = null;
  public orderBagModalForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    public domSanitizer: DomSanitizer,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.orderBagModalForm = this.fb.group({
      comment: [''],
      ref: ['', !this.cAndC ? Validators.compose([Validators.required, this.uniqueRefValidator()]) : null],
    });
    this.articleOptionMap
      .forEach((articleOption) => this.orderBagModalForm.addControl(articleOption.id.toString(), new FormControl(false)));
    if (this.editOrderBag !== null) {
      this.refreshArticleOptionFilteredList();
      this.patchValue();
    }
  }

  uniqueRefValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = this.orderBagList.some((bag, key) => bag.ref === control.value && this.bagNumber - 1 !== key);
      return forbidden ? {'uniqueRef': {value: control.value}} : null;
    };
  }

  patchValue() {
    this.orderBagModalForm.patchValue({
      ref: this.editOrderBag.ref,
      comment: this.editOrderBag.comment,
    });
    this.editOrderBag.articleOptionIdList
      .forEach((articleOptionId) => this.orderBagModalForm.patchValue({[articleOptionId.toString()]: true}));
  }

  onArticleFamilyTypeSelected(articleFamilyTypeSelected) {
    this.articleFamilyTypeSelected = articleFamilyTypeSelected;
    this.refreshArticleOptionFilteredList();
  }

  refreshArticleOptionFilteredList() {
    this.articleOptionFilteredList = [];

    this.articleOptionMap.forEach((articleOption) => {
      const articleOfSameType = articleOption.articles.find((article) => article.articleFamily.type === this.articleFamilyTypeSelected);
      if (articleOfSameType !== undefined) {
        this.articleOptionFilteredList.push(articleOption);
      }
    });
  }

  onValidate() {
    const orderBag: IOrderBag = {
      rangeId: this.articleRangeSelected.id,
      parentId: null,
      articleOptionIdList: [],
      type: this.articleFamilyTypeSelected,
      comment: this.orderBagModalForm.controls['comment'].value ? this.orderBagModalForm.controls['comment'].value : null,
      ref: this.orderBagModalForm.controls['ref'].value,
    };
    this.articleOptionFilteredList.forEach((articleOption) => {
      if (this.orderBagModalForm.controls[articleOption.id.toString()].value) {
        orderBag.articleOptionIdList.push(articleOption.id);
      }
    });
    this.activeModal.close(orderBag);
  }
}
