import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { Page } from '../../../../lib/lib-ngx/utils/Page';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { IncidentTypeSeverity } from '../../../../lib/lib-shared/types/IncidentTypeSeverity';
import { IncidentOrigin } from '../../../../lib/lib-shared/types/IncidentOrigin';

@Component({
  selector: 'lm-incident-list',
  templateUrl: './incident-list.component.html',
  styleUrls: ['./incident-list.component.scss'],
})
export class IncidentListComponent implements OnInit {
  page = new Page();
  public IncidentOrigin = IncidentOrigin;
  public IncidentTypeSeverity = IncidentTypeSeverity;

  public filterForm: FormGroup;
  public incidentList = [];
  public incidentTypeMap = new Map();
  public shopMap = new Map();
  public lavandierMap = new Map();

  constructor(
    private fb: FormBuilder,
    private apiLavandierService: ApiLavandierService,
    public router: Router,
  ) {
    this.page.setPageNumber(0);
    this.page.size = 10;
  }

  ngOnInit() {
    this.loadData();
    this.buildForm();

    this.filterForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(data => {
          this.page.checkPage();
          return this.apiLavandierService.getIncidentList({
            id: data.id ? data.id : '',
            orderId: data.orderId ? data.orderId : '',
            assignLavandierId: data.assignLavandierId,
            typeId: data.type,
            finish: data.finish,
            origin: data.origin,
            name: data.name,
            severity: data.severity,
          }, this.page.toQueryObject());
        }),
      )
      .subscribe((incidentList: any) => {
        this.page.totalElements = incidentList.count;
        this.page.totalPages = this.page.totalElements / this.page.size;
        this.incidentList = incidentList.rows;
      });
    this.filterForm.updateValueAndValidity();
  }

  setPage(pageNumber: number) {
    this.page.setPageNumber(pageNumber);
    this.filterForm.updateValueAndValidity();
  }

  loadData() {
    return forkJoin([
      this.apiLavandierService.getShops(),
      this.apiLavandierService.getLavandiers(),
      this.apiLavandierService.getIncidentTypes(),
    ])
      .pipe(
        map(([shopList, lavandierList, incidentTypeList]: any[]) => {
          return [
            ListToMap.convert(shopList as any[]),
            ListToMap.convert(lavandierList.rows as any[]),
            ListToMap.convert(incidentTypeList as any[]),
          ];
        })
      )
      .subscribe(([shopMap, lavandierMap, incidenTypetMap]) => {
        this.shopMap = shopMap as any;
        this.lavandierMap = lavandierMap as any;
        this.incidentTypeMap = incidenTypetMap as any;
      });
  }

  buildForm() {
    this.filterForm = this.fb.group({
      id: [''],
      name: [''],
      orderId: [''],
      assignLavandierId: [null],
      finish: [false],
      origin: [''],
      severity: [''],
      type: [''],
    });
  }
}
