import { Component, OnInit } from '@angular/core';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';

import { EditCalendarDayModalComponent } from '../../modals/edit-calendar-day-modal/edit-calendar-day-modal.component';
import { CalendarDayType } from '../../../../lib/lib-shared/types/CalendarDayType';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  public CalendarDayType = CalendarDayType;

  public calendarDayMap = new Map();

  constructor(
    private apiLavandierService: ApiLavandierService,
    private modalService: NgbModal,
    public ngbDateParserFormatter: NgbDateParserFormatter,
  ) {
  }

  ngOnInit() {
    this.loadCalendarDayMap();
  }

  loadCalendarDayMap() {
    this.calendarDayMap = new Map();
    this.apiLavandierService.getCalendarDayList()
      .subscribe(
        (calendarDayList: any[]) => {
          calendarDayList.forEach((calendarDay) => {
            this.calendarDayMap.set(calendarDay.date, calendarDay);
          });
        }
      );
  }

  editCalendarDay(date: NgbDate) {
    const editDayModal = this.modalService.open(EditCalendarDayModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    editDayModal.componentInstance.date = date;
    if (this.calendarDayMap.has(this.ngbDateParserFormatter.format(date))) {
      editDayModal.componentInstance.calendarDay = this.calendarDayMap.get(this.ngbDateParserFormatter.format(date));
    }
    editDayModal.result.then(
      (response) => {
        if (response) {
          this.ngOnInit();
        }
      }
    );
  }
}
