import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

import { OrderTransportType } from '../../../../lib/lib-shared/types/OrderTransportType';

@Component({
  selector: 'lm-add-order-transport-custom-modal',
  templateUrl: './add-order-transport-custom-modal.component.html',
  styleUrls: ['./add-order-transport-custom-modal.component.scss']
})
export class AddOrderTransportCustomModalComponent implements OnInit {
  public OrderTransportType = OrderTransportType;

  public showMultipleType: boolean;
  public shopList: any[] = [];

  public orderTransportForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private calendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.orderTransportForm = this.fb.group({
      date: [this.calendar.getToday(), Validators.required],
      shop: [null, Validators.required],
      start: [null, Validators.required],
      duration: [null, [Validators.required, Validators.min(1)]],
    });

    if (this.showMultipleType) {
      this.orderTransportForm.addControl('type', this.fb.control(null, Validators.required));
    }
  }

  onValidate() {
    const date = moment(`${this.ngbDateParserFormatter.format(this.orderTransportForm.value.date)} ${this.orderTransportForm.value.start}`);
    this.activeModal.close({
      shopId: this.orderTransportForm.value.shop.id,
      type: this.orderTransportForm.value.type,
      date: date.format(),
      start: date.format('HH:mm:ss'),
      end: date.add(this.orderTransportForm.value.duration, 'minutes').format('HH:mm:ss'),
    });
  }
}
