import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-scooter-detail',
  templateUrl: './scooter-detail.component.html',
  styleUrls: ['./scooter-detail.component.scss']
})
export class ScooterDetailComponent implements OnInit {
  public scooterData = {};
  public onEditScooter = false;
  public onAddEvent = false;
  public lmpList = [];
  public scooterId = 0;

  constructor(private activatedRoute: ActivatedRoute,
              private apiLavandierService: ApiLavandierService) {
  }

  ngOnInit() {
    this.apiLavandierService.getShops()
      .subscribe((data: Object[]) => this.lmpList = data);
    this.activatedRoute.params.subscribe(
      result => {
        this.scooterId = result['id'];
        this.getScooterData();
      }
    );
  }

  getScooterData() {
    this.apiLavandierService.getScooterById(this.scooterId)
      .subscribe(data => this.scooterData = data);
  }

  onSubmitEditScooter(scooterEdited) {
    this.onEditScooter = !this.onEditScooter;
    if (scooterEdited) {
      this.getScooterData();
    }
  }

}
