import { IOrder, IOrderBag } from './api.service.type';
import { PermissionName } from '../../lib-shared/types/PermissionName';
import { OrderTransportType } from '../../lib-shared/types/OrderTransportType';
import { StorageType } from '../../lib-shared/types/StorageType';
import { UploadFolder } from '../../lib-shared/types/UploadFolder';
import { IncidentTypeSeverity } from '../../lib-shared/types/IncidentTypeSeverity';
import { IncidentOrigin } from '../../lib-shared/types/IncidentOrigin';
import { IncidentStatusStatus } from '../../lib-shared/types/IncidentStatusStatus';
import { ArticleUnity } from '../../lib-shared/types/ArticleUnity';
import { DiscountCodeType } from '../../lib-shared/types/DiscountCodeType';
import { PRICEMULTIPLIER } from '../../lib-shared/defines';

export interface IIncidentNew extends IIncident {
  orderId: number;
  comment: string;
}

export interface IIncident {
  typeId: number;
  assignLavandierId: number;
  origin: IncidentOrigin;
  title: string;
}

export interface IIncidentStatus {
  incidentId: number;
  incidentStatus: IncidentStatusStatus;
}

export interface IIncidentComment {
  incidentId: number;
  comment: string;
}

export interface IIncidentImages {
  incidentCommentId: number;
  imageList: File[];
}

export interface IUserSearch {
  firstName?: string;
  lastName?: string;
}

export interface IOrderTransportAway {
  comment: string;
}

export interface IOrderBagDelete {
  id: number;
}

export interface IOrderBagEdit extends IOrderBag {
  id: number;
}

export interface ITrackingSheetStorage {
  id: number;
  missing: boolean;
}

export interface IOrderEdit {
  deleteList?: IOrderBagDelete[];
  newList?: IOrderBag[];
  editList?: IOrderBagEdit[];
  finish?: boolean;
  express?: boolean;
}

export interface IRole {
  name: string;
  permissionNameList: PermissionName[];
}

export interface IOrderSearch {
  orderId?: number;
}

export interface IOrderAdmin extends IOrder {
  userId: number;
  incidentId?: number;
  shopId?: number;
  name?: string;
  estimatedDeliveryAt?: string;
  inShopId?: number;
}

export interface IOrderTransport {
  shopId: number;
  orderId: number;
  lavandierId: number;
  type: OrderTransportType;
  date: string;
  start: string;
  end: string;
  weight: number;
}

export interface IOrderTransportOrdered {
  id: number;
  weight: number;
  lavandierId: number;
}

export interface IUpload {
  folder: UploadFolder;
  file: File;
}

export enum IncidentTypeVisibility {
  PUBLIC = 0x1,
  OLD = 0x2,
}

export interface IIncidentType {
  severity: IncidentTypeSeverity;
  name: string;
  visibility: number;
}

export interface IUserEdit {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  userTypeId: number;
  important: boolean;
  dataConsent: boolean;
  fakePayment: boolean;
  multipleArticle?: boolean;
  orderName?: boolean;
}

export interface IRange {
  name: string;
  quality: number;
  shortDescription: string;
  description: string;
}

export interface IArticle {
  articleOptionIdList: number[];
  articleFamilyId: number;
  iconId: number;
  unity: ArticleUnity;
  flux: StorageType;
  name: string;
  code: string;
  nbPiece: number;
  mass: number;
}

export interface IArticleOptionType {
  name: string;
}

export interface IArticleOption {
  articleOptionTypeId: number;
  flux: StorageType;
  name: string;
  description: string;
  public: boolean;
}

export interface IArticlePrice {
  userTypeId: number;
  rangeId: number;
  articleId: number;
  price: number;
  vat: number;
}

export interface IArticleOptionPrice {
  userTypeId: number;
  rangeId: number;
  articleId: number;
  articleOptionId: number;
  price: number;
  vat: number;
}

export interface IDiscountCode {
  type: DiscountCodeType;
  amount: number;
  minOrder: number;
  code: string;
  description: string;
  onlyFirstOrder: boolean;
  uniqueByUser: boolean;
  unique: boolean;
  start: string;
  end: string;
}

export interface IMembershipDiscount {
  id: number;
  percent: number;
}
