import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { CustomDatepickerI18nService } from '../../../services/custom-datepicker-i18n.service';
import { FakeApiService } from '../../../services/fake-api.service';

@Component({
  selector: 'lm-dispatch-area',
  templateUrl: './dispatch-area.component.html',
  styleUrls: ['./dispatch-area.component.scss']
})
export class DispatchAreaComponent implements OnInit {
  public filterData = {};
  public filterForm: FormGroup;
  public clubWaitingList = [];
  public clubWaitingTempList = [];
  public missionWaitingList = [];
  public missionWaitingTempList = [];
  public missionPercent = 0;
  @ViewChild('clubTable') clubTable: DatatableComponent;
  @ViewChild('missionsTable') missionsTable: DatatableComponent;

  constructor(private fakeApiService: FakeApiService,
              public calendar: NgbCalendar,
              public customDate: CustomDatepickerI18nService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.buildForm();
    this.fakeApiService.getDispatchAreaData().subscribe(
      result => {
        this.filterData = result.filter;
        this.clubWaitingList = result.clubWaiting;
        this.clubWaitingTempList = [...result.clubWaiting];
        this.missionWaitingList = result.missionsWaiting;
        this.missionWaitingTempList = [...result.missionsWaiting];
        this.missionPercent = result.missionPercent;
        this.filterDispatchArea();
      },
      error => console.log('getDispatchAreaData', error)
    );
  }

  buildForm() {
    this.filterForm = this.fb.group({
      district: [''],
      pickUpDelivery: [''],
      slotType: [''],
      date: [this.calendar.getToday()],
      slot: [''],
      lmp: ['']
    });
  }

  filterDispatchArea() {
    const self = this;
    const filterValues = this.filterForm.value;

    // filter our data
    const clubTemp = this.clubWaitingTempList.filter(function(d) {
      return ((filterValues.district === '' || filterValues.district === d.postalCode)
        && (filterValues.pickUpDelivery === '' || filterValues.pickUpDelivery === d.mode)
        && (filterValues.slotType === '' || filterValues.slotType === d.slotType)
        && (self.customDate.getDayAriaLabel(filterValues.date) === d.date)
        && (filterValues.slot === '' || filterValues.slot === d.slot)
        && (filterValues.lmp === '' || filterValues.lmp === d.lmp));
    });

    const missionsTemp = this.missionWaitingTempList.filter(function(d) {
      return ((filterValues.district === '' || filterValues.district === d.postalCode)
        && (filterValues.pickUpDelivery === '' || filterValues.pickUpDelivery === d.mode)
        && (filterValues.slotType === '' || filterValues.slotType === d.slotType)
        && (self.customDate.getDayAriaLabel(filterValues.date) === d.date)
        && (filterValues.slot === '' || filterValues.slot === d.slot)
        && (filterValues.lmp === '' || filterValues.lmp === d.lmp));
    });

    // update the rows
    this.clubWaitingList = clubTemp;
    this.missionWaitingList = missionsTemp;
    // Whenever the filter changes, always go back to the first page
    this.clubTable.offset = 0;
    this.missionsTable.offset = 0;
  }

}
