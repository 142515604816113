import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { Page } from '../../../lib/lib-ngx/utils/Page';
import { OrderTemporary } from '../../../lib/lib-shared/types/OrderTemporary';
import { PRICEMULTIPLIER } from '../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  public PRICEMULTIPLIER = PRICEMULTIPLIER;
  public OrderTemporary = OrderTemporary;

  page = new Page();

  public orderList = [];
  public filterForm: FormGroup;
  public userTypeList = [];

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private router: Router,
    private apiLavandierService: ApiLavandierService,
  ) {
    this.page.setPageNumber(0);
    this.page.size = 10;
  }

  ngOnInit() {
    this.resetData();
    this.loadData().subscribe();
    this.buildForm();

    this.filterForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(data => {
          this.page.checkPage();
          return this.apiLavandierService.getOrderList({
            id: data.orderId ? data.orderId : '',
            userTypeId: data.userType,
            temporary: data.temporary,
            paid: data.paid.toString(),
            name: data.name,
            createdAt: data.createdAt ? this.ngbDateParserFormatter.format(data.createdAt) : '',
            estimatedDeliveryAt: data.estimatedAt ? this.ngbDateParserFormatter.format(data.estimatedAt) : '',
          }, this.page.toQueryObject());
        }),
      )
      .subscribe((orderList: any) => {
        this.page.totalElements = orderList.count;
        this.page.totalPages = this.page.totalElements / this.page.size;
        this.orderList = orderList.rows;
      });
    this.filterForm.updateValueAndValidity();
  }

  setPage(pageInfo) {
    this.page.setPageNumber(pageInfo.offset);
    this.filterForm.updateValueAndValidity();
  }

  resetData() {
    this.userTypeList = [];
  }

  loadData(): Observable<any> {
    return forkJoin([
      this.apiLavandierService.getUserTypeList(),
    ]).pipe(
      map(([userTypeList]) => {
        this.userTypeList = userTypeList as any[];
      })
    );
  }

  buildForm() {
    this.filterForm = this.fb.group({
      status: [''],
      createdAt: [null],
      estimatedAt: [null],
      orderId: [''],
      name: [''],
      paid: [''],
      userType: [''],
      temporary: [false],
    });
  }

  navigateToOrderDetail(event) {
    if (event.type === 'click') {
      this.router.navigate(['order-details', event.row.id]);
    }
  }
}
