import { AfterViewInit, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AgmMap, AgmMarker } from '@agm/core';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

import { HomeService } from '../../../services/home.service';
import { OrderTransportType } from '../../../../lib/lib-shared/types/OrderTransportType';
import {
  IOrderTransport,
  IOrderTransportOrdered,
} from '../../../../lib/lib-ngx/web-services/api-lavandier.service.type';
import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

declare const google: any;

@Component({
  selector: 'lm-dispatch-map',
  templateUrl: './dispatch-map.component.html',
  styleUrls: ['./dispatch-map.component.scss']
})
export class DispatchMapComponent implements OnInit, AfterViewInit {
  public orderTransportType = OrderTransportType;

  public orderTransportMap = new Map();
  public shopMap = new Map();
  public lavandierByShopMap = new Map();
  public timeSlotList = [];
  public notAffectedList = [];

  public filterForm: FormGroup; // Form group for filter feature
  public notAffectedSelected = null; // mission selected in the slotNotAffectedSelected
  public bestLavandierForRoute = null; // closest lavandier for a notAffectedMissionSelected
  public selectedShop = null; // contain the selected shop
  public selectedOrderTransportList = []; // contain the selected orderTransport list
  public selectedSlotData = null; // Data of the current affected slot; will contain duration and distance
  public directionsService: google.maps.DirectionsService;
  public directionsDisplay: google.maps.DirectionsRenderer;
  public map: google.maps.Map;
  @ViewChild(AgmMarker)
  public marker;
  @ViewChild(AgmMap)
  public agmMap;

  constructor(
    private fb: FormBuilder,
    public calendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private homeService: HomeService,
    private zone: NgZone,
    private apiLavandierService: ApiLavandierService,
    public sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    this.buildForm();

    this.filterForm.controls.date.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(date => {
          return this.apiLavandierService.getOrderTransportList({
            canceled: false.toString(),
            simultaneous: false.toString(),
            inShop: String(false),
            date: this.ngbDateParserFormatter.format(date),
          });
        }),
      )
      .subscribe(
        (data: any) => {// todo type
          this.transformOrderTransportList(data);
          this.addMissingLavandierAndTimeSlot();
        });

    forkJoin([
      this.apiLavandierService.getShops(),
      this.apiLavandierService.getLavandiers(),
    ])
      .pipe(
        map(([shopList, lavandierList]: [Object[], { rows: Object[] }]) => {
          return [
            ListToMap.convert(shopList),
            ListToMap.list(lavandierList.rows, 'shopId'),
          ];
        })
      )
      .subscribe(([shopMap, lavandierByShopMap]: [Map<number, Object>, Map<number, Object>]) => {
        this.shopMap = shopMap;
        this.lavandierByShopMap = lavandierByShopMap;
        this.addMissingLavandierAndTimeSlot();
        this.filterForm.controls.date.updateValueAndValidity({onlySelf: false, emitEvent: true});
      });
  }

  transformOrderTransportList(orderTransportList) {
    const timeSlotList = [];
    const result = new Map(); // todo rename
    orderTransportList.forEach((orderTransport) => {
      const shopId = this.shopMap.get(orderTransport.shopId).parentId ? this.shopMap.get(orderTransport.shopId).parentId : orderTransport.shopId;
      const slotId = moment.utc(orderTransport['start'], 'HH:mm:ss').format('HH:mm') + ' - ' +
        moment.utc(orderTransport['end'], 'HH:mm:ss').format('HH:mm');

      const slot = {id: slotId, date: orderTransport['date'], start: orderTransport['start'], end: orderTransport['end']};
      if (timeSlotList.findIndex(i => i.id === slotId) === -1) {
        timeSlotList.push(slot);
      }

      let shop = {
        affected: new Map(),
        notAffected: new Map(),
      };
      if (result.has(shopId)) {
        shop = result.get(shopId);
      }

      if (orderTransport['lavandierId']) {
        let lavandier = new Map();
        if (shop.affected.has(orderTransport['lavandierId'])) {
          lavandier = shop.affected.get(orderTransport['lavandierId']);
        }

        let slots = [];
        if (lavandier.has(slotId)) {
          slots = lavandier.get(slotId);
        }
        slots.push(orderTransport);
        lavandier.set(slotId, slots);

        shop.affected.set(orderTransport['lavandierId'], lavandier);
      } else {
        let slots = [];
        if (shop.notAffected.has(slotId)) {
          slots = shop.notAffected.get(slotId);
        }
        slots.push(orderTransport);
        shop.notAffected.set(slotId, slots);
      }

      result.set(shopId, shop);
    });
    this.timeSlotList = timeSlotList;
    this.orderTransportMap = result;
  }

  addMissingLavandierAndTimeSlot() {
    this.orderTransportMap.forEach((shop, shopId) => {
      this.timeSlotList.forEach((timeSlot) => {
        if (!shop.notAffected.has(timeSlot.id)) {
          shop.notAffected.set(timeSlot.id, []);
        }
      });

      // re order the not affected timeslot of the shop
      Array.from(shop.notAffected.keys()).sort().forEach(key => {
        const tmp = shop.notAffected.get(key);
        shop.notAffected.delete(key);
        shop.notAffected.set(key, tmp);
      });

      if (this.lavandierByShopMap.has(shopId)) {
        this.lavandierByShopMap.get(shopId).forEach((lavandier) => {
          let lavandierMap = new Map();
          if (shop.affected.has(lavandier.id)) {
            lavandierMap = shop.affected.get(lavandier.id);
          }

          this.timeSlotList.forEach((timeSlot) => {
            if (!lavandierMap.has(timeSlot.id)) {
              lavandierMap.set(timeSlot.id, []);
            }
          });

          shop.affected.set(lavandier.id, lavandierMap);
        });
      }
    });
  }

  reOrder(orderTransportList: Object[], lavandierId: number) {
    const orderTransportOrderedList: IOrderTransportOrdered[] = [];

    orderTransportList.forEach((orderTransport, index) => {
      const orderTransportOrdered: IOrderTransportOrdered = {
        id: orderTransport['id'],
        weight: index,
        lavandierId: lavandierId,
      };
      orderTransportOrderedList.push(orderTransportOrdered);
    });

    this.apiLavandierService.postOrderTransportReOrder(orderTransportOrderedList)
      .subscribe(data => this.calcRoute(true, null, null));
  }

  /**
   * After view init we
   */
  ngAfterViewInit() {
    this.agmMap.mapReady.subscribe(map => {
      // When the map is ready, we create DirectionService and DirectionRender
      this.directionsService = new google.maps.DirectionsService();
      this.directionsDisplay = new google.maps.DirectionsRenderer();
      const directionDisplayOption: google.maps.DirectionsRendererOptions = {
        preserveViewport: true
      };
      // Set some options in DirectionsRender
      this.directionsDisplay.setOptions(directionDisplayOption);
      // Set the default map
      this.map = map;
      // Hide the side menu
      this.homeService.setSideMenuStatus(false);
    });
  }

  buildForm() {
    this.filterForm = this.fb.group({
      mission: [''],
      slot: [''],
      lmp: [''],
      date: [this.calendar.getToday()]
    });
  }

  getAddress(orderTransport) {
    if (orderTransport.order) {
      const sameTypeOrderAddress = orderTransport.order.orderAddresses.filter(orderAddress => orderAddress.type === orderTransport.type);
      return sameTypeOrderAddress[sameTypeOrderAddress.length - 1];
    } else {
      return this.shopMap.get(orderTransport.shopId).address;
    }
  }

  /**
   * Method called when user click on a not affected OrderTransport,
   * it'll show a marker in the map of the OrderTransport
   * And found the best lavandier for this not affected OrderTransport;
   * The best lavandier will have a red border
   * @param orderTransport: OrderTransport to show in the map
   */
  onNotAffectedOrderTransport(orderTransport) {
    // If user click on the current mission; it'll deselect the mission
    if (this.notAffectedSelected === orderTransport) {
      this.notAffectedSelected = null;
      this.bestLavandierForRoute = null;
    } else {
      this.notAffectedSelected = orderTransport;
      // Found the closest mission in lavandier's affected mission
      /*let distClosestLavandier = Math.pow(10, 1000);
      for (const lavandier of this.affectedMissionsList) {
        for (const missionSlot of lavandier.missions) {
          if (missionSlot.slot === mission.slot) {
            for (const lavandierMission of missionSlot.missions) {
              const distLavandier = Math.abs(mission.lat - lavandierMission.lat) + Math.abs(mission.long - lavandierMission.long);
              // If the distance between sum of long lat is lower than the previous closest distance founded
              if (distLavandier < distClosestLavandier) {
                // Update the bestLavandierForRoute
                distClosestLavandier = distLavandier;
                this.bestLavandierForRoute = lavandier;
              }
            }
          }
        }
      }*/
    }
  }

  /**
   * Method which will calculate the route for the selected slot
   */
  calcRoute(showRoute: boolean, shop, orderTransportList) {
    if (showRoute) {
      if (shop !== null) {
        this.selectedShop = shop;
      }
      if (orderTransportList !== null) {
        this.selectedOrderTransportList = orderTransportList;
      }
      // Set default map to prevent route overriding on the google map
      this.directionsDisplay.setMap(this.map);
      const wayPoints = [];
      // Get all the wayPoints between origin and destination
      this.selectedOrderTransportList.forEach(orderTransport => {
        const wp = {
          location: new google.maps.LatLng(this.getAddress(orderTransport).lat, this.getAddress(orderTransport).lng),
          stopover: true
        };
        wayPoints.push(wp);
      });
      // Create the request with origin, destination, way points, travel points and more
      const request = {
        origin: new google.maps.LatLng(this.selectedShop.address.lat, this.selectedShop.address.lng),
        destination: new google.maps.LatLng(this.selectedShop.address.lat, this.selectedShop.address.lng),
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: wayPoints
      };

      // Get route
      this.directionsService.route(request, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          // Set direction if it's OKAY
          this.directionsDisplay.setDirections(result);
          // Calculate the total duration and distance of the current slot
          this.selectedSlotData = {
            distance: 0.0,
            duration: 0.0
          };
          this.zone.run(() => {
            this.directionsDisplay.getDirections().routes[0].legs.forEach(leg => {
              this.selectedSlotData.duration += leg.duration.value;
              this.selectedSlotData.distance += leg.distance.value;
            });
            this.selectedSlotData.duration = (this.selectedSlotData.duration / 60).toFixed(2); // seconds => minutes
            this.selectedSlotData.distance = (this.selectedSlotData.distance / 1000).toFixed(2); // meters => kilometers
          });
        }
      });
    } else {
      this.selectedOrderTransportList = [];
      this.directionsDisplay.setMap(null);
    }
  }

  /**
   * Method called after a click on add a return to lmp
   */
  addReturnLmp(shop, lavandier, timeSlot) {
    const orderTransport: IOrderTransport = {
      shopId: shop.id,
      orderId: null,
      lavandierId: lavandier.id,
      type: OrderTransportType.RETURNTOHOME,
      date: timeSlot.date,
      start: timeSlot.start,
      end: timeSlot.end,
      weight: this.selectedOrderTransportList.length + 1
    };

    this.apiLavandierService.postOrderTransport(orderTransport)
      .subscribe(data => {
        this.selectedOrderTransportList.push(data);
        this.calcRoute(true, null, null);
      });
  }

  /**
   * Method called after a click on delete checkpoint icon
   */
  removeCheckPoint(orderTransport) {
    this.apiLavandierService.deleteOrderTransport(orderTransport.id)
      .subscribe(data => {
        const index = this.selectedOrderTransportList.indexOf(orderTransport);
        if (index !== -1) {
          this.selectedOrderTransportList.splice(index, 1);
        }
        this.calcRoute(true, null, null);
      });
  }

  getTotalMissionByLavandier(missionList) {
    let count = 0;
    missionList.forEach((mission) => count += mission.length);
    return count;
  }
}
