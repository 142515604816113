import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../../lib/lib-ngx/web-services/auth.service';
import { HomeService } from '../../services/home.service';
import { AlertService } from '../../../lib/lib-ngx/services/alert.service';

@Component({
  selector: 'lm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public menuState = true;

  constructor(
    public authService: AuthService,
    private router: Router,
    private homeService: HomeService,
    public alertService: AlertService,
  ) {
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.homeService.setSideMenuStatus(false);
    }
    this.homeService.getSideMenu().subscribe(
      (response) => {
        this.menuState = response;
      }
    );
  }

  logout() {
    this.authService.logout();
    this.homeService.setSideMenuStatus(false);
    this.router.navigate(['login']);
  }

  clickOnSideMenuBtn() {
    this.menuState = !this.menuState;
    this.homeService.setSideMenuStatus(this.menuState);
  }

  onCloseAlert() {
    this.alertService.hide();
  }
}
