import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-order-stored-list',
  templateUrl: './order-stored-list.component.html',
  styleUrls: ['./order-stored-list.component.scss']
})
export class OrderStoredListComponent implements OnInit {
  public orderInputFormControl = new FormControl();

  public order = null;
  public trackingSheetStorageList: any[] = [];

  constructor(
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.orderInputFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(id => {
          this.order = null;
          if (id && id !== '') {
            return this.apiLavandierService.getOrderIdDetails(id)
              .pipe(
                catchError(() => {
                  return of(null);
                })
              );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(order => {
        if (order) {
          this.order = order;
          const trackingSheetStorageList = [];
          this.apiLavandierService.getTrackingSheetOrderId(order.id)
            .subscribe((trackingSheetList: any[]) => {
              trackingSheetList.forEach(trackingSheet => {
                trackingSheetStorageList.push(trackingSheet.trackingSheetStorageList[trackingSheet.trackingSheetStorageList.length - 1]);
              });
              // todo is all trackingSheetStorage at the same orderTransportId
              this.trackingSheetStorageList = trackingSheetStorageList;
            });
        }
      });
  }
}
