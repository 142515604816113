import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { Page } from '../../../../lib/lib-ngx/utils/Page';

@Component({
  selector: 'lm-lavandier-incident',
  templateUrl: './lavandier-incident.component.html',
  styleUrls: ['./lavandier-incident.component.scss'],
})
export class LavandierIncidentComponent implements OnInit {
  page = new Page();
  public lavandierIncidentList = [];
  public incidentTypeMap = new Map();
  public lavandierMap = new Map();
  public shopMap = new Map();

  constructor(
    private apiLavandierService: ApiLavandierService,
    public router: Router,
  ) {
    this.page.setPageNumber(0);
    this.page.size = 10;
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    return forkJoin([
      this.apiLavandierService.getShops(),
      this.apiLavandierService.getLavandiers(),
      this.apiLavandierService.getIncidentTypes(),
      this.apiLavandierService.getIncidentMine(this.page.toQueryObject()),
    ])
      .pipe(
        map(([shopList, lavandierList, incidentList, lavandierIncidentList]: any[]) => {
          return [
            ListToMap.convert(shopList as any[]),
            ListToMap.convert(lavandierList.rows as any[]),
            ListToMap.convert(incidentList as any[]),
            lavandierIncidentList,
          ];
        })
      )
      .subscribe(([shopMap, lavandierMap, incidentMap, lavandierIncidentList]) => {
        this.shopMap = shopMap as any;
        this.lavandierMap = lavandierMap as any;
        this.incidentTypeMap = incidentMap as any;
        this.page.totalElements = (lavandierIncidentList as any).count;
        this.page.totalPages = this.page.totalElements / this.page.size;
        this.lavandierIncidentList = (lavandierIncidentList as any).rows;
      });
  }

  setPage(pageNumber: number) {
    this.page.setPageNumber(pageNumber);
    this.loadLavandierIncidentList();
  }

  loadLavandierIncidentList() {
    this.page.checkPage();
    this.apiLavandierService.getIncidentMine(this.page.toQueryObject())
      .subscribe((lavandierIncidentList: any) => {
        this.page.totalElements = lavandierIncidentList.count;
        this.page.totalPages = this.page.totalElements / this.page.size;
        this.lavandierIncidentList = lavandierIncidentList.rows;
      });
  }
}
