import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ErrorService } from '../../../../lib/lib-ngx/services/error.service';
import { ErrorMessage } from '../../../../lib/lib-shared/ErrorMessage';

@Component({
  selector: 'lm-scan-tracking-sheet',
  templateUrl: './scan-tracking-sheet.component.html',
  styleUrls: ['./scan-tracking-sheet.component.scss']
})
export class ScanTrackingSheetComponent implements OnInit {
  public trackingSheetNumberFormControl = new FormControl();
  @ViewChild('trackingSheetNumberInput') trackingSheetNumberInput: ElementRef;

  public refError = false;
  public trackingSheet = null;
  public trackingSheetStorage = null;
  public trackingSheetStorageListLeft: any[] = [];
  public trackingSheetStorageListRight: any[] = [];
  public order = null;

  constructor(
    private apiLavandierService: ApiLavandierService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.reset();

    this.trackingSheetNumberFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(ref => {
          this.refError = false;
          if (ref && ref !== '') {
            this.trackingSheet = null;
            this.order = null;
            return this.apiLavandierService.getTrackingSheetDetails(ref)
              .pipe(
                catchError(() => {
                  this.refError = true;
                  return of(null);
                })
              );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(trackingSheet => {
        if (trackingSheet) {
          if (trackingSheet.orderArticleTrackingList.some(orderArticleTracking => !orderArticleTracking.factoryAt)) {
            this.errorService.showModal(ErrorMessage.TRACKINGSHEET_NOT_FACTORY_CHECKED);
            return;
          }

          const lastTrackingSheetStorage = trackingSheet.trackingSheetStorageList[trackingSheet.trackingSheetStorageList.length - 1];
          if (lastTrackingSheetStorage.orderTransportId) {
            this.errorService.showModal(ErrorMessage.TRACKINGSHEET_NOT_TIDIABLE);
            return;
          }

          forkJoin([
            this.apiLavandierService.postTrackingSheetStorageTidied({trackingSheetId: trackingSheet.id}),
            this.apiLavandierService.getOrderIdDetails(trackingSheet.orderArticleTrackingList[0].orderArticle.orderBag.orderId),
          ])
            .pipe(concatMap(([trackingSheetStorage, order]: any[]) => (
              this.apiLavandierService.getTrackingSheetOrderId(order.id)
                .pipe(map(trackingSheetList => [trackingSheetStorage, order, trackingSheetList]))
            )))
            .subscribe(([trackingSheetStorage, order, trackingSheetList]: [Object, Object, any[]]) => {
              if (!this.errorService.manageError(trackingSheetStorage)) {
                this.trackingSheet = trackingSheet;
                this.trackingSheetStorage = trackingSheetStorage;
                this.order = order;
                this.trackingSheetStorageListLeft = [];
                this.trackingSheetStorageListRight = [];

                trackingSheetList.filter(ts => ts.id !== trackingSheet.id).forEach((ts, i) => {
                  const lastTrackingSheetStorageList = ts.trackingSheetStorageList[ts.trackingSheetStorageList.length - 1];
                  if (i % 2 === 0) {
                    this.trackingSheetStorageListRight.push(lastTrackingSheetStorageList);
                  } else {
                    this.trackingSheetStorageListLeft.push(lastTrackingSheetStorageList);
                  }
                });
              }
            });
        } else {
          this.reset(this.trackingSheetNumberFormControl.value);
        }
      });
  }

  reset(refError = false) {
    this.refError = refError;

    this.trackingSheetNumberFormControl.enable({emitEvent: false});
    this.getTrackingSheetNumberInputFocus();
    this.trackingSheetStorageListRight = [];
    this.trackingSheetStorageListLeft = [];

    if (!refError) {
      this.trackingSheetNumberFormControl.reset();
    }
  }

  getTrackingSheetNumberInputFocus() {
    setTimeout(() => this.trackingSheetNumberInput.nativeElement.focus());
  }
}
