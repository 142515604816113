import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { MissionListComponent } from './components/mission-list/mission-list.component';
import { StoresComponent } from './components/store/stores/stores.component';
import { StorePerimeterComponent } from './components/store/store-perimeter/store-perimeter.component';
import { ScootersComponent } from './components/scooters/scooters.component';
import { ScooterDetailComponent } from './components/scooters/scooter-detail/scooter-detail.component';
import { SlotComponent } from './components/store/slot/slot.component';
import { UserListComponent } from './components/users/user-list/user-list.component';
import { UserProfileComponent } from './components/users/user-profile/user-profile.component';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderDetailsComponent } from './components/orders/order-details/order-details.component';
import { DispatchAreaComponent } from './components/dispatch/dispatch-area/dispatch-area.component';
import { DispatchLavandierComponent } from './components/dispatch/dispatch-lavandier/dispatch-lavandier.component';
import { DispatchMapComponent } from './components/dispatch/dispatch-map/dispatch-map.component';
import { QuarterbackingListComponent } from './components/quarterback/quarterbacking-list/quarterbacking-list.component';
import { B2bComponent } from './components/b2b/b2b.component';
import { ArticleFamilyComponent } from './components/article/article-family/article-family.component';
import { ArticleSettingComponent } from './components/article/article-setting/article-setting.component';
import { ArticleListComponent } from './components/article/article-list/article-list.component';
import { NewArticleComponent } from './components/article/new-article/new-article.component';
import { ArticlePricesComponent } from './components/article/article-prices/article-prices.component';
import { ArticleDetailsComponent } from './components/article/article-details/article-details.component';
import { IncidentTypeSettingComponent } from './components/lavoirmoderne/incident-type-setting/incident-type-setting.component';
import { NewIncidentComponent } from './components/incident/new-incident/new-incident.component';
import { IncidentListComponent } from './components/incident/incident-list/incident-list.component';
import { ArchivedIncidentComponent } from './components/incident/archived-incident/archived-incident.component';
import { IncidentDetailsComponent } from './components/incident/incident-details/incident-details.component';
import { LavandierIncidentComponent } from './components/incident/lavandier-incident/lavandier-incident.component';
import { LavandierListComponent } from './components/lavandier/lavandier-list/lavandier-list.component';
import { LavandierDetailsComponent } from './components/lavandier/lavandier-details/lavandier-details.component';
import { NewLavandierComponent } from './components/lavandier/new-lavandier/new-lavandier.component';
import { UserTypeSettingComponent } from './components/lavoirmoderne/user-type-setting/user-type-setting.component';
import { UserTypeEditComponent } from './components/lavoirmoderne/user-type-edit/user-type-edit.component';
import { ScanTrackingSheetComponent } from './components/quarterback/scan-tracking-sheet/scan-tracking-sheet.component';
import { OrderStoredListComponent } from './components/quarterback/order-stored-list/order-stored-list.component';
import { QuarterbackingDetailsComponent } from './components/quarterback/quarterbacking-details/quarterbacking-details.component';
import { DiscountCodeListComponent } from './components/users/discount-code-list/discount-code-list.component';
import { DiscountCodeAddComponent } from './components/users/discount-code-add/discount-code-add.component';
import { MembershipDiscountSettingComponent } from './components/lavoirmoderne/membership-discount-setting/membership-discount-setting.component';
import { MembershipDiscountEditComponent } from './components/lavoirmoderne/membership-discount-edit/membership-discount-edit.component';
import { UserClubComponent } from './components/users/user-club/user-club.component';
import { RoleListComponent } from './components/lavandier/role-list/role-list.component';
import { RoleDetailsComponent } from './components/lavandier/role-details/role-details.component';
import { RoleAddComponent } from './components/lavandier/role-add/role-add.component';
import { RoleGuard } from './role.guard';
import { PermissionName } from '../lib/lib-shared/types/PermissionName';
import { CounterPickUpComponent } from './components/counter-pick-up/counter-pick-up.component';
import { ScanBagComponent } from './components/scan-bag/scan-bag.component';
import { CalendarComponent } from './components/store/calendar/calendar.component';
import { EditAccountComponent } from './components/account/edit-account/edit-account.component';
import { EditUserComponent } from './components/user/edit-user/edit-user.component';
import { OrderWantedDeliveryComponent } from './components/order/order-wanted-delivery/order-wanted-delivery.component';

const routes: Routes = [
  {
    path: 'account/new/:userTypeId',
    component: EditAccountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'account/edit/:id',
    component: EditAccountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/new',
    component: EditUserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/edit/:id',
    component: EditUserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order/wantedDelivery',
    component: OrderWantedDeliveryComponent,
    canActivate: [AuthGuard]
  },
  /**** clean is done below this comment ****/
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'mission-list',
    component: MissionListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'stores',
    component: StoresComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'store-perimeter',
    component: StorePerimeterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'scooters',
    component: ScootersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'scooter-detail/:id',
    component: ScooterDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lavandier/list',
    component: LavandierListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lavandier/details/:id',
    component: LavandierDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lavandier/add',
    component: NewLavandierComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'slot',
    component: SlotComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/list',
    component: UserListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-profile/:id',
    component: UserProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-club/list',
    component: UserClubComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order-details/:id',
    component: OrderDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'scan-bag',
    component: ScanBagComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'dispatching-area',
  //   component: DispatchAreaComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'dispatching-lavandier',
  //   component: DispatchLavandierComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'dispatching-map',
    component: DispatchMapComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'quarterbacking',
    component: QuarterbackingListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'quarterbacking/:orderTransportId',
    component: QuarterbackingDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'quarterbacking/order/:orderId',
    component: QuarterbackingDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'b2b',
    component: B2bComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'article/setting',
    component: ArticleSettingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'article/family',
    component: ArticleFamilyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'article/list',
    component: ArticleListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'article/add',
    component: NewArticleComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'article/edit/:id',
    component: NewArticleComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'article/prices/:id',
    component: ArticlePricesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'article/details/:id',
    component: ArticleDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'incident-type-setting',
    component: IncidentTypeSettingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'incident/add',
    component: NewIncidentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'incident/add/:orderId',
    component: NewIncidentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'incident/edit/:id',
    component: NewIncidentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'incident/list',
    component: IncidentListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'incident/archived',
    component: ArchivedIncidentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'incident/details/:id',
    component: IncidentDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'incident/lavandier',
    component: LavandierIncidentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-type-setting',
    component: UserTypeSettingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-type-setting/edit/:userTypeId',
    component: UserTypeEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'membership-discount-setting',
    component: MembershipDiscountSettingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'membership-discount-setting/edit/:membershipDiscountId',
    component: MembershipDiscountEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'scan-tracking-sheet',
    component: ScanTrackingSheetComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order-stored-list',
    component: OrderStoredListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'discount-code/list',
    component: DiscountCodeListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'discount-code/add',
    component: DiscountCodeAddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'counter-pick-up/user/:userId',
    component: CounterPickUpComponent,
    canActivate: [AuthGuard],
    data: {cAndC: false},
  },
  {
    path: 'counter-pick-up/event/:eventId',
    component: CounterPickUpComponent,
    canActivate: [AuthGuard],
    data: {cAndC: false},
  },
  {
    path: 'click-and-collect/user/:userId',
    component: CounterPickUpComponent,
    canActivate: [AuthGuard],
    data: {cAndC: true},
  },
  {
    path: 'click-and-collect/event/:eventId',
    component: CounterPickUpComponent,
    canActivate: [AuthGuard],
    data: {cAndC: true},
  },
  {
    path: 'role/list',
    component: RoleListComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {permissionNameList: [PermissionName.ROLE_LIST]},
  },
  {
    path: 'role/details/:id',
    component: RoleDetailsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {permissionNameList: [PermissionName.ROLE_VIEW]},
  },
  {
    path: 'role/add',
    component: RoleAddComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {permissionNameList: [PermissionName.ROLE_CREATE]},
  },
  {
    path: '**',
    redirectTo: 'mission-list'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
