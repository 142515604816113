import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ShopStyle } from '../../../../lib/lib-shared/types/ShopStyle';
import { ShopLogo } from '../../../../lib/lib-shared/types/ShopLogo';
import { ShopEndpoint } from '../../../../lib/lib-shared/types/ShopEndpoint';

@Component({
  selector: 'lm-add-store',
  templateUrl: './add-store.component.html',
  styleUrls: ['./add-store.component.scss']
})
export class AddStoreComponent implements OnInit {
  public ShopStyle = ShopStyle;
  public ShopLogo = ShopLogo;
  public ShopEndpoint = ShopEndpoint;

  public addStoreForm: FormGroup;
  private address: google.maps.places.PlaceResult;
  public lat = 48.874774;
  public lng = 2.338182;
  public zoom = 14;
  @ViewChild('searchElementRef')
  public searchElement: ElementRef;
  @Output('addStore') addStore = new EventEmitter<boolean>();
  @Output('cancel') cancel = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.loadGoogleMapAPI();
  }

  /**
   * Method to build the add a new address form
   */
  buildForm() {
    this.addStoreForm = this.fb.group({
      address: ['', Validators.required],
      ref: ['', Validators.required],
      storeName: ['', Validators.required],
      style: [ShopStyle.BLACK, Validators.required],
      logo: [ShopLogo.LM, Validators.required],
      endpoint: [null],
      storeColor: ['#ff9900', Validators.required],
      phoneNumber: ['', Validators.required],
    });
  }

  /**
   * Method called after click on add a store; it'll add a new store locally
   */
  onAddStore() {
    const number = PhoneNumberUtil.getInstance().parseAndKeepRawInput(this.addStoreForm.controls['phoneNumber'].value);
    this.apiLavandierService.postShop(this.addStoreForm.controls['style'].value, this.addStoreForm.controls['logo'].value, this.addStoreForm.controls['endpoint'].value, this.addStoreForm.controls['ref'].value, this.addStoreForm.controls['storeName'].value, this.addStoreForm.controls['storeColor'].value, PhoneNumberUtil.getInstance().format(number, PhoneNumberFormat.INTERNATIONAL), this.address)
      .subscribe(() => {
        this.buildForm();
        this.addStore.emit(true);
      });
  }

  /**
   * Load Google API map and add listener to the address input
   */
  loadGoogleMapAPI() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          // verify result
          this.address = place;
          // set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
        });
      });
    });
  }
}
