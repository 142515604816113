import { Component, OnInit } from '@angular/core';
import { WeekDay } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SetSlotModalComponent } from '../../modals/set-slot-modal/set-slot-modal.component';
import { ManageSlotModalComponent } from '../../modals/manage-slot-modal/manage-slot-modal.component';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'lm-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss']
})
export class SlotComponent implements OnInit {
  public timeSlotTypeList = [];
  public shopList = [];
  public selectedLmp = -1;

  constructor(
    private modalService: NgbModal,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.apiLavandierService.getTimeSlotTypes().subscribe(
      (data: Object[]) => this.timeSlotTypeList = data
    );

    this.apiLavandierService.getShops().subscribe(
      data => this.handleGetShopListSuccess(data)
    );
  }

  handleGetShopListSuccess(data) {
    this.shopList = data;

    this.shopList.forEach(function (shop, shopIndex, shopList) {
      const totalList = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 0: 0};
      const timeSlotByDayList = [
        {day: WeekDay.Monday, slotList: []},
        {day: WeekDay.Tuesday, slotList: []},
        {day: WeekDay.Wednesday, slotList: []},
        {day: WeekDay.Thursday, slotList: []},
        {day: WeekDay.Friday, slotList: []},
        {day: WeekDay.Saturday, slotList: []},
        {day: WeekDay.Sunday, slotList: []}
      ];

      shop.timeSlots.forEach(function (timeSlot, slotIndex, timeSlots) {
        timeSlots[slotIndex].total = timeSlot.newSlot + timeSlot.oldSlot;
        totalList[timeSlot.day] += timeSlots[slotIndex].total;
        const index = timeSlotByDayList.map(function (timeSlotByDay) {
          return timeSlotByDay.day;
        }).indexOf(timeSlot.day);
        timeSlotByDayList[index].slotList.push(timeSlot);
      });

      shopList[shopIndex].totalList = totalList;
      shopList[shopIndex].timeSlotByDayList = timeSlotByDayList;
      shopList[shopIndex].total = Object.keys(totalList).reduce(function (previous, key) {
        return previous + totalList[key];
      }, 0);
    });
  }

  /**
   * Method called after click on a LMP option in head div select
   * @param lmpSelected Index of which lmp is selected (-1 if it's all and >=0 if it's a specific lmp
   */
  onChangeLmp(lmpSelected) {
    this.selectedLmp = lmpSelected;
  }

  /**
   * Method called after click on 'Paramètrage créneaux'
   * it'll open a modal
   */
  onSetSlot() {
    const setSlotModal = this.modalService.open(SetSlotModalComponent, {size: 'lg'});
    setSlotModal.componentInstance.timeSlotTypeList = this.timeSlotTypeList;
    setSlotModal.result
      .then((result) => {
        if (result) {
          this.ngOnInit();
        }
      })
      .catch(error => {
      });
  }

  /**
   * Method called after click on add a slot
   * It'll open manageSlotModal
   */
  addSlotModal() {
    const manageSlotModal = this.modalService.open(ManageSlotModalComponent, {size: 'lg'});
    manageSlotModal.componentInstance.shopList = this.shopList;
    manageSlotModal.componentInstance.timeSlotTypeList = this.timeSlotTypeList;
    manageSlotModal.result
      .then(() => this.ngOnInit())
      .catch(error => {
      });
  }

  deleteTimeSlotModal(timeSlot) {
    const confirmationModal = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
    confirmationModal.componentInstance.confirmationDesc = 'Voulez-vous vraiment supprimer ce créneaux ?';
    confirmationModal.componentInstance.validateButtonText = 'Supprimer';
    confirmationModal.componentInstance.cancelButtonText = 'Annuler';
    confirmationModal.result
      .then((result) => {
        if (result) {
          this.apiLavandierService.deleteTimeSlot(timeSlot.id)
            .subscribe(() => this.ngOnInit());
        }
      })
      .catch(error => {
      });
  }

  /**
   * Method called after click on edit a slot (pencil icon)
   * It'll open manageSlotModal
   */
  editSlotModal(slot, day) {
    const manageSlotModal = this.modalService.open(ManageSlotModalComponent, {size: 'lg'});
    manageSlotModal.componentInstance.shopList = this.shopList;
    manageSlotModal.componentInstance.timeSlotTypeList = this.timeSlotTypeList;
    manageSlotModal.componentInstance.slotSelected = slot;
    manageSlotModal.componentInstance.day = day;
    manageSlotModal.result
      .then(() => this.ngOnInit())
      .catch(error => {
      });
  }
}
