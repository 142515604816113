import { Component, ElementRef, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'lm-scan-tracking-sheet-details',
  templateUrl: './scan-tracking-sheet-details.component.html',
  styleUrls: ['./scan-tracking-sheet-details.component.scss']
})
export class ScanTrackingSheetDetailsComponent implements OnInit {

  @Input() trackingSheet = null;
  @Input() trackingSheetStorage = null;
  @Output('focus') focus = new EventEmitter<boolean>();

  public storageNumberFormControl = new FormControl();
  @ViewChild('storageNumberInput') public storageNumberInput: ElementRef;

  public storageError = false;
  public shopStorage = null;
  public orderArticleTrackingScanStatusMap = new Map();

  constructor(
    public apiLavandierService: ApiLavandierService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.trackingSheet.orderArticleTrackingList.forEach(orderArticleTracking =>
      this.orderArticleTrackingScanStatusMap.set(orderArticleTracking.id, undefined));
    this.reset();

    this.storageNumberFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(ref => {
          this.storageError = false;
          this.storageNumberFormControl.disable({emitEvent: false});
          if (ref && ref !== '') {
            if (!this.allArticlesScan()) {
              for (const orderArticleTracking of this.trackingSheet.orderArticleTrackingList) {
                if (orderArticleTracking.ref === ref) {
                  this.apiLavandierService.putTrackingSheetShopStorage(orderArticleTracking.id, {tsMissing: false})
                    .subscribe(() => {
                      this.orderArticleTrackingScanStatusMap.set(orderArticleTracking.id, true);
                      this.reset();
                      return of(null);
                    });
                }
              }
              if (!this.refExist(ref)) {
                const confirmationModal = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
                confirmationModal.componentInstance.confirmationDesc = 'Cet article n\'appartient pas à cette tracking sheet';
                confirmationModal.componentInstance.cancelButtonText = 'Fermer';
                confirmationModal.result
                  .then(() => {
                    this.reset();
                  })
                  .catch();
              }
              return of(null);
            } else {
              return this.apiLavandierService.getShopStorageByRef({ref: ref})
                .pipe(
                  catchError(() => {
                    this.storageError = true;
                    return of(null);
                  })
                );
            }
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(shopStorage => {
        if (shopStorage) {
          this.shopStorage = shopStorage;
          this.apiLavandierService.putTrackingSheetStorageIdStorage(this.trackingSheetStorage.id, {shopStorageId: shopStorage.id})
            .subscribe();
          this.reset();
        } else {
          if (!this.shopStorage) {
            // display error storage
            if (this.allArticlesScan() && this.storageNumberFormControl.value) {
              this.storageError = true;
            }
            this.reset(false);
          } else {
            this.focus.emit(true);
          }
        }
      });
  }

  missingArticle(orderArticleTracking) {
    const confirmationModal = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
    confirmationModal.componentInstance.confirmationDesc = 'Êtes vous sur de vouloir marquer cet article comme manquant ?';
    confirmationModal.componentInstance.validateButtonText = 'Valider';
    confirmationModal.componentInstance.cancelButtonText = 'Annuler';
    confirmationModal.result.then((result) => {
      if (result) {
        this.apiLavandierService.putTrackingSheetShopStorage(orderArticleTracking.id, {tsMissing: true})
          .subscribe(() => {
            this.orderArticleTrackingScanStatusMap.set(orderArticleTracking.id, false);
            this.reset();
          });
      }
    }).catch(error => null);
  }

  allArticlesScan() {
    let isAllScan = true;
    this.orderArticleTrackingScanStatusMap.forEach(value => {
      if (value === undefined) {
        isAllScan = false;
      }
    });
    return isAllScan;
  }

  refExist(ref) {
    let exist = false;
    this.trackingSheet.orderArticleTrackingList.forEach(value => {
      if (value.ref === ref) {
        exist = true;
      }
    });
    return exist;
  }

  reset(reset = true) {
    this.storageNumberFormControl.enable({emitEvent: false});
    this.getStorageNumberInputFocus();

    if (reset) {
      this.storageNumberFormControl.reset();
    }
  }

  getStorageNumberInputFocus() {
    setTimeout(() => this.storageNumberInput.nativeElement.focus());
  }
}
