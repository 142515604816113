import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ErrorService } from '../../../../lib/lib-ngx/services/error.service';
import { OrderTemporary } from '../../../../lib/lib-shared/types/OrderTemporary';
import { ErrorMessage } from '../../../../lib/lib-shared/ErrorMessage';
import { OrderStatusStatus } from '../../../../lib/lib-shared/types/OrderStatusStatus';

@Component({
  selector: 'lm-quarterback-transfer-modal',
  templateUrl: './quarterback-transfer-modal.component.html',
  styleUrls: ['./quarterback-transfer-modal.component.scss']
})
export class QuarterbackTransferModalComponent implements OnInit {
  public orderId = null;

  public allTsArePrepared = false;

  constructor(
    public activeModal: NgbActiveModal,
    private apiLavandierService: ApiLavandierService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
  }

  isAllTsArePrepared(value: boolean) {
    this.allTsArePrepared = value;
  }

  updateOrder(order) {
    if (order.finish) {
      this.errorService.showModal(ErrorMessage.ORDER_ALREADY_FINISH);
      this.activeModal.close();
      return;
    }
    if (order.temporary === OrderTemporary.NONE) {
      this.errorService.showModal(ErrorMessage.ORDER_NOT_TEMPORARY);
      this.activeModal.close();
      return;
    }
    if (order.orderStatuses[order.orderStatuses.length - 1].status !== OrderStatusStatus.STOW) {
      this.errorService.showModal(ErrorMessage.NOT_A_DELIVERABLE_ORDER);
      this.activeModal.close();
      return;
    }

    const orderArticleList = [];
    for (const orderBag of order.orderBags) {
      orderArticleList.push(...orderBag.orderArticles);
    }

    const trackingSheetMap: Map<number, any> = new Map();
    for (const orderArticle of orderArticleList) {
      for (const orderArticleTracking of orderArticle.orderArticleTrackingList) {
        if (orderArticleTracking.trackingSheet) {
          trackingSheetMap.set(orderArticleTracking.trackingSheet.id, orderArticleTracking.trackingSheet);
        }
      }
    }

    if (!(Array.from(trackingSheetMap.values()).every(trackingSheet => {
        const lastTrackingSheetStorage = trackingSheet.trackingSheetStorageList[trackingSheet.trackingSheetStorageList.length - 1];
        return lastTrackingSheetStorage && lastTrackingSheetStorage.shopStorageId;
      })
      && orderArticleList.every(orderArticle => orderArticle.orderArticleTrackingList.every(orderArticleTracking => orderArticleTracking.trackingSheetId))
      && order.orderBags.every(orderBag => orderBag.finishAt))) {
      this.errorService.showModal(ErrorMessage.ORDER_NOT_TRANSFERABLE);
      this.activeModal.close();
      return;
    }
  }

  onValidate() {
    this.apiLavandierService.putOrderIdTransfer(this.orderId)
      .subscribe((data: any) => {
        if (!this.errorService.manageError(data)) {
          this.activeModal.close();
        }
      });
  }
}
