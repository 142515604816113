import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

import { AuthService } from '../lib/lib-ngx/web-services/auth.service';
import { PermissionName } from '../lib/lib-shared/types/PermissionName';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const permissionNameList = next.data.permissionNameList as Array<PermissionName>;

    if (!this.authService.getDecodeToken().roleList) {
      this.authService.logout();
      this.router.navigate(['/login']);
      return false;
    }

    return this.authService.getDecodeToken().roleList.some(role => permissionNameList.some(permissionName => permissionName === role));
  }
}
