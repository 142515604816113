import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-add-store-perimeter',
  templateUrl: './add-store-perimeter.component.html',
  styleUrls: ['./add-store-perimeter.component.scss']
})
export class AddStorePerimeterComponent implements OnInit {
  @Input('store') store;
  @Output('addStorePerimeter') addStorePerimeter = new EventEmitter<boolean>();
  public addPerimeterForm: FormGroup; // Form for adding a new perimeter

  @ViewChild('addressElementRef')
  public addressElementRef: ElementRef;
  public address: google.maps.places.PlaceResult = null;

  constructor(
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.initGMaps();
  }

  /**
   * Method to build form for adding a new perimeter
   */
  buildForm() {
    this.addPerimeterForm = this.fb.group({
      address: ['', Validators.required],
      timeLimit: ['0', Validators.required]
    });
  }

  initGMaps() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.addressElementRef.nativeElement, {
        types: ['(regions)'],
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          this.address = autocomplete.getPlace();
        });
      });
      this.renderer.listen(this.addressElementRef.nativeElement, 'change', () => {
        this.ngZone.run(() => {
          this.address = null;
        });
      });
    });
  }

  /**
   * Method called after click on add a new perimeter or cancel an adding
   * @param event Must be cancel or validate
   */
  onSubmitForm(event) {
    if (event === 'validate') {
      this.apiLavandierService.postShopPerimeter(this.store.id, this.address, this.addPerimeterForm.controls['timeLimit'].value)
        .subscribe(
          () => {
            this.buildForm();
            this.addStorePerimeter.emit(true);
          }
        );
    } else {
      this.addStorePerimeter.emit(false);
    }
  }
}
