import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ErrorService } from '../../../../lib/lib-ngx/services/error.service';

@Component({
  selector: 'lm-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  private userId = null;
  public user = null;
  public userTypeList = [];
  public accountList = [];

  public showForm = false;

  public form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private apiLavandierService: ApiLavandierService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private router: Router,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.userId = param.id !== undefined ? param.id : null;
      this.loadData();
    });
  }

  loadData() {
    return forkJoin([
      this.userId !== null ? this.apiLavandierService.getUser(this.userId) : of(null),
      this.apiLavandierService.getUserTypeDetailsList(),
    ])
      .pipe(
        map(([user, userTypeList]: [any, any[]]) => {
          return [
            user,
            userTypeList,
          ];
        })
      )
      .subscribe(([user, userTypeList]) => {
        this.user = user;
        this.userTypeList = userTypeList;
        this.buildForm();
      });
  }

  buildForm() {
    let userTypeValue = null;
    if (this.user) {
      userTypeValue = this.userTypeList.find(userType => userType.id === this.user.userTypeId);
      if (userTypeValue.endpoint) {
        this.userTypeList = this.userTypeList.filter(userType => userType.endpoint === userTypeValue.endpoint);
      } else {
        this.userTypeList = [userTypeValue];
      }
    }

    this.form = this.fb.group({
      userType: [userTypeValue, Validators.required],
      account: [null, Validators.required],
      dataConsent: [this.user ? this.user.dataConsent : null, Validators.required],
      multipleArticle: [this.user ? this.user.multipleArticle : false, Validators.required],
      orderName: [this.user ? this.user.orderName : false, Validators.required],
      email: [this.user ? this.user.email : null, Validators.compose([Validators.email, Validators.required])],
      firstName: [this.user ? this.user.firstName : '', Validators.required],
      lastName: [this.user ? this.user.lastName : '', Validators.required],
      phoneNumber: [this.user ? this.user.phoneNumber : null, Validators.required],
    });

    if (this.user) {
      this.form.addControl('important', this.fb.control(this.user.important, Validators.required));
      this.form.addControl('fakePayment', this.fb.control(this.user.fakePayment, Validators.required));
    }

    this.form.controls.userType.valueChanges
      .subscribe(userType => {
        this.form.controls.account.setValue(null);
        this.accountList = [];
        this.form.controls.account.disable();

        if (userType) {
          if (!userType.endpoint) {
            this.accountList = userType.accountList;
            this.form.controls.account.enable();
            this.form.controls.email.setValidators(null);
            if (!this.user) {
              this.form.controls.dataConsent.enable();
              this.form.controls.dataConsent.setValue(null);
              this.form.controls.dataConsent.updateValueAndValidity();
            }
          } else {
            this.form.controls.email.setValidators(Validators.compose([Validators.email, Validators.required]));
            if (!this.user) {
              this.form.controls.dataConsent.setValue(true);
              this.form.controls.dataConsent.disable();
              this.form.controls.dataConsent.updateValueAndValidity();
            }
          }
        }
        if (this.user) {
          if (this.form.value.userType && !this.form.value.userType.endpoint) {
            this.form.controls.userType.disable();
            this.form.controls.userType.updateValueAndValidity();
          }
          if (this.user.accountId) {
            const accountValue = this.accountList.find(account => account.id === this.user.accountId);
            this.form.controls.account.setValue(accountValue);
          }
          this.form.controls.account.disable();
        }
        this.form.controls.account.updateValueAndValidity();
        this.form.controls.email.updateValueAndValidity();
      });

    // this.showForm = !this.userId;
    this.showForm = true;
    this.form.controls.userType.updateValueAndValidity();
  }

  cancel() {
    /*
    if (this.user) {
      this.showForm = false;
    } else {
      this.router.navigate(['user/list']);
    }
     */
    this.router.navigate(['user/list']);
  }

  save() {
    let phoneNumber = null;
    if (this.form.value.phoneNumber) {
      const number = PhoneNumberUtil.getInstance().parseAndKeepRawInput(this.form.value.phoneNumber);
      phoneNumber = PhoneNumberUtil.getInstance().format(number, PhoneNumberFormat.INTERNATIONAL);
    }

    if (this.user) {
      this.apiLavandierService.putUser(this.user.id, {
        userTypeId: this.form.value.userType ? this.form.value.userType.id : undefined,
        important: this.form.value.important,
        dataConsent: this.form.value.dataConsent,
        fakePayment: this.form.value.fakePayment,
        multipleArticle: this.form.value.multipleArticle,
        orderName: this.form.value.orderName,
        email: this.form.value.email ? this.form.value.email : null,
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        phoneNumber: phoneNumber,
      })
        .subscribe((data) => {
          if (!this.errorService.manageError(data)) {
            this.router.navigate(['user-profile', this.user.id]);
          }
        });
    } else {
      this.apiLavandierService.postUser({
        userTypeId: this.form.value.userType.id,
        accountId: this.form.value.account ? this.form.value.account.id : null,
        dataConsent: this.form.value.userType.endpoint ? true : this.form.value.dataConsent,
        multipleArticle: this.form.value.multipleArticle,
        orderName: this.form.value.orderName,
        email: this.form.value.email ? this.form.value.email : null,
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        phoneNumber: phoneNumber,
      })
        .subscribe((user: any) => {
          if (!this.errorService.manageError(user)) {
            this.router.navigate(['user-profile', user.id]);
          }
        });
    }
  }
}
