import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { IRole } from '../../../../lib/lib-ngx/web-services/api-lavandier.service.type';
import { EnumToArrayPipe } from '../../../../lib/lib-ngx/pipes/enum-to-array.pipe';
import { PermissionName } from '../../../../lib/lib-shared/types/PermissionName';

@Component({
  selector: 'lm-role-details',
  templateUrl: './role-details.component.html',
  styleUrls: ['./role-details.component.scss']
})
export class RoleDetailsComponent implements OnInit {
  public PermissionName = PermissionName;

  public roleId: number = null;
  public roleDetails = null;
  public addRoleForm: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiLavandierService: ApiLavandierService,
    private fb: FormBuilder,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params
      .subscribe((param) => {
        this.roleId = parseInt(param.id, 10);
        this.loadRoleDetails();
        this.buildForm();
      });
  }

  buildForm() {
    this.addRoleForm = this.fb.group({
      name: ['', Validators.required],
    });
    const enumToArrayPipe = new EnumToArrayPipe();
    enumToArrayPipe.transform(PermissionName).forEach((permissionName) => {
      this.addRoleForm.addControl(permissionName.toLowerCase(), new FormControl(false));
    });
  }

  loadRoleDetails() {
    this.apiLavandierService.getRole(this.roleId)
      .subscribe(
        (roleDetails: any) => {
          this.roleDetails = roleDetails;
          this.patchValue();
        }
      );
  }

  patchValue() {
    this.addRoleForm.patchValue({
      name: this.roleDetails.name,
    });
    this.roleDetails.permissionList.forEach((permission) => {
      this.addRoleForm.patchValue({
        [permission.name.toLowerCase()]: true,
      });
    });
  }

  editRoleName() {
    const role: IRole = {
      name: this.addRoleForm.controls['name'].value,
      permissionNameList: [],
    };

    new EnumToArrayPipe().transform(PermissionName).forEach((permissionName) => {
      if (this.addRoleForm.controls[permissionName.toLowerCase()].value) {
        role.permissionNameList.push(permissionName);
      }
    });

    this.apiLavandierService.putRole(this.roleId, role)
      .subscribe(
        () => this.router.navigate(['/role/list'])
      );
  }
}
