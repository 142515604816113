import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { ApiLavandierService } from '../../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { CreditType } from '../../../../../lib/lib-shared/types/CreditType';
import { PRICEMULTIPLIER } from '../../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-user-credit',
  templateUrl: './user-credit.component.html',
  styleUrls: ['./user-credit.component.scss']
})
export class UserCreditComponent implements OnInit {
  public PRICEMULTIPLIER = PRICEMULTIPLIER;
  public CreditType = CreditType;

  @ViewChild('toChargeTable') toChargeTable: any;
  @ViewChild('chargedTable') chargedTable: any;
  @Input() user = null;
  @Input() lavandierMap = new Map();
  public creditToChargeList = [];
  public creditChargedList = [];

  constructor(
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.loadCreditUserList();
  }

  loadCreditUserList() {
    this.creditToChargeList = [];
    this.creditChargedList = [];
    this.apiLavandierService.getCreditUser(this.user.id)
      .subscribe(
        (creditList: any[]) => {
          const creditParentList = [];
          const creditParentIdMap = new Map();
          creditList.forEach((credit) => {
            if (credit.parentId === null) {
              credit.amountLeft = credit.amount;
              credit.childrenList = [];
              creditParentList.push(credit);
            } else {
              creditParentIdMap.set(credit.parentId, credit);
            }
          });

          creditParentList.forEach((credit) => {
            if (credit.type === CreditType.MINUS) {
              if (credit.orderCredit !== null) {
                credit.amountLeft -= credit.orderCredit.amount;
              }
              if (creditParentIdMap.has(credit.id)) {
                credit.amountLeft -= this.calcAmountLeft(creditParentIdMap.get(credit.id), credit.childrenList, creditParentIdMap);
              }
            }
            if (credit.type === CreditType.PERCENT) {
              if (credit.orderCredit !== null) {
                credit.amountLeft = 0;
              }
            }
            if (credit.amountLeft > 0 && (!credit.endedAt || moment(credit.endedAt).isAfter())) {
              this.creditToChargeList.push(credit);
            } else {
              this.creditChargedList.push(credit);
            }
          });
          // Load ngx-datatable
          this.creditToChargeList = [...this.creditToChargeList]; // Refresh data issue
          this.creditChargedList = [...this.creditChargedList];
          setTimeout(() => this.toChargeTable.rowDetail.expandAllRows());
          setTimeout(() => this.chargedTable.rowDetail.expandAllRows());
        }
      );
  }

  calcAmountLeft(credit, childrenList, creditParentIdMap) {
    childrenList.push(credit);
    if (!creditParentIdMap.has(credit.id)) {
      return credit.orderCredit !== null ? credit.orderCredit.amount : 0;
    }
    return this.calcAmountLeft(creditParentIdMap.get(credit.id), childrenList, creditParentIdMap) + credit.orderCredit.amount;
  }
}
