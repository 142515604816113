import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-scooters',
  templateUrl: './scooters.component.html',
  styleUrls: ['./scooters.component.scss']
})
export class ScootersComponent implements OnInit {
  public scooterList = [];
  public scooterTempList = [];
  public lmpList = [];
  public lmpSelected = {};
  public onAddScooter = false;

  @ViewChild(DatatableComponent)
  table: DatatableComponent;

  constructor(private apiLavandierService: ApiLavandierService,
              public sanitizer: DomSanitizer,
              private router: Router) {
  }

  ngOnInit() {
    this.apiLavandierService.getShops()
      .subscribe((data: Object[]) => this.lmpList = data);
    this.getScooterList();
  }

  getScooterList() {
    this.apiLavandierService.getScooterData()
      .subscribe((data: Object[]) => {
        this.scooterList = data;
        this.scooterTempList = [...data];
      });
  }

  onLmp(event) {
    const index = event.target.selectedIndex - 1;
    this.lmpSelected = index === -1 ? {} : this.lmpList[index];
    this.filterLmp();
  }

  filterLmp() {
    const lmp = this.lmpSelected['name'] === undefined ? '' : this.lmpSelected;

    // filter our data
    const temp = this.scooterTempList.filter(function (d) {
      return lmp['name'] === undefined || lmp['name'] === d.shop.name;
    });

    // update the rows
    this.scooterList = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  onScooterProfile(id) {
    if (event.type === 'click') {
      this.router.navigate(['/scooter-detail', id]);
    }
  }

  addScooter(scooterAdded) {
    this.onAddScooter = !this.onAddScooter;
    if (scooterAdded) {
      this.getScooterList();
    }
  }

}
