import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { FakeApiService } from '../../../services/fake-api.service';

@Component({
  selector: 'lm-reattribute-mission-modal',
  templateUrl: './reattribute-mission-modal.component.html',
  styleUrls: ['./reattribute-mission-modal.component.scss']
})
export class ReattributeMissionModalComponent implements OnInit {
  public mission;
  public lavandierList = [];
  public lavandierSelected = {};

  constructor(public activeModal: NgbActiveModal,
              private fakeApiService: FakeApiService) { }

  ngOnInit() {
    // console.log(this.mission);
    this.fakeApiService.getAvailableLavandierData().subscribe(
      result => {
        this.lavandierList = result.data.lavandierList;
      }
    );
  }

  onSubmit() {
    // console.log(this.lavandierSelected);
    this.activeModal.close({
      lavandier: this.lavandierSelected
    });
  }

}
