import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { TitleCasePipe } from '@angular/common';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { IOrderAdmin } from '../../../../lib/lib-ngx/web-services/api-lavandier.service.type';
import { AuthService } from '../../../../lib/lib-ngx/web-services/auth.service';
import { ErrorService } from '../../../../lib/lib-ngx/services/error.service';
import { UserTypeOrderType } from '../../../../lib/lib-shared/types/UserTypeOrderType';
import { EditTransportScheduleModalComponent } from '../../modals/edit-transport-schedule-modal/edit-transport-schedule-modal.component';
import { PRICEMULTIPLIER } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  public PRICEMULTIPLIER = PRICEMULTIPLIER;
  public UserTypeOrderType = UserTypeOrderType;

  public userId = null;
  public userProfileData: any = null;
  public activeTab = 'user';
  public schedulePickUp = false;
  public userTypeMap = new Map();
  public shopMap = new Map();
  public lavandierMap = new Map();
  public userAddressList = [];
  public addressMap = new Map();

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiLavandierService: ApiLavandierService,
    private modalService: NgbModal,
    public authService: AuthService,
    private router: Router,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.schedulePickUp = false;
    this.activatedRoute.params.subscribe(params => {
        this.userId = params.id;
        this.loadData();
      }
    );
  }

  loadData() {
    return forkJoin([
      this.apiLavandierService.getUser(this.userId),
      this.apiLavandierService.getUserAddressList(this.userId),
      this.apiLavandierService.getUserTypeList(),
      this.apiLavandierService.getShops(),
      this.apiLavandierService.getLavandiers(),
    ])
      .pipe(
        map(([user, userAddressList, userTypeList, shopList, lavandierList]: [Object, Object[], Object[], Object[], any]) => {
          return [
            user,
            userAddressList,
            ListToMap.convert(userAddressList),
            ListToMap.convert(userTypeList),
            ListToMap.convert(shopList),
            ListToMap.convert(lavandierList.rows),
          ];
        })
      )
      .subscribe(([user, userAddressList, addressMap, userTypeMap, shopMap, lavandierMap]: [Object, Object[], Map<number, Object>, Map<number, Object>, Map<number, Object>, Map<number, Object>]) => {
        this.userProfileData = user;
        this.userAddressList = userAddressList;
        this.addressMap = addressMap;
        this.userTypeMap = userTypeMap;
        this.shopMap = shopMap;
        this.lavandierMap = lavandierMap;
      });
  }

  onFreezeAccount() {
    const confirmationModal = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
    if (!this.userProfileData.enabled) {
      confirmationModal.componentInstance.confirmationDesc = 'Voulez-vous vraiment dégeler le compte de cet utilisateur ?';
      confirmationModal.componentInstance.validateButtonText = 'Dégeler le compte';
    } else {
      confirmationModal.componentInstance.confirmationDesc = 'Voulez-vous vraiment geler le compte de cet utilisateur ?';
      confirmationModal.componentInstance.validateButtonText = 'Geler le compte';
    }
    confirmationModal.componentInstance.cancelButtonText = 'Annuler';
    confirmationModal.result
      .then((result) => {
        if (result) {
          this.apiLavandierService.putUserIdEnabled(this.userProfileData.id, {enabled: !this.userProfileData.enabled})
            .subscribe(() => this.ngOnInit());
        }
      });
  }

  onAddPickUp(orderTransportData) {
    const orderAdmin: IOrderAdmin = {
      bagList: [],
      userId: this.userProfileData.id,
      incidentId: null,
      shopId: orderTransportData.shopId,
      express: false,
      discountCodeCode: null,
      stripeCardId: null,
      date: orderTransportData.date,
      start: orderTransportData.start,
      end: orderTransportData.end,
      addresses: orderTransportData.addressList,
    };

    this.apiLavandierService.postOrder(orderAdmin)
      .subscribe((data: any) => {
        if (!this.errorService.manageError(data)) {
          const confirmModal = this.modalService.open(ConfirmationModalComponent);
          const fullName = new TitleCasePipe().transform(`${this.userProfileData.firstName} ${this.userProfileData.lastName}`);
          confirmModal.componentInstance.confirmationDesc = 'Confirmation de la création d\'un retrait pour ' + fullName;
          confirmModal.componentInstance.validateButtonText = 'Ok';
          confirmModal.result
            .then(() => this.router.navigate(['/order-details', data.order.id]))
            .catch(() => this.router.navigate(['/order-details', data.order.id]));
        }
      });
  }

  openEditTransportScheduleModal(transportSchedule?) {
    const editTransportScheduleModal = this.modalService.open(EditTransportScheduleModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    editTransportScheduleModal.componentInstance.user = this.userProfileData;
    editTransportScheduleModal.componentInstance.transportSchedule = transportSchedule;
    editTransportScheduleModal.componentInstance.shopMap = this.shopMap;
    editTransportScheduleModal.componentInstance.addressMap = this.addressMap;
    editTransportScheduleModal.result
      .then((reload: boolean) => reload ? this.ngOnInit() : null);
  }

  onActivateTransportSchedule(event) {
    if (event.type === 'click') {
      event.cellElement.blur();

      this.openEditTransportScheduleModal(event.row);
    }
  }

  onClickDeleteTransportSchedule(event, transportSchedule) {
    event.stopPropagation();
    const confirmationModal = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
    confirmationModal.componentInstance.confirmationDesc = 'Voulez-vous vraiment supprimer cette Programmation récurrente ?';
    confirmationModal.componentInstance.validateButtonText = 'Supprimer';
    confirmationModal.componentInstance.cancelButtonText = 'Annuler';
    confirmationModal.result
      .then((result) => {
        if (result) {
          this.apiLavandierService.deleteTransportSchedule(transportSchedule.id)
            .subscribe(() => this.ngOnInit());
        }
      })
      .catch(() => null);
  }
}
