import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumNumberValueToArray'
})
export class EnumNumberValueToArrayPipe implements PipeTransform {

  transform(data: any, args?: any): any {
    return Object.values(data).filter(val => typeof val === 'number');
  }
}
