import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { IMembershipDiscount } from '../../../../lib/lib-ngx/web-services/api-lavandier.service.type';
import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { PRICEMULTIPLIER } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-membership-discount-edit',
  templateUrl: './membership-discount-edit.component.html',
  styleUrls: ['./membership-discount-edit.component.scss']
})
export class MembershipDiscountEditComponent implements OnInit {
  public membershipDiscountId: number;
  public membershipDiscount = null;
  public rangeMap = new Map();
  public memberDiscountEditForm: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiLavandierService: ApiLavandierService,
    private fb: FormBuilder,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      this.membershipDiscountId = parseInt(param.membershipDiscountId, 10);
      this.resetData();
      this.loadData();
    });
  }

  resetData() {
    this.membershipDiscount = null;
    this.rangeMap = new Map();
  }

  loadData() {
    forkJoin([
      this.apiLavandierService.getMembershipDiscountById(this.membershipDiscountId),
      this.apiLavandierService.getArticleRangeList(),
    ]).pipe(map(([membershipDiscount, articleRangeList]: [any, any[]]) => {
      return [
        membershipDiscount,
        ListToMap.convert(articleRangeList),
      ];
    })).subscribe(([membershipDiscount, articleRangeList]) => {
      this.membershipDiscount = membershipDiscount;
      this.rangeMap = articleRangeList;
      this.buildForm();
    });
  }

  buildForm() {
    this.memberDiscountEditForm = this.fb.group({
      percent: [this.membershipDiscount.percent / PRICEMULTIPLIER, [Validators.required, Validators.min(0), Validators.max(100)]],
    });
  }

  onValidateMembershipDiscount() {
    const membershipDiscount: IMembershipDiscount = {
      id: this.membershipDiscountId,
      percent: this.memberDiscountEditForm.value.percent * PRICEMULTIPLIER,
    };

    this.apiLavandierService.putMembershipDiscount(this.membershipDiscount.id, membershipDiscount)
      .subscribe((() => this.router.navigate(['/membership-discount-setting'])));
  }
}
