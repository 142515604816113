import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { IIncidentType, IncidentTypeVisibility } from '../../../../lib/lib-ngx/web-services/api-lavandier.service.type';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { IncidentTypeSeverity } from '../../../../lib/lib-shared/types/IncidentTypeSeverity';

@Component({
  selector: 'lm-incident-type-setting',
  templateUrl: './incident-type-setting.component.html',
  styleUrls: ['./incident-type-setting.component.scss']
})
export class IncidentTypeSettingComponent implements OnInit {
  public IncidentTypeSeverity = IncidentTypeSeverity;

  public manageIncidentTypeSettingForm: FormGroup;
  public onShowForm = false;
  public editIncidentType = null;
  public incidentTypeList = [];
  public filterForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.buildFormFilter();

    this.filterForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(data => {
          return this.apiLavandierService.getIncidentTypes({
            public: data.public,
            old: data.old,
          });
        }),
      )
      .subscribe((data: Object[]) => this.incidentTypeList = data);
    this.filterForm.updateValueAndValidity();
  }

  /**
   * Method called to reset the form with his default value;
   * Also put onShowForm to false and editIncidentType to null
   */
  buildForm() {
    this.onShowForm = false;
    this.editIncidentType = null;
    this.manageIncidentTypeSettingForm = this.fb.group({
      name: ['', Validators.required],
      severity: [IncidentTypeSeverity.CRITICAL, Validators.required],
      public: [''],
      old: ['']
    });
  }

  /**
   * Method called when user click on 'Ajouter un type d'incident'
   * It'll reset the form and put onShowForm to true
   */
  openAddIncidentType() {
    this.buildForm();
    this.onShowForm = true;
  }

  /**
   * Method called when user click on 'Modifier' in the ngx-datatable row
   * It'll save the incidentType to editIncidentType and patch value from incidentType
   * @param incidentType IncidentType to edit
   */
  patchValueToOpenEditIncidentType(incidentType) {
    this.onShowForm = true;
    this.editIncidentType = incidentType;
    this.manageIncidentTypeSettingForm.patchValue({
      name: incidentType.name,
      severity: incidentType.severity,
      public: (IncidentTypeVisibility.PUBLIC & incidentType.visibility),
      old: (IncidentTypeVisibility.OLD & incidentType.visibility),
    });
  }

  onValidateIncidentType() {
    let visible = 0;
    if (this.manageIncidentTypeSettingForm.value.old) {
      visible = visible | IncidentTypeVisibility.OLD;
    }
    if (this.manageIncidentTypeSettingForm.value.public) {
      visible = visible | IncidentTypeVisibility.PUBLIC;
    }
    const incidentType: IIncidentType = {
      severity: this.manageIncidentTypeSettingForm.value.severity,
      name: this.manageIncidentTypeSettingForm.value.name,
      visibility: visible
    };

    this.editIncidentType === null ? this.onAddIncidentType(incidentType) : this.onEditIncidentType(incidentType);
  }

  buildFormFilter() {
    this.filterForm = this.fb.group({
      public: [''],
      old: [''],
    });
  }

  /**
   * Method called when user click on 'Créer ce type d'incident'
   * It'll create an incidentType and call post API to create a new IncidentType
   */
  onAddIncidentType(incidentType) {
    this.apiLavandierService.postIncidentType(incidentType)
      .subscribe(
        () => this.ngOnInit()
      );
  }

  /**
   * Method called after a click on 'Modifier ce type d'incident'
   * It'll will create an IncidentType and call put API to update the IncidentType
   * with his id which is previously saved in editIncidentType
   */
  onEditIncidentType(incidentType) {
    this.apiLavandierService.putIncidentType(this.editIncidentType.id, incidentType)
      .subscribe(
        () => this.ngOnInit()
      );
  }

}
