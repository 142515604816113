import * as moment from 'moment';

export function formatTimeSlots(timeSlots) {
  if (Array.isArray(timeSlots)) {
    timeSlots.forEach(function (timeSlot) {
      timeSlot['formatedDate'] = moment(timeSlot['date']).format('dddd DD MMMM');
      timeSlot['slots'].forEach(function (slot) {
        slot['formatedTime'] = moment.utc(slot['start'], 'HH:mm:ss').format('HH:mm') + ' - ' +
          moment.utc(slot['end'], 'HH:mm:ss').format('HH:mm');
      });
    });
  }
  return timeSlots;
}
