import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'lm-store-perimeter',
  templateUrl: './store-perimeter.component.html',
  styleUrls: ['./store-perimeter.component.scss']
})
export class StorePerimeterComponent implements OnInit {
  public onAddPerimeter = false; // Boolean to hide or show add perimeter div
  public storeSelected = null;
  public storeList = []; // Store's data
  public mapModal = { // modal info for google map
    title: '',
    zoom: 14
  };

  constructor(
    private apiLavandierService: ApiLavandierService,
    public sanitizer: DomSanitizer,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.getShopList();
  }

  getShopList() {
    this.apiLavandierService.getShops().subscribe(
      (storeList: Object[]) => {
        this.storeList = storeList;
        this.storeSelected = this.storeList.length ? this.storeList[0] : null;
      }
    );
  }

  onAddStorePerimeter() {
    this.getShopList();
    this.onAddPerimeter = false;
  }

  /**
   * Method called after a click on trash icon in perimeters
   * @param perimeter which perimeter
   */
  deletePerimeter(perimeter) {
    const confirmationModal = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
    confirmationModal.componentInstance.confirmationDesc = 'Voulez-vous vraiment supprimer ce périmètre ?';
    confirmationModal.componentInstance.validateButtonText = 'Supprimer';
    confirmationModal.componentInstance.cancelButtonText = 'Annuler';
    confirmationModal.result
      .then((result) => {
        if (result) {
          this.apiLavandierService.deleteShopPerimeter(perimeter.id)
            .subscribe(() => this.getShopList());
        }
      })
      .catch(error => {
      });
  }

  /**
   * Method called after click on map icon to show google maps location in modal
   * @param content
   * @param perimeter
   */
  openMap(content, perimeter) {
    this.mapModal.title = perimeter.postalCode;
    this.modalService.open(content, {size: 'lg'});
  }
}
