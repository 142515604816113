import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';

@Component({
  selector: 'lm-add-order-wanted-delivery-modal',
  templateUrl: './add-order-wanted-delivery-modal.component.html',
  styleUrls: ['./add-order-wanted-delivery-modal.component.scss']
})
export class AddOrderWantedDeliveryModalComponent implements OnInit {

  public order = null;

  public form: FormGroup;
  public minWantedDeliveryDate: NgbDateStruct = null;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private ngbDateParserFormatter: NgbDateParserFormatter,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    const now = moment(this.order.estimatedDeliveryAt);
    const minWantedDeliveryDate = now.clone().add(1, 'days');
    this.minWantedDeliveryDate = this.ngbDateParserFormatter.parse(minWantedDeliveryDate.format());

    this.form = this.fb.group({
      addNoShowOrderLine: [true, Validators.required],
      wantedDeliveryDate: [this.minWantedDeliveryDate, Validators.required],
    });
  }

  onValidate() {
    this.activeModal.close({
      addNoShowOrderLine: this.form.value.addNoShowOrderLine,
      date: this.ngbDateParserFormatter.format(this.form.value.wantedDeliveryDate),
    });
  }
}
