import { Injectable } from '@angular/core';

export enum SearchFilter {
  QUARTERBACK,
}

export enum SearchFilterName {
  DATE,
  SHOP,
}

@Injectable({
  providedIn: 'root'
})
export class SearchFilterService {
  private filterMap: Map<SearchFilter, Map<SearchFilterName, any>> = new Map<SearchFilter, Map<SearchFilterName, any>>();

  constructor() {
  }

  public set(searchFilter: SearchFilter, searchFilterName: SearchFilterName, value: any) {
    if (this.filterMap.has(searchFilter)) {
      this.filterMap.get(searchFilter).set(searchFilterName, value);
    } else {
      this.filterMap.set(searchFilter, new Map<SearchFilterName, any>().set(searchFilterName, value));
    }
  }

  public get(searchFilter: SearchFilter, searchFilterName: SearchFilterName): any {
    if (this.filterMap.has(searchFilter) && this.filterMap.get(searchFilter).has(searchFilterName)) {
      return this.filterMap.get(searchFilter).get(searchFilterName);
    } else {
      return null;
    }
  }
}
