import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { FakeApiService } from '../../../services/fake-api.service';

@Component({
  selector: 'lm-attribute-mission-modal',
  templateUrl: './attribute-mission-modal.component.html',
  styleUrls: ['./attribute-mission-modal.component.scss']
})
export class AttributeMissionModalComponent implements OnInit {
  public lavandier = {};
  public availableMissionsList = [];
  public slotSelected = {};
  public missionsSelected = [];

  constructor(public activeModal: NgbActiveModal,
              private fakeApiService: FakeApiService) { }

  ngOnInit() {
    this.fakeApiService.getAvailableMissionData().subscribe(
      result => {
        this.availableMissionsList = result.data.availableMissionsList;
        this.slotSelected = this.availableMissionsList[0];
      }
    );
  }

  /**
   * Method called after a click on a mission's checkbox
   * @param mission Which mission to add or remove from missionsSelected array
   */
  addMissionToSelectedList(mission) {
    const missionIndex = this.missionsSelected.indexOf(mission);
    if (missionIndex === -1) {
      this.missionsSelected.push(mission);
    } else {
      this.missionsSelected.splice(missionIndex, 1);
    }
    // console.log(this.missionsSelected);
  }

  /**
   * Method called after a click on attribute mission
   */
  onSubmit() {
    // console.log(this.missionsSelected);
    this.activeModal.close({
      missionsSelected: this.missionsSelected
    });
  }

}
