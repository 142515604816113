import { Component, OnInit, ViewChild } from '@angular/core';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { Page } from '../../../../lib/lib-ngx/utils/Page';
import { DiscountCodeType } from '../../../../lib/lib-shared/types/DiscountCodeType';
import { PRICEMULTIPLIER } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-discount-code-list',
  templateUrl: './discount-code-list.component.html',
  styleUrls: ['./discount-code-list.component.scss']
})
export class DiscountCodeListComponent implements OnInit {
  public DiscountCodeType = DiscountCodeType;
  public PRICEMULTIPLIER = PRICEMULTIPLIER;

  @ViewChild('discountCodeTable') public discountCodeTable;
  public discountCodeList = [];

  page = new Page();

  constructor(
    private apiLavandierService: ApiLavandierService,
  ) {
    this.page.setPageNumber(0);
    this.page.size = 10;
  }

  ngOnInit() {
    this.loadDiscountCodeList();
  }

  loadDiscountCodeList() {
    this.page.checkPage();
    this.apiLavandierService.getDiscountCode(this.page.toQueryObject())
      .subscribe((discountCodeList: any) => {
        this.page.totalElements = discountCodeList.count;
        this.page.totalPages = this.page.totalElements / this.page.size;
        this.discountCodeList = discountCodeList.rows;
        setTimeout(() => this.discountCodeTable.rowDetail.expandAllRows());
      });
  }

  setPage(pageInfo) {
    this.page.setPageNumber(pageInfo.offset);
    this.loadDiscountCodeList();
  }
}
