import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-edit-scooter',
  templateUrl: './edit-scooter.component.html',
  styleUrls: ['./edit-scooter.component.scss']
})
export class EditScooterComponent implements OnInit {
  @Input('scooterData') scooterData;
  @Input('lmpList') lmpList;
  @Output('submitForm') submitForm = new EventEmitter<boolean>();
  public editScooterForm: FormGroup;

  constructor(private fb: FormBuilder,
              private apiLavandierService: ApiLavandierService,
              public datePipe: DatePipe) {
  }

  ngOnInit() {
    this.buildForm();
  }

  /**
   * Method to build for for adding a scooter
   */
  buildForm() {
    this.editScooterForm = this.fb.group({
      registrationDocument: [this.scooterData['registrationDocument'] !== null],
      geoCoyote: [this.scooterData['geoCoyote'] !== null],
      stickersNumber: [this.scooterData['stickersNumber'] !== null],
      backUpKey: [this.scooterData['backUpKey'] !== null],
      insuranceCard: [this.scooterData['insuranceCard'] !== null],
      key: [this.scooterData['key'] !== null],
      covering: [this.scooterData['covering'] !== null],
      shopId: [this.scooterData['shopId']]
    });
  }

  /**
   * Method called after submit the form
   * @param event Must be validate or cance
   */
  onSubmitForm(event) {
    // Call API to edit scooter if event is equals to validate
    if (event === 'validate') {
      const date = new Date().toISOString();
      const scooterValue = this.editScooterForm.value;
      this.apiLavandierService.putScooter(this.scooterData['id'], scooterValue.shopId,
        scooterValue.registrationDocument ? date : null,
        scooterValue.geoCoyote ? date : null, scooterValue.stickersNumber ? date : null,
        scooterValue.backUpKey ? date : null, scooterValue.insuranceCard ? date : null,
        scooterValue.key ? date : null, scooterValue.covering ? date : null)
        .subscribe(
          data => this.submitForm.emit(true),
          error => console.log('EditScooterFailure', error)
        );
    } else {
      this.submitForm.emit(false);
    }
  }

}
