import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import {
  IIncident,
  IIncidentImages,
  IIncidentNew,
  IncidentTypeVisibility
} from '../../../../lib/lib-ngx/web-services/api-lavandier.service.type';
import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { IncidentOrigin } from '../../../../lib/lib-shared/types/IncidentOrigin';

@Component({
  selector: 'lm-new-incident',
  templateUrl: './new-incident.component.html',
  styleUrls: ['./new-incident.component.scss']
})
export class NewIncidentComponent implements OnInit {
  public IncidentOrigin = IncidentOrigin;

  public addEventForm: FormGroup;
  public incidentTypeMap = new Map();
  public assignLavandierList = [];
  public eventList = [];
  public customerName = '';
  public uploader: FileUploader = new FileUploader({
    allowedFileType: ['image']
  });

  public incidentDetails = null;
  public orderId = null;
  public incidentId = null;

  constructor(
    private fb: FormBuilder,
    private apiLavandierService: ApiLavandierService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.search = this.search.bind(this);

    this.route.params.subscribe(param => {
      this.buildForm();
      if (param.id) {
        this.incidentId = param.id;
        this.loadIncidentDetails();
      } else if (param.orderId) {
        this.orderId = param.orderId;
        this.loadOrderDetails();
      }
      this.loadIncidentTypeMap();
      this.loadLavandierList();
    });
  }

  buildForm() {
    this.addEventForm = this.fb.group({
      orderId: ['', Validators.required],
      typeId: ['', Validators.required],
      origin: [IncidentOrigin.WEB, Validators.required],
      title: ['', Validators.required],
      assignLavandierId: null,
      comment: ['', Validators.required]
    });
  }

  /**
   * Method called to get incident detail by incident id
   */
  loadIncidentDetails() {
    this.apiLavandierService.getIncidentDetails(this.incidentId)
      .subscribe(
        (data: Object[]) => {
          this.incidentDetails = data;
          this.addEventForm.controls['comment'].clearValidators();
          this.addEventForm.controls['comment'].updateValueAndValidity();
          this.patchValue();
          this.onSelectOrder(this.incidentDetails.order);
        }
      );
  }

  loadOrderDetails() {
    this.apiLavandierService.getOrderIdDetails(this.orderId)
      .subscribe((orderDetail) => {
        if (orderDetail) {
          this.onSelectOrder(orderDetail);
          this.addEventForm.controls['orderId'].setValue(orderDetail);
        } else {
          this.router.navigate(['/incident/add']);
        }
      });
  }

  patchValue() {
    this.addEventForm.patchValue({
      orderId: this.incidentDetails.orderId,
      typeId: this.incidentDetails.typeId,
      origin: this.incidentDetails.origin,
      title: this.incidentDetails.title,
      assignLavandierId: this.incidentDetails.assignLavandierId
    });
  }

  /**
   * Method which will get all the incident type
   */
  loadIncidentTypeMap() {
    this.apiLavandierService.getIncidentTypes()
      .pipe(map(ListToMap.convert))
      .subscribe((incidentTypeMap: Map<number, Object>) => {
        this.incidentTypeMap = incidentTypeMap;
        this.incidentTypeMap.forEach(value => {
          if (value.visibility & IncidentTypeVisibility.OLD) {
            if (!this.incidentDetails || (this.incidentDetails && value.id !== this.incidentDetails.typeId)) {
              this.incidentTypeMap.delete(value.id);
            }
          }
        });
        this.updateIncidentTypeList();
      });
  }

  /**
   * If the list is not empty, we patch the first value's id to the form group
   */
  updateIncidentTypeList() {
    if (this.incidentTypeMap.size && this.addEventForm.value.typeId === '') {
      this.addEventForm.patchValue({
        typeId: this.incidentTypeMap.values().next().value.id
      });
    }
  }

  /**
   * Method called to get the lavandier's list
   */
  loadLavandierList() {
    this.apiLavandierService.getLavandiers()
      .subscribe((data: any) => this.assignLavandierList = data.rows);
  }

  /**
   * This function will transform the observable text into the array of results (list of orders)
   * @param text orderId input value observable
   */
  search(text: Observable<string>): Observable<any> {
    return text.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        term !== '' ? this.apiLavandierService.postOrderSearch({orderId: +term}) : of([])
      )
    );
  }

  /**
   * This function will take an order and return his id
   * @param order
   */
  formatter(order: any) {
    return order.id;
  }

  /**
   * Method called when the user select an order id
   * It will set the customer name (first name + last name)
   * Then it'll search incident
   * @param order The selected order
   */
  onSelectOrder(order) {
    this.customerName = order.user.firstName + ' ' + order.user.lastName;
    this.apiLavandierService.getIncidentList({orderId: order.id})
      .subscribe((data: any) => this.eventList = data.rows);
  }

  /**
   * Method called when user click on 'Enregistrer',
   * It'll wreate an IIncident from addEventForm.value and post it to postIncident
   * If success, it will return an incidentComment id and we can upload the images
   */
  onAddIncident() {
    const incident: IIncidentNew = {
      typeId: this.addEventForm.value.typeId,
      orderId: this.addEventForm.value.orderId.id,
      assignLavandierId: this.addEventForm.value.assignLavandierId,
      origin: this.addEventForm.value.origin,
      title: this.addEventForm.value.title,
      comment: this.addEventForm.value.comment
    };
    this.apiLavandierService.postIncident(incident)
      .subscribe((data: any) => {
        if (this.uploader.queue.length) {
          this.onAddIncidentImages(data);
          return;
        }
        this.router.navigate(['/incident/details', data.incident.id]);
      });
  }

  /**
   * Method called after a success on adding a new incident
   * This method will post images for the new Incident
   * @param incident
   */
  onAddIncidentImages(incident) {
    const incidentImage: IIncidentImages = {
      incidentCommentId: incident.incidentComment.id,
      imageList: []
    };
    this.uploader.queue.forEach((item) => {
      incidentImage.imageList.push(item._file);
    });
    this.apiLavandierService.postIncidentImages(incidentImage)
      .subscribe(() => this.router.navigate(['/incident/details', incident.incident.id]));
  }

  /**
   * Method called to edit an incident
   */
  onEditIncident() {
    const incident: IIncident = {
      typeId: this.addEventForm.value.typeId,
      assignLavandierId: this.addEventForm.value.assignLavandierId,
      origin: this.addEventForm.value.origin,
      title: this.addEventForm.value.title
    };

    this.apiLavandierService.putIncident(this.incidentDetails.id, incident)
      .subscribe(() => this.router.navigate(['/incident/details', this.incidentDetails.id]));
  }
}
