import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ArticleFamilyType } from '../../../../lib/lib-shared/types/ArticleFamilyType';

@Component({
  selector: 'lm-edit-bag-list-modal',
  templateUrl: './edit-bag-list-modal.component.html',
  styleUrls: ['./edit-bag-list-modal.component.scss']
})
export class EditBagListModalComponent implements OnInit {
  public ArticleFamilyType = ArticleFamilyType;

  public articleRangeMap = new Map();
  public articleOptionMap = new Map();
  public userTypeMap = new Map();

  public orderTransport = null;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
  }

  getAddress(orderTransport) {
    const sameTypeOrderAddress = orderTransport.order.orderAddresses.filter(orderAddress => orderAddress.type === orderTransport.type);
    return sameTypeOrderAddress[sameTypeOrderAddress.length - 1];
  }
}
