import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ListToMap } from '../../../lib/lib-ngx/utils/ListToMap';
import { OrderTransportType } from '../../../lib/lib-shared/types/OrderTransportType';
import {
  AddOrderTransportCustomModalComponent
} from '../modals/add-order-transport-custom-modal/add-order-transport-custom-modal.component';

@Component({
  selector: 'lm-mission-list',
  templateUrl: './mission-list.component.html',
  styleUrls: ['./mission-list.component.scss']
})
export class MissionListComponent implements OnInit {
  public OrderTransportType = OrderTransportType;

  public filterForm: FormGroup;
  public shopMap = new Map();
  public missionList = [];

  constructor(
    public sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private calendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private apiLavandierService: ApiLavandierService,
    private router: Router,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.buildForm();

    this.apiLavandierService.getShops()
      .pipe(map(ListToMap.convert))
      .subscribe(data => {
        this.shopMap = data;
        this.filterForm.updateValueAndValidity();
      });

    this.filterForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(data => {
          return this.apiLavandierService.getOrderTransportList({
            canceled: false.toString(),
            shopId: data.shop ? data.shop.id : '',
            inShop: String(data.inShop),
            date: this.ngbDateParserFormatter.format(data.date),
          });
        }),
      )
      .subscribe((orderTransportList: any) => {
        this.missionList = orderTransportList.filter(orderTransport => orderTransport.type !== OrderTransportType.RETURNTOHOME);
      });
  }

  buildForm() {
    this.filterForm = this.fb.group({
      date: [this.calendar.getToday()],
      shop: [null],
      inShop: [null],
    });
  }

  openAddOrderTransportMultiple() {
    const addOrderTransportMultipleModal = this.modalService.open(AddOrderTransportCustomModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    addOrderTransportMultipleModal.componentInstance.showMultipleType = true;
    addOrderTransportMultipleModal.componentInstance.shopList = Array.from(this.shopMap.values());
    addOrderTransportMultipleModal.result
      .then((data) => {
        this.apiLavandierService.postOrderTransport({
          shopId: data.shopId,
          orderId: null,
          lavandierId: null,
          type: data.type,
          date: data.date,
          start: data.start,
          end: data.end,
          weight: 0,
        })
          .subscribe(() => this.ngOnInit());
      })
      .catch(() => null);
  }

  getAddress(orderTransport) {
    if (orderTransport.order) {
      const sameTypeOrderAddress = orderTransport.order.orderAddresses.filter(orderAddress => orderAddress.type === orderTransport.type);
      return sameTypeOrderAddress[sameTypeOrderAddress.length - 1];
    } else {
      return this.shopMap.get(orderTransport.shopId).address;
    }
  }

  redirectToOrderDetails(event) {
    if (event.type === 'click') {
      this.router.navigate(['order-details', event.row.orderId]);
    }
  }
}
