import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WeekDay } from '@angular/common';
import * as moment from 'moment';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-manage-slot-modal',
  templateUrl: './manage-slot-modal.component.html',
  styleUrls: ['./manage-slot-modal.component.scss']
})
export class ManageSlotModalComponent implements OnInit {
  public shopList = [];
  public timeSlotTypeList = [];
  public slotSelected = null;
  public day = null;
  public weekDays = [
    {value: WeekDay.Monday, name: 'monday'},
    {value: WeekDay.Tuesday, name: 'tuesday'},
    {value: WeekDay.Wednesday, name: 'wednesday'},
    {value: WeekDay.Thursday, name: 'thursday'},
    {value: WeekDay.Friday, name: 'friday'},
    {value: WeekDay.Saturday, name: 'saturday'},
    {value: WeekDay.Sunday, name: 'sunday'},
  ];

  public slotForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  /**
   * Method which build the form group
   */
  buildForm() {
    this.slotForm = this.fb.group({
      monday: [false],
      tuesday: [false],
      wednesday: [false],
      thursday: [false],
      friday: [false],
      saturday: [false],
      sunday: [false],
      type: [this.timeSlotTypeList[0].id, Validators.required],
      startHour: ['', Validators.required],
      endHour: [{value: '', disabled: true}, Validators.required],
      timeLimit: ['0', Validators.required],
      nbrSlotNew: ['', Validators.required],
      nbrSlotOld: ['', Validators.required]
    });
    if (this.slotSelected !== null) {
      this.patchValue();
    } else {
      this.addLmpFormControl();
    }
  }

  /**
   * Method called if user want to edit a slot
   * It'll patch all value from selected slot data
   */
  patchValue() {
    this.slotForm.controls[this.weekDays[this.day].name].setValue(true);
    this.slotForm.patchValue({
      type: this.slotSelected.typeId,
      startHour: moment(this.slotSelected.start, 'HH:mm:ss').format('HH:mm'),
      endHour: this.slotSelected.end,
      timeLimit: this.slotSelected.timeLimit,
      nbrSlotNew: this.slotSelected.newSlot,
      nbrSlotOld: this.slotSelected.oldSlot,
    });
  }

  /**
   * Method called if user want to add a slot and not for edit modal
   * It will add for all LMP in shopList a FormControl with his name
   */
  addLmpFormControl() {
    this.slotForm.addControl(
      'shops', this.fb.group({})
    );
    const formGroup: FormGroup = <FormGroup>this.slotForm.controls.shops;
    for (const shop of this.shopList) {
      formGroup.addControl(shop.id, new FormControl(false));
    }
  }

  /**
   * Method called after click on cancel or validate button
   */
  onSubmitForm(onAdding: boolean) {
    const days = [];
    const shopIds = [];
    if (onAdding) {
      for (const weekDay of this.weekDays) {
        if (this.slotForm.controls[weekDay.name].value) {
          days.push(weekDay.value);
        }
      }

      for (const shop of this.shopList) {
        if ((<FormGroup>this.slotForm.controls.shops).contains(shop.id)
          && (<FormGroup>this.slotForm.controls.shops).controls[shop.id].value) {
          shopIds.push(shop.id);
        }
      }
    }

    const start = this.slotForm.value.startHour;
    const timeLimit = this.slotForm.value.timeLimit;
    const newSlot = this.slotForm.value.nbrSlotNew;
    const oldSlot = this.slotForm.value.nbrSlotOld;
    const typeId = this.slotForm.value.type;

    if (onAdding) {
      this.apiLavandierService.postTimeSlot(shopIds, typeId, days, start, timeLimit, newSlot, oldSlot)
        .subscribe(
          () => this.activeModal.close()
        );
    } else {
      this.apiLavandierService.putTimeSlot(this.slotSelected.id, {
        shopId: this.slotSelected.shopId,
        typeId: typeId,
        day: this.slotSelected.day,
        start: start,
        timeLimit: timeLimit,
        newSlot: newSlot,
        oldSlot: oldSlot
      })
        .subscribe(
          () => this.activeModal.close()
        );
    }
  }
}
