import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { IRange } from '../../../../../lib/lib-ngx/web-services/api-lavandier.service.type';

@Component({
  selector: 'lm-article-range',
  templateUrl: './article-range.component.html',
  styleUrls: ['./article-range.component.scss', '../article-setting.component.scss']
})
export class ArticleRangeComponent implements OnInit {
  public articleRangeList = [];
  public articleRangeForm: FormGroup;
  public onAddArticleRange = false;
  public articleRangeToEdit = null;

  constructor(
    private modalService: NgbModal,
    private apiLavandierService: ApiLavandierService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.loadArticleRangeList();
  }

  /**
   * Method called to get the article range list
   */
  loadArticleRangeList() {
    this.resetArticleRangeForm();
    this.apiLavandierService.getArticleRangeList()
      .subscribe(
        (data: Object[]) => this.articleRangeList = data
      );
  }

  /**
   * Method called to reset articleRangeForm, onAddArticleRange and articleRangeToEdit
   */
  resetArticleRangeForm() {
    this.onAddArticleRange = false;
    this.articleRangeToEdit = null;
    this.buildForm();
  }

  buildForm() {
    this.articleRangeForm = this.fb.group({
      name: ['', Validators.required],
      quality: [null, Validators.required],
      shortDescription: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  /**
   * Method called to open edit template and patch value
   * @param articleRange
   */
  openEditArticleRange(articleRange) {
    this.articleRangeToEdit = articleRange;
    this.articleRangeForm.patchValue({
      name: this.articleRangeToEdit.name,
      quality: this.articleRangeToEdit.quality,
      shortDescription: this.articleRangeToEdit.shortDescription,
      description: this.articleRangeToEdit.description,
    });
  }

  /**
   * Method called to create an articleRange, it'll post or put depending on onAdding variable
   * @param onAdding true to post and false to put
   */
  onValidateArticleRange(onAdding: boolean) {
    const articleRange: IRange = {
      name: this.articleRangeForm.value.name,
      quality: this.articleRangeForm.value.quality,
      shortDescription: this.articleRangeForm.value.shortDescription,
      description: this.articleRangeForm.value.description
    };
    onAdding ? this.onNewArticleRange(articleRange) : this.onEditArticleRange(articleRange);
  }

  /**
   * Method called to add a new article range
   * @param articleRange
   */
  onNewArticleRange(articleRange) {
    this.apiLavandierService.postArticleRange(articleRange)
      .subscribe(() => this.ngOnInit());
  }

  /**
   * Method called to edit an article range
   * @param articleRange
   */
  onEditArticleRange(articleRange) {
    this.apiLavandierService.putArticleRange(this.articleRangeToEdit.id, articleRange)
      .subscribe(() => this.ngOnInit());
  }
}
