import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ManageAddressModalComponent } from '../../modals/manage-address-modal/manage-address-modal.component';

@Component({
  selector: 'lm-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss']
})
export class EditAccountComponent implements OnInit {

  private userTypeId = null;
  private accountId = null;
  public account = null;
  public address = null;

  public showForm = false;

  public form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private apiLavandierService: ApiLavandierService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.userTypeId = param.userTypeId !== undefined ? param.userTypeId : null;
      this.accountId = param.id !== undefined ? param.id : null;
      this.loadData();
    });
  }

  loadData() {
    return forkJoin([
      this.accountId !== null ? this.apiLavandierService.getAccountDetails(this.accountId) : of(null),
    ])
      .pipe(
        map(([account]: [any]) => {
          return [
            account,
          ];
        })
      )
      .subscribe(([account]) => {
        this.account = account;
        this.address = account && account.address ? account.address : null;
        this.buildForm();
      });
  }

  buildForm() {
    this.form = this.fb.group({
      name: [this.account ? this.account.name : '', Validators.required],
    });

    this.showForm = !this.accountId;
  }

  editAddress() {
    const manageAddressModal = this.modalService.open(ManageAddressModalComponent, {size: 'lg'});
    const userData = {
      firstName: this.address ? this.address.firstName : '',
      lastName: this.address ? this.address.lastName : '',
      phoneNumber: this.address ? this.address.phoneNumber : '',
      address: this.address ? this.address : '',
      moreInfo: this.address ? this.address.additional : ''
    };
    manageAddressModal.componentInstance.userAddress = userData;
    manageAddressModal.result
      .then(result => {
        if (result !== undefined) {
          this.address = result;
        }
      })
      .catch(() => null);
  }

  cancel() {
    if (this.account) {
      this.showForm = false;
    } else {
      this.router.navigate(['user-type-setting/edit', this.userTypeId]);
    }
  }

  save() {
    if (this.account) {
      this.apiLavandierService.putAccount(this.account.id, {name: this.form.value.name, address: this.address})
        .subscribe(() => this.ngOnInit());
    } else {
      this.apiLavandierService.postAccount({userTypeId: this.userTypeId, name: this.form.value.name, address: this.address})
        .subscribe((account: any) => this.router.navigate(['account/edit', account.id]));
    }
  }
}
