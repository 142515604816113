import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { FakeApiService } from '../../services/fake-api.service';

@Component({
  selector: 'lm-b2b',
  templateUrl: './b2b.component.html',
  styleUrls: ['./b2b.component.scss']
})
export class B2bComponent implements OnInit {
  public filterForm: FormGroup;
  public b2bList = [];
  public b2bTempList = [];
  public activityList = [];
  @ViewChild(DatatableComponent)
  table: DatatableComponent;

  constructor(private fb: FormBuilder,
              private fakeApiService: FakeApiService) {
  }

  ngOnInit() {
    this.buildForm();
    this.fakeApiService.getContactB2B()
      .subscribe(
        data => this.handleGetContactB2BSuccess(data),
        error => this.handleGetContactB2BFailure(error)
      );
  }

  buildForm() {
    this.filterForm = this.fb.group({
      companyName: [''],
      personInCharge: [''],
      activity: [''],
      clothsNumberGreaterThan: [null],
      clothsNumberLessThan: [null]
    });
  }

  handleGetContactB2BSuccess(data) {
    this.b2bList = data;
    this.b2bTempList = [...data];
  }

  handleGetContactB2BFailure(error) {
    console.log(error);
  }

  filterB2B() {
    const filtersValue = this.filterForm.value;
    console.log(filtersValue);

    const b2bTemp = this.b2bTempList.filter(function (d) {
      return (filtersValue.companyName === '' || d.companyName.toLowerCase().includes(filtersValue.companyName.toLowerCase()))
        && (filtersValue.personInCharge === '' || d.accountantName.toLowerCase().includes(filtersValue.personInCharge.toLowerCase()))
        && (filtersValue.activity === '' || filtersValue.activity === d.activityArea)
        && (filtersValue.clothsNumberGreaterThan === null || filtersValue.clothsNumberGreaterThan > d.clothesNumber)
        && (filtersValue.clothsNumberLessThan === null || filtersValue.clothsNumberLessThan < d.clothesNumber);
    });

    this.b2bList = b2bTemp;
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

}
