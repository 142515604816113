import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { jwtDecode } from 'jwt-decode';

import { environment } from '../../../environments/environment';
import { AddressField, getAddressField } from '../utils/AddressField';
import {
  IAuthPostAPIkeyReq,
  IAuthPostLoginReq,
  IAuthPostNewReq,
  TAuthPostAPIKeyRep,
  TAuthPostLoginRep,
  TAuthPostNewRep
} from '../../lib-shared/api/Auth.Type';
import { IRegister } from './auth.service.type';
import { ITokenContent } from '../../lib-shared/api/All.Type';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url = AuthService.generateUrl(environment.api_url);

  private TOKEN_KEY = 'lm-token';
  private INFORMATION_KEY = 'lm-information';

  constructor(
    private http: HttpClient,
  ) {
  }

  static generateUrl(base: string) {
    return `${base}/auth`;
  }

  setUrl(base: string) {
    this.url = AuthService.generateUrl(base);
  }

  register(params: IRegister) {
    const address = {
      firstName: params.firstName,
      lastName: params.lastName,
      streetNumber: getAddressField(params.address, AddressField.STREETNUMBER),
      route: getAddressField(params.address, AddressField.ROUTE),
      locality: getAddressField(params.address, AddressField.LOCALITY),
      country: getAddressField(params.address, AddressField.COUNTRY),
      postalCode: getAddressField(params.address, AddressField.POSTALCODE),
      formattedAddress: params.address.formatted_address as string,
      lat: params.address.geometry!.location!.lat(),
      lng: params.address.geometry!.location!.lng(),
      phoneNumber: params.phoneNumber,
      additional: '',
    };
    const data: IAuthPostNewReq = {...params, ...{address: address}};
    return this.http.post<TAuthPostNewRep>(`${this.url}/new`, data);
  }

  login(params: IAuthPostLoginReq) {
    return this.http.post<TAuthPostLoginRep>(`${this.url}/login`, params);
  }

  loginAPIKey(params: IAuthPostAPIkeyReq) {
    return this.http.post<TAuthPostAPIKeyRep>(`${this.url}/apikey`, params);
  }

  setToken(token: string) {
    localStorage.setItem(this.TOKEN_KEY, JSON.stringify(token));
  }

  getToken(): string {
    return JSON.parse(localStorage.getItem(this.TOKEN_KEY)!);
  }

  getDecodeToken(): ITokenContent {
    return jwtDecode(JSON.parse(localStorage.getItem(this.TOKEN_KEY)!));
  }

  getHttpOptions() {
    return {
      headers: this.getHttpHeaders(),
    };
  }

  getHttpHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Authorization': 'Bearer ' + this.getToken()
    });
  }

  logout() {
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.INFORMATION_KEY);
  }

  isLoggedIn() {
    return !!localStorage.getItem(this.TOKEN_KEY);
  }
}
