import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lm-edit-shop-hours-modal',
  templateUrl: './edit-shop-hours-modal.component.html',
  styleUrls: ['./edit-shop-hours-modal.component.scss']
})
export class EditShopHoursModalComponent implements OnInit {

  public shop = null;

  public formList: FormArray;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.formList = this.fb.array([]);

    for (const hour of this.shop.hours) {
      this.formList.push(this.fb.group({name: [hour.name, Validators.required], value: [hour.value, Validators.required]}));
    }
  }

  onAddLine(index: number) {
    this.formList.insert(index + 1, this.fb.group({name: [null, Validators.required], value: [null, Validators.required]}));
  }

  onDeleteLine(index) {
    this.formList.removeAt(index);
  }

  onValidate() {
    const hours = [];
    for (const form of this.formList.controls) {
      hours.push({
        name: form.value.name,
        value: form.value.value,
      });
    }
    this.activeModal.close(hours);
  }
}
