import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

import { TransportScheduleType } from '../../../../lib/lib-shared/types/TransportScheduleType';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-edit-transport-schedule-modal',
  templateUrl: './edit-transport-schedule-modal.component.html',
  styleUrls: ['./edit-transport-schedule-modal.component.scss']
})
export class EditTransportScheduleModalComponent implements OnInit {
  public TransportScheduleType = TransportScheduleType;

  public user = null;
  public transportSchedule = null;

  public formGroup: FormGroup;

  public shopMap = new Map();
  public addressMap = new Map();

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
    if (this.transportSchedule) {
      this.patchValue();
    }
  }

  buildForm() {
    this.formGroup = this.fb.group({
      type: [null, Validators.required],
      shop: [null, Validators.required],
      nbDayToAdd: [null, Validators.required],
      checkDay: [null, Validators.required],
      checkTime: [null, Validators.required],
      day: [null, Validators.required],
      start: [null, Validators.required],
      duration: [null, Validators.required],
      address: [null, Validators.required],
    });
  }

  patchValue() {
    this.formGroup.patchValue({
      type: this.transportSchedule.type,
      shop: this.shopMap.get(this.transportSchedule.shopId),
      nbDayToAdd: this.transportSchedule.nbDayToAdd,
      checkDay: this.transportSchedule.checkDay,
      checkTime: moment.utc(this.transportSchedule.checkTime, 'HH:mm:ss').local().format('HH:mm'),
      day: this.transportSchedule.day,
      start: moment(this.transportSchedule.start, 'HH:mm:ss').format('HH:mm'),
      duration: moment(this.transportSchedule.end, 'HH:mm:ss').diff(moment(this.transportSchedule.start, 'HH:mm:ss').add(-1, 'seconds'), 'minutes'),
      address: this.addressMap.get(this.transportSchedule.addressId),
    });
  }

  onValidate() {
    if (this.transportSchedule) {
      this.apiLavandierService.putTransportSchedule(this.transportSchedule.id, {
        shopId: this.formGroup.value.shop.id,
        addressId: this.formGroup.value.address.id,
        type: this.formGroup.value.type,
        checkDay: this.formGroup.value.checkDay,
        checkTime: moment(this.formGroup.value.checkTime, 'HH:mm').utc().format('HH:mm'),
        day: this.formGroup.value.day,
        start: this.formGroup.value.start,
        nbDayToAdd: this.formGroup.value.nbDayToAdd,
        duration: this.formGroup.value.duration,
      })
        .subscribe(() => this.activeModal.close(true));
    } else {
      this.apiLavandierService.postTransportSchedule({
        shopId: this.formGroup.value.shop.id,
        userId: this.user.id,
        addressId: this.formGroup.value.address.id,
        type: this.formGroup.value.type,
        checkDay: this.formGroup.value.checkDay,
        checkTime: moment(this.formGroup.value.checkTime, 'HH:mm').utc().format('HH:mm'),
        day: this.formGroup.value.day,
        start: this.formGroup.value.start,
        nbDayToAdd: this.formGroup.value.nbDayToAdd,
        duration: this.formGroup.value.duration,
      })
        .subscribe(() => this.activeModal.close(true));
    }
  }
}
