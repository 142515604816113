export class RangeUtils {
  static isRangeAvailableForUserType(userTypeId: number, rangeId: number, rangeMap: Map<number, any>) {
    return !!(rangeMap.has(rangeId) && rangeMap.get(rangeId).userTypeDiscountList.some(RangeUtils.hasUserType(userTypeId)));
  }

  static getRangeMapAvailableForUserType(userTypeId: number, rangeMap: Map<number, any>) {
    const resultMap = new Map<number, any>();
    rangeMap.forEach(range => {
      if (range.userTypeDiscountList.some(RangeUtils.hasUserType(userTypeId))) {
        resultMap.set(range.id, range);
      }
    });
    return resultMap;
  }

  private static hasUserType(userTypeId: number) {
    return (userTypeDiscount) => {
      return userTypeDiscount.userTypeId === userTypeId;
    };
  }
}
