import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiLavandierService } from '../../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { IArticleOption } from '../../../../../lib/lib-ngx/web-services/api-lavandier.service.type';
import { StorageType } from '../../../../../lib/lib-shared/types/StorageType';

@Component({
  selector: 'lm-article-option',
  templateUrl: './article-option.component.html',
  styleUrls: ['./article-option.component.scss', '../article-setting.component.scss']
})
export class ArticleOptionComponent implements OnInit {
  public StorageType = StorageType;

  @Input('articleOptionTypeMap') articleOptionTypeMap;

  public articleOptionList = [];
  public articleOptionForm: FormGroup;
  public articleOptionToEdit = null;
  public onAddArticleOption = false;

  constructor(
    private apiLavandierService: ApiLavandierService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.loadArticleOptionList();
  }

  /**
   * Method called to get articleOptionList
   */
  loadArticleOptionList() {
    this.resetArticleOptionForm();
    this.apiLavandierService.getArticleOptionList()
      .subscribe((data: Object []) => this.articleOptionList = data);
  }

  /**
   * Method called to reset articleOptionForm
   */
  resetArticleOptionForm() {
    this.buildForm();
    this.articleOptionToEdit = null;
    this.onAddArticleOption = false;
  }

  buildForm() {
    this.articleOptionForm = this.fb.group({
      articleOptionTypeId: ['', Validators.required],
      flux: [null],
      name: ['', Validators.required],
      public: [false],
      description: [''],
    });
  }

  /**
   * Method called to open the edit template; also patch value to articleOptionForm
   * @param articleOption articleOption to edit
   */
  openEditArticleOption(articleOption) {
    this.articleOptionToEdit = articleOption;
    this.articleOptionForm.patchValue({
      articleOptionTypeId: this.articleOptionToEdit.articleOptionTypeId,
      flux: this.articleOptionToEdit.flux,
      name: this.articleOptionToEdit.name,
      public: this.articleOptionToEdit.public,
      description: this.articleOptionToEdit.description,
    });
  }

  /**
   * Method called to create an articleOption; then it'll post or put depending on onAdding variable
   * @param onAdding true to post and false to put
   */
  onValidateArticleOption(onAdding: boolean) {
    const articleOption: IArticleOption = {
      articleOptionTypeId: this.articleOptionForm.value.articleOptionTypeId,
      flux: this.articleOptionForm.value.flux,
      name: this.articleOptionForm.value.name,
      description: this.articleOptionForm.value.description,
      public: this.articleOptionForm.value.public,
    };
    onAdding ? this.onNewArticleOption(articleOption) : this.onEditArticleOption(articleOption);
  }

  /**
   * Method called to create a new article option
   * @param articleOption
   */
  onNewArticleOption(articleOption) {
    this.apiLavandierService.postArticleOption(articleOption)
      .subscribe(() => this.ngOnInit());
  }

  /**
   * Method called to update an article option
   * @param articleOption
   */
  onEditArticleOption(articleOption) {
    this.apiLavandierService.putArticleOption(this.articleOptionToEdit.id, articleOption)
      .subscribe(() => this.ngOnInit());
  }
}
