import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../../lib/lib-ngx/web-services/auth.service';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'lm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  showAlert: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private homeService: HomeService,
  ) {
  }

  ngOnInit() {
    setTimeout(() => this.homeService.setSideMenuStatus(false));
    this.showAlert = false;
  }

  login(formData) {
    this.authService.login({email: formData.email, password: formData.password})
      .subscribe(
        (data) => {
          if (data.hasOwnProperty('token')) {
            this.authService.setToken(data.token);
            this.homeService.setSideMenuStatus(true);
            this.router.navigate(['store']);
          } else {
            this.showAlert = true;
          }
        },
        () => this.showAlert = true
      );
  }
}
