import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IArticle, IUpload } from '../../../../lib/lib-ngx/web-services/api-lavandier.service.type';
import { StorageType } from '../../../../lib/lib-shared/types/StorageType';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { UploadFolder } from '../../../../lib/lib-shared/types/UploadFolder';
import { ArticleUnity } from '../../../../lib/lib-shared/types/ArticleUnity';

@Component({
  selector: 'lm-new-article',
  templateUrl: './new-article.component.html',
  styleUrls: ['./new-article.component.scss']
})
export class NewArticleComponent implements OnInit {
  public ArticleUnity = ArticleUnity;
  public StorageType = StorageType;

  @ViewChild('ng2FileSelectInput') public ng2FileSelectInput: any;
  public articleFamilyMap = new Map();
  public articleOptionTypeList = [];
  public articleOptionList = [];
  public articleId = null;
  public articleToEdit = null;
  public articleForm: FormGroup;
  public uploader: FileUploader = new FileUploader({
    allowedFileType: ['image']
  });

  constructor(private fb: FormBuilder,
              private apiLavandierService: ApiLavandierService,
              private sanitizer: DomSanitizer,
              public router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.articleId = param.id !== undefined ? param.id : null;
      this.resetData();
      this.loadData().subscribe();
    });
  }

  resetData() {
    this.articleFamilyMap = new Map();
    this.articleToEdit = null;
    this.articleOptionTypeList = [];
    this.articleOptionList = [];
  }

  loadData(): Observable<any> {
    return forkJoin([
      this.apiLavandierService.getArticleFamilyList(),
      this.apiLavandierService.getArticleOptionTypeList(),
      this.apiLavandierService.getArticleOptionList(),
      this.articleId !== null ? this.apiLavandierService.getArticle(this.articleId) : of(null),
    ]).pipe(
      map(([articleFamilyList, articleOptionTypeList, articleOptionList, articleToEdit]) => {
        this.articleFamilyMap = ListToMap.convert(articleFamilyList as any[]);
        this.articleOptionTypeList = articleOptionTypeList as any[];
        this.articleOptionList = articleOptionList as any[];
        this.buildForm();

        if (articleToEdit !== null) {
          this.articleToEdit = articleToEdit;
          this.patchValue();
        }
      })
    );
  }

  buildForm() {
    this.articleForm = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      unity: ['', Validators.required],
      articleFamily: ['', Validators.required],
      nbPiece: [1, Validators.required],
      flux: ['', Validators.required],
      mass: [null, Validators.required],
      articleOption: this.fb.group({}),
    });

    const articleOptionFromGroup = this.articleForm.get('articleOption') as FormGroup;
    for (const articleOption of this.articleOptionList) {
      articleOptionFromGroup.addControl(articleOption.id, new FormControl(false));
    }
  }

  patchValue() {
    this.articleForm.patchValue({
      name: this.articleToEdit.name,
      code: this.articleToEdit.code,
      unity: this.articleToEdit.unity,
      nbPiece: this.articleToEdit.nbPiece,
      flux: this.articleToEdit.flux,
      mass: this.articleToEdit.mass,
      articleFamily: this.articleFamilyMap.get(this.articleToEdit.articleFamilyId),
    });

    this.articleToEdit.articleOptions.forEach((articleOption) => {
      this.articleForm.get('articleOption').patchValue({
        [articleOption.id]: true
      });
    });
  }

  clearNg2FileUploaderQueueAndInput() {
    this.uploader.clearQueue();
    // Remove the input value to enable re-select same file, issue related to input tag, not ng2-file-upload
    // https://github.com/valor-software/ng2-file-upload/issues/871#issuecomment-328294164
    this.ng2FileSelectInput.nativeElement.value = '';
  }

  /**
   * Method called to add a new icon on articles folder
   */
  onNewIcon() {
    if (this.uploader.queue.length) {
      const upload: IUpload = {
        folder: UploadFolder.ARTICLES,
        file: this.uploader.queue[0]._file
      };

      this.apiLavandierService.postUpload(upload)
        .subscribe(
          (data: Object) => this.onValidateArticle(data, this.articleToEdit === null)
        );
    } else {
      this.onValidateArticle(null, this.articleToEdit === null);
    }
  }

  /**
   * Method called to create an article, then it'll post or put depending on onAdding variable
   * @param icon icon to post or put if changed
   * @param onAdding true to post and false to put
   */
  onValidateArticle(icon, onAdding: boolean) {
    const article: IArticle = {
      articleOptionIdList: [],
      articleFamilyId: this.articleForm.value.articleFamily.id,
      iconId: icon === null ? this.articleToEdit.iconId : icon.id,
      unity: this.articleForm.value.unity,
      flux: this.articleForm.value.flux,
      name: this.articleForm.value.name,
      code: this.articleForm.value.code,
      mass: this.articleForm.value.mass,
      nbPiece: this.articleForm.value.nbPiece,
    };
    this.articleOptionList.forEach((articleOption) => {
      if (this.articleForm.controls['articleOption'].value[articleOption.id]) {
        article.articleOptionIdList.push(articleOption.id);
      }
    });
    onAdding ? this.onNewArticle(article) : this.onEditArticle(article);
  }

  /**
   * Method called to add a new article
   * @param article
   */
  onNewArticle(article) {
    this.apiLavandierService.postArticle(article)
      .subscribe(() => this.router.navigate(['/article/list']));
  }

  /**
   * Method called to edit an article
   * @param article
   */
  onEditArticle(article) {
    this.apiLavandierService.putArticle(this.articleToEdit.id, article)
      .subscribe(() => this.router.navigate(['/article/list']));
  }
}
