import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { IUserEdit } from '../../../../../lib/lib-ngx/web-services/api-lavandier.service.type';
import { ManageAddressModalComponent } from '../../../modals/manage-address-modal/manage-address-modal.component';
import { ConfirmationModalComponent } from '../../../modals/confirmation-modal/confirmation-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'lm-user-profile-contact-information',
  templateUrl: './user-profile-contact-information.component.html',
  styleUrls: ['./user-profile-contact-information.component.scss',
    '../user-profile.component.scss']
})
export class UserProfileContactInformationComponent implements OnInit {
  @Input('userProfileData') userProfileData;
  @Output('editValidation') editValidation = new EventEmitter<void>();
  public editUserProfile = false;
  public editForm: FormGroup;
  public userTypeList = [];

  constructor(
    private fb: FormBuilder,
    private apiLavandierService: ApiLavandierService,
    private modalService: NgbModal,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.getUserTypeList();
    this.editUserProfile = false;
    this.editForm = this.fb.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      userType: ['', Validators.required]
    });
  }

  onEditContactInformation() {
    this.editUserProfile = true;
    this.editForm.patchValue({
      lastName: this.userProfileData.lastName,
      firstName: this.userProfileData.firstName,
      phoneNumber: this.userProfileData.phoneNumber,
      email: this.userProfileData.email,
      userType: this.userProfileData.userType.id
    });
  }

  validateEdit() {
    if (!this.editForm.valid) {
      return;
    }
    const number = PhoneNumberUtil.getInstance().parseAndKeepRawInput(this.editForm.value.phoneNumber);
    const userEdit: IUserEdit = {
      userTypeId: this.editForm.value.userType,
      important: this.editForm.value.important,
      dataConsent: this.editForm.value.dataConsent,
      fakePayment: this.editForm.value.fakePayment,
      email: this.editForm.value.email,
      firstName: this.editForm.value.firstName,
      lastName: this.editForm.value.lastName,
      phoneNumber: PhoneNumberUtil.getInstance().format(number, PhoneNumberFormat.INTERNATIONAL),
    };

    this.apiLavandierService.putUser(this.userProfileData.id, userEdit)
      .subscribe(() => {
        this.ngOnInit();
        this.editValidation.emit();
      });
  }

  getUserTypeList() {
    this.apiLavandierService.getUserTypeList()
      .subscribe((userTypeList: any[]) => this.userTypeList = userTypeList);
  }

  deleteAddress(address) {
    const confirmationModal = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
    confirmationModal.componentInstance.confirmationDesc = 'Êtes vous sur de vouloir supprimer cette adresse ?';
    confirmationModal.componentInstance.validateButtonText = 'Valider';
    confirmationModal.componentInstance.cancelButtonText = 'Annuler';
    confirmationModal.result.then(result => {
      if (result) {
        this.apiLavandierService.deleteAddress(address.id).subscribe(() => {
          this.ngOnInit();
          this.editValidation.emit();
        });
      }
    }).catch(() => null);
  }

  defaultAddress(address) {
    this.apiLavandierService.putIdDefaultAddress(address.id, {userId: this.userProfileData.id})
      .subscribe(() => {
        this.ngOnInit();
        this.editValidation.emit();
      });
  }

  addAddress(address, onEdit) {
    const addAddressModal = this.modalService.open(ManageAddressModalComponent, {size: 'lg'});
    const userData = {
      firstName: onEdit ? address.firstName : this.userProfileData.firstName,
      lastName: onEdit ? address.lastName : this.userProfileData.lastName,
      phoneNumber: onEdit ? address.phoneNumber : this.userProfileData.phoneNumber,
      address: onEdit ? address : '', // If it's for editing an address
      moreInfo: onEdit ? address.additional : ''
    };
    addAddressModal.componentInstance.userAddress = userData;
    addAddressModal.result.then(result => {
      if (result !== undefined) {
        if (onEdit) {
          this.apiLavandierService.putAddress(address.id, {
            firstName: result.firstName,
            lastName: result.lastName,
            streetNumber: result.streetNumber,
            route: result.route,
            locality: result.locality,
            country: result.country,
            postalCode: result.postalCode,
            formattedAddress: result.formattedAddress,
            lat: result.lat,
            lng: result.lng,
            phoneNumber: result.phoneNumber,
            additional: result.additional
          }).subscribe(() => {
            this.ngOnInit();
            this.editValidation.emit();
          });

        } else {
          this.apiLavandierService.postAddress({
            userId: this.userProfileData.id,
            firstName: result.firstName,
            lastName: result.lastName,
            streetNumber: result.streetNumber,
            route: result.route,
            locality: result.locality,
            country: result.country,
            postalCode: result.postalCode,
            formattedAddress: result.formattedAddress,
            lat: result.lat,
            lng: result.lng,
            phoneNumber: result.phoneNumber,
            additional: result.additional
          }).subscribe(() => {
            this.ngOnInit();
            this.editValidation.emit();
          });

        }
      }
    })
      .catch(() => null);
  }
}
