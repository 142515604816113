import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';

import { HomeService } from '../../services/home.service';

@Component({
  selector: 'lm-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  public sideBarData = [];
  public currentPath = '';

  @ViewChild('sideMenuAccordion')
  public sideMenuAccordion: NgbAccordion;

  constructor(
    private homeService: HomeService,
    public router: Router,
  ) {
  }

  ngOnInit() {
    this.router.events
      .subscribe((params) => {
        if (params instanceof ActivationEnd) {
          this.currentPath = params.snapshot.routeConfig.path;
        }
      });
    this.homeService.getSideBarData().subscribe(
      (response) => {
        this.sideBarData = response.data;
        // On init expand the item by the activated route
        response.data.forEach((menuItem) => {
          if (menuItem.route[0] === this.currentPath || menuItem.childrenPath.indexOf(this.currentPath) !== -1) {
            this.sideMenuAccordion.toggle(menuItem.name);
            this.sideMenuAccordion.activeIds = menuItem.name;
            return;
          }
        });
      }
    );
  }
}
