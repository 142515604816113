import { Component, ElementRef, Input, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader } from '@agm/core';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

import { AddressField, getAddressField } from '../../../../lib/lib-ngx/utils/AddressField';
import { IAddressContent } from '../../../../lib/lib-shared/api/All.Type';

@Component({
  selector: 'lm-manage-address-modal',
  templateUrl: './manage-address-modal.component.html',
  styleUrls: ['./manage-address-modal.component.scss']
})
export class ManageAddressModalComponent implements OnInit {
  @Input('userAddress') userAddress;
  public addressForm: FormGroup;

  @ViewChild('addressElementRef')
  public addressElementRef: ElementRef;
  public address: google.maps.places.PlaceResult = null;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.addressForm = this.fb.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      address: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      moreInfo: ['']
    });
    this.initGMaps();
    if (this.userAddress !== null) {
      setTimeout(() => {
        this.patchValue();
      });
    }
  }

  patchValue() {
    this.addressForm.patchValue({
      lastName: this.userAddress['lastName'],
      firstName: this.userAddress['firstName'],
      address: this.userAddress['address'].formattedAddress,
      phoneNumber: this.userAddress['phoneNumber'],
      moreInfo: this.userAddress['moreInfo'],
    });
  }

  initGMaps() {
    this.mapsAPILoader.load().then(() => {
      if (this.userAddress !== null) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({'address': this.userAddress['address'].formattedAddress}, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            this.ngZone.run(() => {
              // @ts-ignore
              this.address = results[0];
            });
          }
        });
      }

      const autocomplete = new google.maps.places.Autocomplete(this.addressElementRef.nativeElement, {
        types: ['address'],
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          this.address = autocomplete.getPlace();
        });
      });
      this.renderer.listen(this.addressElementRef.nativeElement, 'change', () => {
        this.ngZone.run(() => {
          this.address = null;
        });
      });
    });
  }

  onSubmit() {
    const number = PhoneNumberUtil.getInstance().parseAndKeepRawInput(this.addressForm.controls['phoneNumber'].value);
    const address: IAddressContent = {
      firstName: this.addressForm.controls['firstName'].value,
      lastName: this.addressForm.controls['lastName'].value,
      streetNumber: this.address === null ? this.userAddress['address'].streetNumber : getAddressField(this.address, AddressField.STREETNUMBER),
      route: this.address === null ? this.userAddress['address'].route : getAddressField(this.address, AddressField.ROUTE),
      locality: this.address === null ? this.userAddress['address'].locality : getAddressField(this.address, AddressField.LOCALITY),
      country: this.address === null ? this.userAddress['address'].country : getAddressField(this.address, AddressField.COUNTRY),
      postalCode: this.address === null ? this.userAddress['address'].postalCode : getAddressField(this.address, AddressField.POSTALCODE),
      formattedAddress: this.address === null ? this.userAddress['address'].formattedAddress : this.address.formatted_address,
      lat: this.address === null ? this.userAddress['address'].lat : this.address.geometry.location.lat(),
      lng: this.address === null ? this.userAddress['address'].lng : this.address.geometry.location.lng(),
      phoneNumber: PhoneNumberUtil.getInstance().format(number, PhoneNumberFormat.INTERNATIONAL),
      additional: this.addressForm.controls['moreInfo'].value,
    };

    this.activeModal.close(address);
  }
}
