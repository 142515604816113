import { Component, Input, OnInit } from '@angular/core';

import { ApiLavandierService } from '../../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListToMap } from '../../../../../lib/lib-ngx/utils/ListToMap';
import { Page } from '../../../../../lib/lib-ngx/utils/Page';

@Component({
  selector: 'lm-user-incidents',
  templateUrl: './user-incidents.component.html',
  styleUrls: ['./user-incidents.component.scss']
})
export class UserIncidentsComponent implements OnInit {
  @Input() user = null;

  public page = new Page();
  public incidentsList = null;
  @Input() public lavandierMap = new Map();
  public shopMap = new Map();
  public incidentTypeMap = new Map();

  constructor(
    private apiLavandierService: ApiLavandierService,
  ) {
    this.page.setPageNumber(0);
    this.page.size = 10;
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    return forkJoin([
      this.apiLavandierService.getIncidentList({userId: `${this.user.id}`}, this.page.toQueryObject()),
      this.apiLavandierService.getIncidentTypes(),
      this.apiLavandierService.getShops(),
    ]).pipe(
      map(([IncidentList, incidentTypeList, shopList]: any[]) => [
        IncidentList,
        ListToMap.convert(incidentTypeList as any[]),
        ListToMap.convert(shopList as any[]),
      ])
    ).subscribe(([incidentList, incidentTypeMap, shopMap]: any[]) => {
      this.page.totalElements = incidentList.count;
      this.page.totalPages = this.page.totalElements / this.page.size;
      this.incidentsList = incidentList.rows;
      this.incidentTypeMap = incidentTypeMap as any;
      this.shopMap = shopMap as any;
    });
  }

  setPage(pageNumber: number) {
    this.page.setPageNumber(pageNumber);
    this.loadData();
  }
}

