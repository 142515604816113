import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CustomDatepickerI18nService } from '../../../services/custom-datepicker-i18n.service';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-add-scooter',
  templateUrl: './add-scooter.component.html',
  styleUrls: ['./add-scooter.component.scss']
})
export class AddScooterComponent implements OnInit {
  @Input('lmp') lmp;
  @Input('lmpList') lmpList;
  @Output('submitForm') submitForm = new EventEmitter<boolean>();
  public addScooterForm: FormGroup;

  constructor(private fb: FormBuilder,
              private apiLavandierService: ApiLavandierService,
              private customDate: CustomDatepickerI18nService) {
  }

  ngOnInit() {
    this.buildForm();
  }

  /**
   * Method to build for for adding a scooter
   */
  buildForm() {
    this.addScooterForm = this.fb.group({
      registrationP1: ['', Validators.required],
      registrationP2: ['', Validators.required],
      registrationP3: ['', Validators.required],
      code: ['', Validators.required],
      store: [this.lmp['name'] === undefined ?
        this.lmpList[0]['id'] :
        this.lmp['id'], Validators.required],
      date: ['', Validators.required],
      registrationDocument: [false],
      geoCoyote: [false],
      stickersNumber: [false],
      backUpKey: [false],
      insuranceCard: [false],
      key: [false],
      covering: [false]
    });
  }

  onSubmitForm(event) {
    if (event === 'validate') {
      const date = new Date().toISOString();
      const scooterValue = this.addScooterForm.value;
      this.apiLavandierService.postNewScooter(scooterValue.registrationP1.toUpperCase() + '-' + scooterValue.registrationP2.toUpperCase()
        + '-' + scooterValue.registrationP3.toUpperCase(), scooterValue.code.toUpperCase(), scooterValue.store,
        this.customDate.getENDayAriaLabel(scooterValue.date), scooterValue.registrationDocument ? date : null,
        scooterValue.geoCoyote ? date : null, scooterValue.stickersNumber ? date : null,
        scooterValue.backUpKey ? date : null, scooterValue.insuranceCard ? date : null,
        scooterValue.key ? date : null, scooterValue.covering ? date : null).subscribe(
        data => this.submitForm.emit(true),
        error => console.log('AddScooterFailure', error)
      );
    } else {
      this.submitForm.emit(false);
    }
  }

}
