import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthService } from '../../lib/lib-ngx/web-services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FakeApiService {

  constructor(private http: HttpClient,
              private authService: AuthService) {
  }

  getDispatchByLavandierData(): Observable<any> {
    return this.http.get('assets/json/dispatching-lavandier.json');
  }

  getAvailableMissionData(): Observable<any> {
    return this.http.get('assets/json/available-missions.json');
  }

  getAvailableLavandierData(): Observable<any> {
    return this.http.get('assets/json/available-lavandiers.json');
  }

  getDispatchAreaData(): Observable<any> {
    return this.http.get('assets/json/dispatch-area.json');
  }

  getQuarterbackingData(): Observable<any> {
    return this.http.get('assets/json/quarterbacking.json');
  }
}
