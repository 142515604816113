import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lm-add-event-scooter',
  templateUrl: './add-event-scooter.component.html',
  styleUrls: ['./add-event-scooter.component.scss']
})
export class AddEventScooterComponent implements OnInit {
  @Input('scooterData') scooterData;
  @Output('submitForm') submitForm = new EventEmitter<true>();

  constructor() { }

  ngOnInit() {
  }

  /**
   * Method called after click on a form button
   * @param event Must be cancel or validate
   */
  onSubmitForm(event) {
    // Call API to add new event
    this.submitForm.emit(true);
  }

}
