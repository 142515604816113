import { Component, OnInit } from '@angular/core';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { Page } from '../../../../lib/lib-ngx/utils/Page';
import { PRICEMULTIPLIER } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-user-club',
  templateUrl: './user-club.component.html',
  styleUrls: ['./user-club.component.scss']
})
export class UserClubComponent implements OnInit {
  public PRICEMULTIPLIER = PRICEMULTIPLIER;

  public MembershipList = [];

  page = new Page();

  constructor(
    private apiLavandierService: ApiLavandierService,
  ) {
    this.page.setPageNumber(0);
    this.page.size = 10;
  }

  ngOnInit() {
    this.loadUserClub();
  }

  loadUserClub() {
    this.page.checkPage();
    this.apiLavandierService.getMembershipList(this.page.toQueryObject())
      .subscribe((membershipList: any) => {
        this.MembershipList = membershipList.rows;
        this.page.totalElements = membershipList.count;
        this.page.totalPages = membershipList.count / this.page.size;
      });
  }

  setPage(pageInfo) {
    this.page.setPageNumber(pageInfo.offset);
    this.loadUserClub();
  }
}
