/// <reference types="@types/googlemaps" />
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { HomeService } from './services/home.service';
import { ErrorService } from '../lib/lib-ngx/services/error.service';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public openSideMenu = true;
  public screenWidth;
  public sideMenuStatusSubscription;

  constructor(
    private homeService: HomeService,
    public errorService: ErrorService,
    public modalService: NgbModal,
  ) {
    moment.defineLocale('fr-lm', {
      parentLocale: 'fr',
      week: {dow: 0, doy: 6}
    });
    moment.locale('fr-lm');

    errorService.setModal((text: String) => {
      setTimeout(() => {
        const confirmationModal = modalService.open(ConfirmationModalComponent, {size: 'lg'});
        confirmationModal.componentInstance.confirmationDesc = text;
        confirmationModal.componentInstance.validateButtonText = 'Ok';
      });
    });
  }

  ngOnInit() {
    this.onResize();
    // Subscribe to a subject to know the side menu status, opened or closed
    this.sideMenuStatusSubscription = this.homeService.getSideMenu().subscribe(
      (response) => {
        this.openSideMenu = response;
      }
    );
  }

  ngOnDestroy() {
    this.sideMenuStatusSubscription.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  changeOpenSideMenuValue() {
    if (this.screenWidth < 1200) {
      this.openSideMenu = !this.openSideMenu;
      this.homeService.setSideMenuStatus(this.openSideMenu);
    }
  }
}
