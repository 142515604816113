import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-set-slot-modal',
  templateUrl: './set-slot-modal.component.html',
  styleUrls: ['./set-slot-modal.component.scss']
})
export class SetSlotModalComponent implements OnInit {
  public setSlotForm: FormGroup;
  public timeSlotTypeList: Object[] = [];
  public selectedTimeSlotType = {
    id: 0
  };

  constructor(public activeModal: NgbActiveModal,
              private fb: FormBuilder,
              private apiLavandierService: ApiLavandierService) {
  }

  ngOnInit() {
    this.buildForm();
    if (this.timeSlotTypeList.length > 0) {
      this.onChangeType(this.timeSlotTypeList[0]);
    }
  }

  /**
   * Method to build setSlotForm
   */
  buildForm() {
    this.setSlotForm = this.fb.group({
      slotColor: ['#000000'],
      slotTime: ['', Validators.required],
      switchCustomer: ['', Validators.required]
    });
  }

  onChangeType(timeSlotType) {
    this.selectedTimeSlotType = timeSlotType;
    this.setSlotForm.patchValue({
      slotColor: timeSlotType.color,
      slotTime: timeSlotType.duration,
      switchCustomer: timeSlotType.switchDate
    });
  }

  onSubmitForm() {
    const id = this.selectedTimeSlotType.id;
    const color = this.setSlotForm.controls['slotColor'].value;
    const duration = this.setSlotForm.controls['slotTime'].value;
    const switchDate = this.setSlotForm.controls['switchCustomer'].value;
    this.apiLavandierService.putTimeSlotType(id, color, duration, switchDate)
      .subscribe(data => this.handlePutTimeSlotTypeSuccess(data));
  }

  handlePutTimeSlotTypeSuccess(data) {
    this.activeModal.close(true);
  }
}
