import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lm-user-data-verification-modal',
  templateUrl: './user-data-verification-modal.component.html',
  styleUrls: ['./user-data-verification-modal.component.scss']
})
export class UserDataVerificationModalComponent implements OnInit {
  public userDetails = null;
  public userAddresses = [];

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
  }
}
