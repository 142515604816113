import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PaymentType } from '../../../../lib/lib-shared/types/PaymentType';
import { PRICEMULTIPLIER } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-add-payment-modal',
  templateUrl: './add-payment-modal.component.html',
  styleUrls: ['./add-payment-modal.component.scss']
})
export class AddPaymentModalComponent implements OnInit {
  public paymentTypeList = Object.values(PaymentType).filter(paymentType => paymentType !== PaymentType.STRIPE && paymentType !== PaymentType.FAKE);

  public form: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      paymentType: [null, Validators.required],
      amount: [0, [Validators.required, Validators.min(0)]],
      externalId: [null],
    });
  }

  onValidate() {
    this.activeModal.close({
      paymentType: this.form.value.paymentType,
      amount: this.form.value.amount * PRICEMULTIPLIER,
      externalId: this.form.value.externalId ? this.form.value.externalId : null,
    });
  }
}
