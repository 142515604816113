import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

import { CustomDatepickerI18nService } from '../../../services/custom-datepicker-i18n.service';
import { FakeApiService } from '../../../services/fake-api.service';
import { AttributeMissionModalComponent } from '../../modals/attribute-mission-modal/attribute-mission-modal.component';
import { ReattributeMissionModalComponent } from '../../modals/reattribute-mission-modal/reattribute-mission-modal.component';

@Component({
  selector: 'lm-dispatch-lavandier',
  templateUrl: './dispatch-lavandier.component.html',
  styleUrls: ['./dispatch-lavandier.component.scss']
})
export class DispatchLavandierComponent implements OnInit {
  public lmpList = [];
  public missionsList = [];
  public missionsTempList = [];
  public filterForm: FormGroup;

  constructor(private fakeApiService: FakeApiService,
              private fb: FormBuilder,
              public calendar: NgbCalendar,
              public customDate: CustomDatepickerI18nService,
              public sanitizer: DomSanitizer,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.buildForm();
    this.fakeApiService.getDispatchByLavandierData().subscribe(
      response => {
        this.lmpList = response.data.lmpList;
        this.missionsList = response.data.missionsList;
        this.missionsTempList = [...response.data.missionsList];
        this.filterDispatchLavandier();
      }
    );
  }

  buildForm() {
    this.filterForm = this.fb.group({
      name: [''],
      lmp: [''],
      date: [this.calendar.getToday()]
    });
  }

  /**
   * Method which return a boolean to know if the lavandier's name match the filter
   * @param lavandier Lavandier to compare
   * @returns {boolean} True if the name from the filter form is included in the lavandier firstname or lastname
   */
  matchWithFilter(lavandier) {
    return this.filterForm.value.name === '' ||
      lavandier.firstName.toLowerCase().includes(this.filterForm.value.name.toString().toLowerCase()) ||
      lavandier.lastName.toLowerCase().includes(this.filterForm.value.name.toString().toLowerCase());
  }

  /**
   * Method which will open a modal to user
   * @param lavandier Which lavandier to attribute missions
   */
  attributeMission(lavandier) {
    const attributeMission = this.modalService.open(AttributeMissionModalComponent, {size: 'lg'});
    attributeMission.componentInstance.lavandier = lavandier;
    attributeMission.result.then(
      result => {
        // console.log(result);
        // Call API to attribute missions from result.missionsSelected
      }
    );
  }

  reattribruteMission(mission) {
    const reattributeMission = this.modalService.open(ReattributeMissionModalComponent, {size: 'lg'});
    reattributeMission.componentInstance.mission = mission;
    reattributeMission.result.then(
      result => {
        // console.log(result);
        // Call API to change mission's owner
      }
    );
  }

  filterDispatchLavandier() {
    const self = this;
    const filterValues = this.filterForm.value;

    // filter our data
    const clubTemp = this.missionsTempList.filter(function (d) {
      return (d.date === self.customDate.getDayAriaLabel(filterValues.date));
    });

    // update the rows
    this.missionsList = clubTemp;
  }

}
