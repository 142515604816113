import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const I18N_VALUES = {
  'fr': {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  }
  // other languages you would support
};

@Injectable()
export class I18n {
  language = 'fr';
}

@Injectable({
  providedIn: 'root'
})
export class CustomDatepickerI18nService extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date): string {
    const day = `${date.day}`.length === 1 ? '0' + `${date.day}` : `${date.day}`;
    const month = `${date.month}`.length === 1 ? '0' + `${date.month}` : `${date.month}`;
    return day + '/' + month + `/${date.year}`;
  }

  getENDayAriaLabel(date): string {
    const day = `${date.day}`.length === 1 ? '0' + `${date.day}` : `${date.day}`;
    const month = `${date.month}`.length === 1 ? '0' + `${date.month}` : `${date.month}`;
    return month + '/' + day + `/${date.year}`;
  }
}
