export interface StringPage {
  offset: string;
  limit: string;
}

export class Page {
  // The number of elements in the page
  size = 0;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  private pageNumber = 0;
  private pageNumberPrevious = 0;

  setPageNumber(pageNumber: number) {
    this.pageNumberPrevious = this.pageNumber;
    this.pageNumber = pageNumber;
  }

  getPageNumber(): number {
    return this.pageNumber;
  }

  checkPage() {
    if (this.pageNumber === this.pageNumberPrevious) {
      this.pageNumber = 0;
    }
    this.pageNumberPrevious = this.pageNumber;
  }

  toQueryObject(): StringPage {
    return {
      offset: (this.pageNumber * this.size).toString(),
      limit: this.size.toString(),
    };
  }
}
