import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { IRole } from '../../../../lib/lib-ngx/web-services/api-lavandier.service.type';
import { EnumToArrayPipe } from '../../../../lib/lib-ngx/pipes/enum-to-array.pipe';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { PermissionName } from '../../../../lib/lib-shared/types/PermissionName';

@Component({
  selector: 'lm-role-add',
  templateUrl: './role-add.component.html',
  styleUrls: ['./role-add.component.scss']
})
export class RoleAddComponent implements OnInit {
  public PermissionName = PermissionName;

  public addRoleForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.addRoleForm = this.fb.group({
      name: ['', Validators.required],
    });
    const enumToArrayPipe = new EnumToArrayPipe();
    enumToArrayPipe.transform(PermissionName).forEach((permissionName) => {
      this.addRoleForm.addControl(permissionName.toLowerCase(), new FormControl(false));
    });
  }

  onNewRole() {
    const role: IRole = {
      name: this.addRoleForm.controls['name'].value,
      permissionNameList: [],
    };

    new EnumToArrayPipe().transform(PermissionName).forEach((permissionName) => {
      if (this.addRoleForm.controls[permissionName.toLowerCase()].value) {
        role.permissionNameList.push(permissionName);
      }
    });

    this.apiLavandierService.postRole(role)
      .subscribe(
        () => this.router.navigate(['/role/list'])
      );
  }
}
