import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'lm-qrcode-apikey-modal',
  templateUrl: './qrcode-apikey-modal.component.html',
  styleUrls: ['./qrcode-apikey-modal.component.scss']
})
export class QRCodeAPIKeyModalComponent implements OnInit {
  @ViewChild('pdf') public pdf: ElementRef;
  public pdfUrlObservable: Observable<any>;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    this.pdfUrlObservable.subscribe((data: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        this.pdf.nativeElement.src = reader.result;
      };
    });
  }
}
