import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { Page } from '../../../../lib/lib-ngx/utils/Page';
import { OrderTransportType } from '../../../../lib/lib-shared/types/OrderTransportType';

@Component({
  selector: 'lm-tab-incident',
  templateUrl: './tab-incident.component.html',
  styleUrls: ['./tab-incident.component.scss']
})
export class TabIncidentComponent implements OnInit {
  @Input() public incidentList = [];
  @Input() public incidentTypeMap = new Map();
  @Input() public lavandierMap = new Map();
  @Input() public shopMap = new Map();
  @Input() page: Page = new Page();

  @Output() pageNumber = new EventEmitter<number>();

  constructor(
    public router: Router,
  ) {
  }

  ngOnInit() {
  }

  getLastPickupOrderTransport(orderTransportList: any) {
    for (let i = orderTransportList.length - 1; i >= 0; i--) {
      if (orderTransportList[i].type === OrderTransportType.PICKUP) {
        return orderTransportList[i];
      }
    }
  }

  setPage(pageInfo) {
    this.pageNumber.emit(pageInfo.offset);
  }
}
