import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ErrorService } from '../../../../lib/lib-ngx/services/error.service';
import { AuthService } from '../../../../lib/lib-ngx/web-services/auth.service';
import { OrderTemporary } from '../../../../lib/lib-shared/types/OrderTemporary';
import { Error } from '../../../../lib/lib-ngx/utils/error';

@Component({
  selector: 'lm-quarterbacking-details',
  templateUrl: './quarterbacking-details.component.html',
  styleUrls: ['./quarterbacking-details.component.scss']
})
export class QuarterbackingDetailsComponent implements OnInit {
  public orderId = null;
  public orderTransportId = null;
  public shopList = [];

  public shopFormControl = new FormControl(null);

  public allTsArePrepared = false;

  constructor(
    private route: ActivatedRoute,
    private apiLavandierService: ApiLavandierService,
    private errorService: ErrorService,
    private router: Router,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        this.orderId = params.orderId;
        this.orderTransportId = params.orderTransportId;
        this.loadData();
      });
  }

  loadData() {
    if (this.orderId) {
      this.apiLavandierService.getShops()
        .subscribe((shopList: any[]) => {
          this.shopList = shopList;

          const lavandierShop = this.shopList.find(shop => shop.lavandiers.find(lavandier => lavandier.userId === this.authService.getDecodeToken().id));
          this.shopFormControl.patchValue(lavandierShop ? lavandierShop : null);
        });
    }
  }

  updateOrder(order) {
    if (order.temporary !== OrderTemporary.NONE) {
      this.errorService.showModal(Error.ORDER_NOT_QUARTERBACKABLE);
      this.router.navigate(['/order-details', this.orderId]);
      return;
    }
  }

  isAllTsArePrepared(value: boolean) {
    this.allTsArePrepared = value;
  }

  onCounterDelivery() {
    this.apiLavandierService.postOrderIdDelivery(this.orderId, {
      inShopId: this.shopFormControl.value.id,
    })
      .subscribe((data: any) => {
        if (!this.errorService.manageError(data)) {
          this.router.navigate(['/order-details', this.orderId]);
        }
      });
  }
}
