import { Component, OnInit } from '@angular/core';

import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';

@Component({
  selector: 'lm-article-setting',
  templateUrl: './article-setting.component.html',
  styleUrls: ['./article-setting.component.scss']
})
export class ArticleSettingComponent implements OnInit {
  public listToMap = ListToMap;

  public articleOptionTypeMap = new Map();

  constructor() {
  }

  ngOnInit() {
  }
}
