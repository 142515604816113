import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private sideMenuSubject = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  setSideMenuStatus(bool: boolean) {
    this.sideMenuSubject.next(bool);
  }

  getSideMenu(): Observable<boolean> {
    return this.sideMenuSubject.asObservable();
  }

  getSideBarData(): Observable<any> {
    return this.http.get('assets/json/side-bar.json');
  }
}
