export enum EventConfigEvent {
  // status
  CREATE = 'CREATE',
  PICKUPASSIGNED = 'PICKUPASSIGNED',
  PICKUPUNASSIGNED = 'PICKUPUNASSIGNED',
  PICKUPSTARTED = 'PICKUPSTARTED',
  PICKUPDONE = 'PICKUPDONE',
  STOW = 'STOW',
  DELIVERYASKED = 'DELIVERYASKED',
  DELIVERYASSIGNED = 'DELIVERYASSIGNED',
  DELIVERYUNASSIGNED = 'DELIVERYUNASSIGNED',
  DELIVERYSTARTED = 'DELIVERYSTARTED',
  DELIVERYDONE = 'DELIVERYDONE',
  FINISH = 'FINISH',
  FINISH_WITHOUT_PROBLEM = 'FINISH_WITHOUT_PROBLEM',
  CANCEL = 'CANCEL',

  // others
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD',
  INVOICE = 'INVOICE',
  TRANSFER_DELIVERED = 'TRANSFER_DELIVERED',
  SHIPPING_EXCEPTION = 'SHIPPING_EXCEPTION',
  NOSHOW_EXCEPTION = 'NOSHOW_EXCEPTION',
  NOSHOW = 'NOSHOW',
  STOW_DEADLINE = 'STOW_DEADLINE',
  STOW_DEADLINE_EXCEPTION = 'STOW_DEADLINE_EXCEPTION',
  STOW_DEADLINE_EVENT1 = 'STOW_DEADLINE_EVENT1',
  STOW_DEADLINE_EVENT2 = 'STOW_DEADLINE_EVENT2',
  SPONSORSHIP_CREDIT = 'SPONSORSHIP_CREDIT',
}
