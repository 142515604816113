import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {
  public roleList = [];

  constructor(
    private apiLavandierService: ApiLavandierService,
    public router: Router,
  ) {
  }

  ngOnInit() {
    this.loadRoleList();
  }

  loadRoleList() {
    this.apiLavandierService.getRoleList()
      .subscribe(
        (roleList: any[]) => this.roleList = roleList
      );
  }
}
