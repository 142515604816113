import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { NgbCalendar, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ListToMap } from '../../../lib/lib-ngx/utils/ListToMap';
import { AddOrderBagModalComponent } from '../modals/add-order-bag-modal/add-order-bag-modal.component';
import { IOrderBag } from '../../../lib/lib-ngx/web-services/api.service.type';
import { OrderTransportType } from '../../../lib/lib-shared/types/OrderTransportType';
import { EditBagListModalComponent } from '../modals/edit-bag-list-modal/edit-bag-list-modal.component';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { ErrorService } from '../../../lib/lib-ngx/services/error.service';
import { OrderBagCreation } from '../../../lib/lib-shared/types/OrderBagCreation';
import { RangeUtils } from '../../../lib/lib-shared/utils/Range.Utils';
import { ErrorMessage } from '../../../lib/lib-shared/ErrorMessage';
import { OrderTemporary } from '../../../lib/lib-shared/types/OrderTemporary';
import { ArticleFamilyType } from '../../../lib/lib-shared/types/ArticleFamilyType';

@Component({
  selector: 'lm-scan-bag',
  templateUrl: './scan-bag.component.html',
  styleUrls: ['./scan-bag.component.scss']
})
export class ScanBagComponent implements OnInit {
  public ArticleFamilyType = ArticleFamilyType;

  public orderBagRefFormControl = new FormControl();
  @ViewChild('orderBagRefInput') orderBagRefInput: ElementRef;
  public filterForm: FormGroup;

  public articleRangeMap = new Map();
  public articleOptionMap = new Map();
  public shopMap = new Map();
  public userTypeMap = new Map();

  public orderBag = null;
  public orderTransportList = [];

  constructor(
    private apiLavandierService: ApiLavandierService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private calendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    public sanitizer: DomSanitizer,
    public errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.loadData();
    this.buildForm();
  }

  loadData() {
    forkJoin([
      this.apiLavandierService.getArticleRangeList(),
      this.apiLavandierService.getArticleOptionPublicList(),
      this.apiLavandierService.getShops(),
      this.apiLavandierService.getUserTypeList(),
    ]).pipe(
      map(([articleRangeList, articleOptionList, shopList, userTypeList]: [Object[], Object[], Object[], Object[]]) => {
        return [
          ListToMap.convert(articleRangeList),
          ListToMap.convert(articleOptionList),
          ListToMap.convert(shopList),
          ListToMap.convert(userTypeList),
        ];
      })
    ).subscribe(([articleRangeMap, articleOptionMap, shopMap, userTypeMap]: [Map<number, Object>, Map<number, Object>, Map<number, Object>, Map<number, Object>]) => {
      this.articleRangeMap = articleRangeMap;
      this.articleOptionMap = articleOptionMap;
      this.shopMap = shopMap;
      this.userTypeMap = userTypeMap;
    });
  }

  buildForm() {
    this.orderBagRefFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(ref => {
          this.orderBag = null;
          if (ref && ref.trim().length) {
            return this.apiLavandierService.getOrderBagRefDetails(ref);
          }
          return of(null);
        }),
      )
      .subscribe(orderBag => {
        if (orderBag) {
          this.setOrderBag(orderBag);
        } else {
          if (this.orderBagRefFormControl.value && this.orderBagRefFormControl.value.trim()) {
            this.filterForm.updateValueAndValidity();
          }
          this.reset(false);
        }
      });

    this.filterForm = this.fb.group({
      name: '',
      date: [this.calendar.getToday()],
      shop: [null],
    });
    this.filterForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(data => {
          return this.apiLavandierService.getOrderTransportList({
            canceled: false.toString(),
            name: data.name,
            shopId: data.shop ? data.shop.id : '',
            date: this.ngbDateParserFormatter.format(data.date),
          });
        }),
      )
      .subscribe((orderTransportList: any) => {
        this.orderTransportList = orderTransportList.filter(orderTransport => [OrderTransportType.PICKUP, OrderTransportType.DELIVERY].includes(orderTransport.type) && orderTransport.doneAt);
      });

    this.reset(true);
  }

  reset(reset: boolean) {
    this.orderBagRefFormControl.enable({emitEvent: false});
    this.getTrackingSheetNumberInputFocus();

    if (reset) {
      this.orderBagRefFormControl.reset();
    }
    this.orderBagRefFormControl.markAsTouched();
  }

  setOrderBag(orderBag) {
    if (orderBag.checkAt) {
      this.errorService.showModal(ErrorMessage.ORDERBAG_ALREADY_CHECKED);
      this.reset(true);
      return;
    }
    if (orderBag.finishAt) {
      this.errorService.showModal(ErrorMessage.ORDERBAG_ALREADY_FINISH);
      this.reset(true);
      return;
    }

    if (![OrderTemporary.NONE, OrderTemporary.DONE].some(value => value === orderBag.order.temporary)) {
      this.errorService.showModal(ErrorMessage.ORDER_NOT_PICKUP_MULTIPLE);
      this.reset(true);
      return;
    }

    this.orderBag = orderBag;
  }

  getTrackingSheetNumberInputFocus() {
    setTimeout(() => this.orderBagRefInput.nativeElement.focus());
  }

  isRangeAvailableForUserType() {
    return RangeUtils.isRangeAvailableForUserType(this.orderBag.order.user.userTypeId, this.orderBag.rangeId, this.articleRangeMap);
  }

  getAddress(orderTransport) {
    const sameTypeOrderAddress = orderTransport.order.orderAddresses.filter(orderAddress => orderAddress.type === orderTransport.type);
    return sameTypeOrderAddress[sameTypeOrderAddress.length - 1];
  }

  editOrderBag() {
    const addOrderBagModal = this.modalService.open(AddOrderBagModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });

    const orderBag: IOrderBag = {
      rangeId: this.orderBag.rangeId,
      articleOptionIdList: this.orderBag.articleOptions.map(articleOption => articleOption.id),
      type: this.orderBag.type,
      comment: this.orderBag.comment,
      ref: this.orderBag.ref,
    };

    const rangeMap = RangeUtils.getRangeMapAvailableForUserType(this.orderBag.order.user.userTypeId, this.articleRangeMap);
    addOrderBagModal.componentInstance.bagNumber = this.orderBag.id;
    addOrderBagModal.componentInstance.articleRangeMap = rangeMap;
    addOrderBagModal.componentInstance.articleOptionMap = this.articleOptionMap;
    addOrderBagModal.componentInstance.articleFamilyTypeSelected = this.orderBag.type;
    addOrderBagModal.componentInstance.articleRangeSelected = rangeMap.has(this.orderBag.rangeId) ? rangeMap.get(this.orderBag.rangeId) : null;
    addOrderBagModal.componentInstance.editOrderBag = orderBag;
    addOrderBagModal.result
      .then((editedOrderBag: IOrderBag) => this.apiLavandierService.putOrderBag(this.orderBag.id, editedOrderBag)
        .subscribe((data) => {
          if (!this.errorService.manageError(data)) {
            this.apiLavandierService.getOrderBagDetails(this.orderBag.id)
              .subscribe(newOrderBag => this.setOrderBag(newOrderBag));
          }
        }))
      .catch(() => null);
  }

  onCheck() {
    this.apiLavandierService.putOrderBagCheck(this.orderBag.id)
      .subscribe((data) => {
        if (!this.errorService.manageError(data)) {
          this.ngOnInit();
        }
      });
  }

  onLineEvent(event) {
    if (event.type === 'click') {
      event.cellElement.blur();
      const orderTransport = event.row;

      if (orderTransport.away || orderTransport.type === OrderTransportType.DELIVERY) {
        const confirmationModal = this.modalService.open(ConfirmationModalComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static',
          keyboard: false
        });

        const orderTransportParent = this.orderTransportList.find(ot => ot.parentId === orderTransport.id);
        if (orderTransportParent) {
          confirmationModal.componentInstance.confirmationDesc = `Voulez-vous ouvrir l'enlèvement simultané de cette livraison ?`;
          confirmationModal.componentInstance.validateButtonText = 'Valider';
          confirmationModal.componentInstance.cancelButtonText = 'Annuler';
          confirmationModal.result
            .then(result => {
              if (result) {
                this.showEditOrderBagListModal(orderTransportParent);
              }
            })
            .catch(() => null);
        } else {
          confirmationModal.componentInstance.confirmationDesc = `Voulez-vous annuler l'absence du client à cet enlèvement ?`;
          if (orderTransport.type === OrderTransportType.DELIVERY) {
            confirmationModal.componentInstance.confirmationDesc = `Voulez-vous créer un enlèvement associé à cet enlèvement ?`;
            if (orderTransport.away) {
              confirmationModal.componentInstance.confirmationDesc = `Voulez-vous annuler l'absence du client à cette livraison et créer un enlèvement associé ?`;
            }
          }
          confirmationModal.componentInstance.validateButtonText = 'Valider';
          confirmationModal.componentInstance.cancelButtonText = 'Annuler';
          confirmationModal.result
            .then(result => {
              if (result) {
                this.apiLavandierService.putOrderTransportReOpen(orderTransport.id)
                  .subscribe((newOrderTransport: any) => {
                    if (!this.errorService.manageError(newOrderTransport)) {
                      this.apiLavandierService.getOrderTransportDetails(newOrderTransport.id)
                        .subscribe(newOrderTransportDetails => this.showEditOrderBagListModal(newOrderTransportDetails));
                    }
                  });
              }
            })
            .catch(() => null);
        }
      } else {
        this.showEditOrderBagListModal(orderTransport);
      }
    }
  }

  showEditOrderBagListModal(orderTransport) {
    const EditBagListModal = this.modalService.open(EditBagListModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });

    EditBagListModal.componentInstance.articleRangeMap = RangeUtils.getRangeMapAvailableForUserType(orderTransport.order.user.userTypeId, this.articleRangeMap);
    EditBagListModal.componentInstance.articleOptionMap = this.articleOptionMap;
    EditBagListModal.componentInstance.userTypeMap = this.userTypeMap;
    EditBagListModal.componentInstance.orderTransport = orderTransport;

    EditBagListModal.result
      .then(orderBag => {
        if (orderBag) {
          this.apiLavandierService.getOrderBagDetails(orderBag.id)
            .subscribe(newOrderBag => {
              this.setOrderBag(newOrderBag);
              this.editOrderBag();
            });
        } else {
          this.addOrderBag(orderTransport.order);
        }
      })
      .catch(() => null);
  }

  addOrderBag(order) {
    const addOrderBagModal = this.modalService.open(AddOrderBagModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });

    addOrderBagModal.componentInstance.orderBagList = order.orderBags;
    addOrderBagModal.componentInstance.bagNumber = order.orderBags.length + 1;
    addOrderBagModal.componentInstance.articleRangeMap = RangeUtils.getRangeMapAvailableForUserType(order.user.userTypeId, this.articleRangeMap);
    addOrderBagModal.componentInstance.articleOptionMap = this.articleOptionMap;
    addOrderBagModal.result
      .then((orderBag: IOrderBag) => {
        this.apiLavandierService.postOrderBagOrder(order.id, {...orderBag, ...{creation: OrderBagCreation.ADMIN_CHECK}})
          .subscribe((createdOrderBag: any) => {
            if (!this.errorService.manageError(createdOrderBag)) {
              this.apiLavandierService.getOrderBagDetails(createdOrderBag.id)
                .subscribe(newOrderBag => this.setOrderBag(newOrderBag));
            }
          });
      })
      .catch(() => null);
  }
}
