import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { forkJoin, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { Page } from '../../../../lib/lib-ngx/utils/Page';
import { PRICEMULTIPLIER } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  public PRICEMULTIPLIER = PRICEMULTIPLIER;

  page = new Page();

  public userList = [];
  public filterForm: FormGroup;
  public userTypeList = [];

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    public router: Router,
    private apiLavandierService: ApiLavandierService,
  ) {
    this.page.setPageNumber(0);
    this.page.size = 10;
  }

  ngOnInit() {
    this.loadData().subscribe();
    this.buildForm();

    this.filterForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(data => {
          this.page.checkPage();
          return this.apiLavandierService.getUserList({
            id: data.id ? data.id : '',
            userTypeId: data.userType,
            email: data.email,
            name: data.name,
            phoneNumber: data.phoneNumber ? data.phoneNumber : '',
            createdAt: data.createdAt ? this.ngbDateParserFormatter.format(data.createdAt) : '',
          }, this.page.toQueryObject());
        }),
      )
      .subscribe((userList: any) => {
        this.page.totalElements = userList.count;
        this.page.totalPages = this.page.totalElements / this.page.size;
        this.userList = userList.rows;
        this.userList.forEach((user: any) => {
          let totalAmount = 0;
          user.orders.forEach((order: any) => {
            totalAmount += order.price;
          });
          user.averageBasket = ((user.orders.length) ? (totalAmount / user.orders.length) : 0).toFixed(2);
          user.lastOrder = (user.orders[user.orders.length - 1]) ? user.orders[user.orders.length - 1].createdAt : '';
          user.averageNumberOrderMonthly = (user.orders.length / moment().diff(moment(user.createdAt), 'months', true)).toFixed(2);
          user.totalAmount = totalAmount.toFixed(2);
        });
      });
    this.filterForm.updateValueAndValidity();
  }

  setPage(pageInfo) {
    this.page.setPageNumber(pageInfo.offset);
    this.filterForm.updateValueAndValidity();
  }

  loadData(): Observable<any> {
    return forkJoin([
      this.apiLavandierService.getUserTypeList(),
    ]).pipe(
      map(([userTypeList]) => {
        this.userTypeList = userTypeList as any[];
      })
    );
  }

  buildForm() {
    this.filterForm = this.fb.group({
      id: [''],
      name: [''],
      email: [''],
      createdAt: [null],
      phoneNumber: [''],
      userType: [''],
    });
  }

  onRowClick(event) {
    if (event.type === 'click') {
      this.router.navigate(['user-profile', event.row.id]);
    }
  }
}
