import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lm-user-profile-performance',
  templateUrl: './user-profile-performance.component.html',
  styleUrls: ['./user-profile-performance.component.scss',
    '../user-profile.component.scss']
})
export class UserProfilePerformanceComponent implements OnInit {
  @Input('userProfileData') userProfileData;

  constructor() { }

  ngOnInit() {
  }

}
