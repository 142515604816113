import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { EventConfigType } from '../../../../lib/lib-shared/types/EventConfigType';
import { EventConfigEvent } from '../../../../lib/lib-shared/types/EventConfigEvent';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-event-config-edit-modal',
  templateUrl: './event-config-edit-modal.component.html',
  styleUrls: ['./event-config-edit-modal.component.scss']
})
export class EventConfigEditModalComponent implements OnInit {
  public EventConfigType = EventConfigType;
  public EventConfigEvent = EventConfigEvent;

  public configId;
  public eventConfig;

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      type: [this.eventConfig ? this.eventConfig.type : null, Validators.required],
      event: [this.eventConfig ? this.eventConfig.event : null, Validators.required],
    });

    this.form.controls.type.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged())
      .subscribe(type => {
        this.form.removeControl(EventConfigType.EMAIL);
        this.form.removeControl(EventConfigType.SMS);
        this.form.removeControl(EventConfigType.WEBHOOK);
        if (type === EventConfigType.EMAIL) {
          let orderClassic;
          let orderCAndC;
          if (this.eventConfig && this.eventConfig.type === EventConfigType.EMAIL) {
            orderClassic = this.eventConfig.value.orderClassic;
            orderCAndC = this.eventConfig.value.orderCAndC;
          }
          this.form.addControl(EventConfigType.EMAIL, this.fb.group({
            orderClassic: [orderClassic],
            orderCAndC: [orderCAndC],
          }));
        } else if (type === EventConfigType.SMS) {
          let textClassic;
          let textCAndC;
          if (this.eventConfig && this.eventConfig.type === EventConfigType.SMS) {
            textClassic = this.eventConfig.value.textClassic;
            textCAndC = this.eventConfig.value.textCAndC;
          }
          this.form.addControl(EventConfigType.SMS, this.fb.group({
            textClassic: [textClassic],
            textCAndC: [textCAndC],
          }));
        } else if (type === EventConfigType.WEBHOOK) {
          let url;
          let bearer;
          if (this.eventConfig && this.eventConfig.type === EventConfigType.WEBHOOK) {
            url = this.eventConfig.value.url;
            bearer = this.eventConfig.value.bearer;
          }
          this.form.addControl(EventConfigType.WEBHOOK, this.fb.group({
            url: [url, Validators.required],
            bearer: [bearer],
          }));
        }
      });
    this.form.controls.type.updateValueAndValidity();
  }

  onDelete() {
    this.apiLavandierService.deleteEventConfig(this.eventConfig.id)
      .subscribe((() => this.activeModal.close()));
  }

  onValidate() {
    let value = {};
    if (this.form.value.type === EventConfigType.EMAIL) {
      value = {
        orderClassic: this.form.get(EventConfigType.EMAIL).value.orderClassic,
        orderCAndC: this.form.get(EventConfigType.EMAIL).value.orderCAndC,
      };
    } else if (this.form.value.type === EventConfigType.SMS) {
      value = {
        textClassic: this.form.get(EventConfigType.SMS).value.textClassic,
        textCAndC: this.form.get(EventConfigType.SMS).value.textCAndC,
      };
    } else if (this.form.value.type === EventConfigType.WEBHOOK) {
      value = {
        url: this.form.get(EventConfigType.WEBHOOK).value.url,
        bearer: this.form.get(EventConfigType.WEBHOOK).value.bearer,
      };
    }

    if (this.eventConfig) {
      this.apiLavandierService.putEventConfig(this.eventConfig.id, {
        type: this.form.value.type,
        event: this.form.value.event,
        value: value,
      })
        .subscribe((() => this.activeModal.close()));

    } else {
      this.apiLavandierService.postEventConfig({
        configId: this.configId,
        type: this.form.value.type,
        event: this.form.value.event,
        value: value,
      })
        .subscribe((() => this.activeModal.close()));
    }
  }
}
