export enum OrderStatusStatus {
  CREATE = 'CREATE',
  PICKUPASSIGNED = 'PICKUPASSIGNED',
  PICKUPUNASSIGNED = 'PICKUPUNASSIGNED',
  PICKUPSTARTED = 'PICKUPSTARTED',
  PICKUPDONE = 'PICKUPDONE',
  STOW = 'STOW',
  DELIVERYASKED = 'DELIVERYASKED',
  DELIVERYASSIGNED = 'DELIVERYASSIGNED',
  DELIVERYUNASSIGNED = 'DELIVERYUNASSIGNED',
  DELIVERYSTARTED = 'DELIVERYSTARTED',
  DELIVERYDONE = 'DELIVERYDONE',
  FINISH = 'FINISH',
  CANCEL = 'CANCEL',
}
