import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[lmImagePreview]'
})
export class ImagePreviewDirective implements OnChanges {
  @Input() private file: File;

  constructor(private elementRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.file) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => this.elementRef.nativeElement.src = reader.result;
    reader.readAsDataURL(this.file);
  }
}
