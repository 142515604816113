import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { CustomDatepickerI18nService, I18n } from '../../../../services/custom-datepicker-i18n.service';
import { ApiLavandierService } from '../../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { OrderTransportType } from '../../../../../lib/lib-shared/types/OrderTransportType';
import { Page } from '../../../../../lib/lib-ngx/utils/Page';
import { OrderTemporary } from '../../../../../lib/lib-shared/types/OrderTemporary';
import { PRICEMULTIPLIER } from '../../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-user-profile-orders',
  templateUrl: './user-profile-orders.component.html',
  styleUrls: ['./user-profile-orders.component.scss',
    '../user-profile.component.scss'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService}]
})
export class UserProfileOrdersComponent implements OnInit {
  public OrderTransportType = OrderTransportType;
  public PRICEMULTIPLIER = PRICEMULTIPLIER;
  public OrderTemporary = OrderTemporary;

  @Input('userProfileData') userProfileData;
  public userOrderList = [];
  public filterOrderForm: FormGroup;

  page = new Page();

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private apiLavandierService: ApiLavandierService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
  ) {
    this.page.setPageNumber(0);
    this.page.size = 10;
  }

  ngOnInit() {
    this.buildForm();
    this.filterOrderForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(data => {
          this.page.checkPage();
          return this.apiLavandierService.getOrderUserIdList(
            this.userProfileData.id,
            {
              temporary: data.temporary,
              createdAt: data.createdAt ? this.ngbDateParserFormatter.format(data.createdAt) : '',
              estimatedDeliveryAt: data.estimatedAt ? this.ngbDateParserFormatter.format(data.estimatedAt) : '',
              orderId: data.orderId,
              paid: data.paid.toString(),
            },
            this.page.toQueryObject());
        }),
      )
      .subscribe((orderList: any) => {
        this.page.totalElements = orderList.count;
        this.page.totalPages = this.page.totalElements / this.page.size;
        this.userOrderList = orderList.rows;
      });
    this.filterOrderForm.updateValueAndValidity();
  }

  setPage(pageInfo) {
    this.page.setPageNumber(pageInfo.offset);
    this.filterOrderForm.updateValueAndValidity();
  }

  buildForm() {
    this.filterOrderForm = this.fb.group({
      status: [''],
      createdAt: [null],
      estimatedAt: [null],
      orderId: [null],
      paid: [''],
      temporary: [''],
    });
  }

  getLastOrderTransportOfTypeIfValid(type: OrderTransportType, orderTransports) {
    let orderTransport = null;
    for (let i = orderTransports.length - 1; i >= 0; i--) {
      if (orderTransports[i].type === type) {
        orderTransport = orderTransports[i];
        break;
      }
    }

    if (orderTransport && !orderTransport.away && orderTransport.canceledAt) {
      return orderTransport;
    }
    return null;
  }
}
