import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  private filterTrad = ['=', '>', '<', 'entre', '!='];

  transform(value: any, args?: any): any {
    return this.filterTrad[value];
  }

}
