import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ArticleFamilyType } from '../../../../lib/lib-shared/types/ArticleFamilyType';

@Component({
  selector: 'lm-article-family',
  templateUrl: './article-family.component.html',
  styleUrls: ['./article-family.component.scss']
})
export class ArticleFamilyComponent implements OnInit {
  public ArticleFamilyType = ArticleFamilyType;

  public articleFamilyList = [];
  public articleFamilyForm: FormGroup;
  public articleFamilyToEdit = null;
  public articleFamilyToAdd = false;
  public userTypeList = [];

  constructor(
    private modalService: NgbModal,
    private apiLavandierService: ApiLavandierService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.loadArticleFamilyList();
    this.loadUserTypeList();
  }

  /**
   * Method called to load article family list
   */
  loadArticleFamilyList() {
    this.resetArticleFamilyForm();
    this.apiLavandierService.getArticleFamilyList()
      .subscribe(
        (data: Object[]) => this.articleFamilyList = data
      );
  }

  loadUserTypeList() {
    this.apiLavandierService.getUserTypeList()
      .subscribe(
        (data: Object[]) => {
          this.userTypeList = data;
          this.buildForm();
        }
      );
  }

  buildForm() {
    this.articleFamilyForm = this.fb.group({
      weight: ['', Validators.required],
      type: [ArticleFamilyType.PIECE, Validators.required],
      name: ['', Validators.required],
      color: ['#f5a623', Validators.required],
      userType: this.fb.group({})
    });

    const userTypeFormArray = this.articleFamilyForm.get('userType') as FormGroup;
    for (const userType of this.userTypeList) {
      userTypeFormArray.addControl(userType.id, new FormControl(false));
    }
  }

  /**
   * Method called to open edit template and patch value
   * @param articleFamily
   */
  openEditFamily(articleFamily) {
    this.articleFamilyToEdit = articleFamily;
    this.buildForm();

    this.articleFamilyForm.patchValue({
      weight: this.articleFamilyToEdit.weight,
      type: this.articleFamilyToEdit.type,
      name: this.articleFamilyToEdit.name,
      color: this.articleFamilyToEdit.color
    });

    this.articleFamilyToEdit.userTypes.forEach((userType) => {
      this.articleFamilyForm.get('userType').patchValue({
        [userType.id]: true
      });
    });
  }

  /**
   * Method called to reset articleFamilyForm, articleFamilyToAdd and articleFamilyToEdit
   */
  resetArticleFamilyForm() {
    this.articleFamilyToAdd = false;
    this.articleFamilyToEdit = null;
    this.buildForm();
  }

  /**
   * Method called to create an articleFamily, then it will post or put to server
   * @param onAdding true for adding and false for editing
   */
  onValidateArticleFamily(onAdding: boolean) {
    const articleFamily = {
      userTypeIdList: [],
      weight: this.articleFamilyForm.value.weight,
      type: this.articleFamilyForm.value.type,
      name: this.articleFamilyForm.value.name,
      color: this.articleFamilyForm.value.color
    };
    this.userTypeList.forEach((userType) => {
      if (this.articleFamilyForm.controls['userType'].value[userType.id]) {
        articleFamily.userTypeIdList.push(userType.id);
      }
    });
    onAdding ? this.onNewArticleFamily(articleFamily) : this.onEditArticleFamily(articleFamily);
  }

  /**
   * Method called to add a new article family
   */
  onNewArticleFamily(articleFamily) {
    this.apiLavandierService.postArticleFamily(articleFamily)
      .subscribe(() => this.ngOnInit());
  }

  /**
   * Method called to edit an article Family
   */
  onEditArticleFamily(articleFamily) {
    this.apiLavandierService.putArticleFamily(this.articleFamilyToEdit.id, articleFamily)
      .subscribe(() => this.ngOnInit());
  }
}
