import { Component, OnInit } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { CalendarDayType } from '../../../../lib/lib-shared/types/CalendarDayType';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-edit-calendar-day-modal',
  templateUrl: './edit-calendar-day-modal.component.html',
  styleUrls: ['./edit-calendar-day-modal.component.scss'],
})
export class EditCalendarDayModalComponent implements OnInit {
  public CalendarDayType = CalendarDayType;

  public date: NgbDate;
  public calendarDay = null;
  public calendarDayType = '';

  constructor(
    public ngbDateParserFormatter: NgbDateParserFormatter,
    public activeModal: NgbActiveModal,
    public apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    if (this.calendarDay !== null) {
      this.calendarDayType = this.calendarDay.type;
    }
  }

  onSubmit() {
    const calendarDay = {
      type: this.calendarDayType,
      date: this.ngbDateParserFormatter.format(this.date),
    };

    if (this.calendarDay === null && this.calendarDayType !== '') {
      this.newCalendarDay(calendarDay);
    } else if (this.calendarDay !== null) {
      this.editCalendarDay(calendarDay);
    }
  }

  newCalendarDay(calendarDay) {
    this.apiLavandierService.postCalendarDay(calendarDay)
      .subscribe(() => this.activeModal.close(true));
  }

  editCalendarDay(calendarDay) {
    this.apiLavandierService.putCalendarDay(this.calendarDay.id, calendarDay)
      .subscribe(() => this.activeModal.close(true));
  }
}
