import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeSlotType'
})
export class TimeSlotTypePipe implements PipeTransform {

  transform(id: string, timeSlotTypeList: any, key: string): any {
    return timeSlotTypeList.find(function(element) {
      return element.id === id;
    })[key];
  }

}
