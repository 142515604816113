import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';

import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { AuthService } from '../../../../lib/lib-ngx/web-services/auth.service';
import { QRCodeAPIKeyModalComponent } from '../../modals/qrcode-apikey-modal/qrcode-apikey-modal.component';

@Component({
  selector: 'lm-lavandier-details',
  templateUrl: './lavandier-details.component.html',
  styleUrls: ['./lavandier-details.component.scss']
})
export class LavandierDetailsComponent implements OnInit {
  public lavandierId = null;
  public lavandierDetails = null;
  public shopList = [];
  public roleList = [];
  public connectedLavandier = null;

  public adminForm: FormGroup;
  public addRoleForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private apiLavandierService: ApiLavandierService,
    private modalService: NgbModal,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.lavandierId = params.id;
        return forkJoin([
          this.apiLavandierService.getLavandierIdDetails(this.lavandierId),
          this.apiLavandierService.getShops(),
          this.apiLavandierService.getRoleList(),
          this.apiLavandierService.getLavandierIdDetails(this.authService.getDecodeToken().lavandierId),
        ])
          .subscribe(([lavandierDetails, shopList, roleList, connectedLavandier]: [Object, Object[], Object[], Object]) => {
            this.lavandierDetails = lavandierDetails;
            this.shopList = shopList;
            this.roleList = roleList;
            this.connectedLavandier = connectedLavandier;
            this.buildForm();
            this.patchValue();
          });
      }
    });
  }

  buildForm() {
    this.adminForm = this.fb.group({
      admin: [false],
      internalAdmin: [false],
    });

    this.addRoleForm = this.fb.group({});
    this.roleList.forEach((role) => {
      this.addRoleForm.addControl(role.id.toString(), new FormControl({value: false, disabled: true}));
    });
  }

  patchValue() {
    this.adminForm.patchValue({
      admin: this.lavandierDetails.admin,
      internalAdmin: this.lavandierDetails.internalAdmin,
    });

    this.roleList.forEach((role) => {
      if (this.connectedLavandier.roleList.some(r => r.id === role.id)) {
        this.addRoleForm.controls[role.id.toString()].enable();
      }
    });

    this.lavandierDetails.roleList.forEach((role) => {
      this.addRoleForm.patchValue({[role.id.toString()]: {value: true, disabled: true}});
    });
  }

  /**
   * Method called after a lavandier's current lmp change
   */
  changeLavandierLMP(shopId) {
    const lmp = shopId === '' ? null : shopId;
    this.apiLavandierService.putLavandier(this.lavandierDetails.id, {shopId: lmp})
      .subscribe(() => this.ngOnInit());
  }

  onFreezeAccount(freezeLavandier: boolean) {
    const confirmationModal = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
    if (!this.lavandierDetails.user.enabled) {
      confirmationModal.componentInstance.confirmationDesc = 'Voulez-vous vraiment dégeler le compte de cet utilisateur ?';
      confirmationModal.componentInstance.validateButtonText = 'Dégeler le compte';
    } else {
      confirmationModal.componentInstance.confirmationDesc = 'Voulez-vous vraiment geler le compte de cet utilisateur ?';
      confirmationModal.componentInstance.validateButtonText = 'Geler le compte';
    }
    confirmationModal.componentInstance.cancelButtonText = 'Annuler';

    if (freezeLavandier) {
      if (!this.lavandierDetails.enabled) {
        confirmationModal.componentInstance.confirmationDesc = 'Voulez-vous vraiment dégeler le compte de ce lavandier ?';
        confirmationModal.componentInstance.validateButtonText = 'Dégeler le compte';
      } else {
        confirmationModal.componentInstance.confirmationDesc = 'Voulez-vous vraiment geler le compte de ce lavandier ?';
        confirmationModal.componentInstance.validateButtonText = 'Geler le compte';
      }
    }
    confirmationModal.result
      .then((result) => {
        if (result) {
          if (freezeLavandier) {
            this.apiLavandierService.putLavandier(this.lavandierDetails.id, {enabled: !this.lavandierDetails.enabled})
              .subscribe(() => this.ngOnInit());
          } else {
            this.apiLavandierService.putUserIdEnabled(this.lavandierDetails.user.id, {enabled: !this.lavandierDetails.user.enabled})
              .subscribe(() => this.ngOnInit());
          }
        }
      })
      .catch(error => {
      });
  }

  editAdminType() {
    this.apiLavandierService.putLavandier(this.lavandierDetails.id, {
      admin: this.adminForm.value.admin,
      internalAdmin: this.adminForm.value.internalAdmin,
    })
      .subscribe(() => this.ngOnInit());
  }

  editRoleList() {
    const RoleIdList = [];
    this.roleList.forEach((role) => {
      if (this.addRoleForm.controls[role.id.toString()].value) {
        RoleIdList.push(role.id);
      }
    });

    this.apiLavandierService.putLavandier(this.lavandierDetails.id, {roleIdList: RoleIdList})
      .subscribe(() => this.ngOnInit());
  }

  hasQRCodeEnabled() {
    return this.lavandierDetails.qrCodeTokenList.some(qrCodeToken => qrCodeToken.enabled);
  }

  disableQRCode() {
    const confirmationModal = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
    confirmationModal.componentInstance.confirmationDesc = 'Êtes-vous sûr de vouloir désactiver la connexion par QRCode de cet utilisateur ?';
    confirmationModal.componentInstance.validateButtonText = 'Confirmer';
    confirmationModal.componentInstance.cancelButtonText = 'Annuler';
    confirmationModal.result
      .then((result) => {
        if (result) {
          this.apiLavandierService.putLavandierQRCodeDisable(this.lavandierDetails.id)
            .subscribe(() => this.ngOnInit());
        }
      })
      .catch(() => null);
  }

  newQRCode() {
    const confirmationModal = this.modalService.open(ConfirmationModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    confirmationModal.componentInstance.confirmationDesc = `La création d'un nouveau QRCode désactivera son ancien s'il en possède un, vous confirmez ?`;
    confirmationModal.componentInstance.validateButtonText = 'Confirmer';
    confirmationModal.componentInstance.cancelButtonText = 'Annuler';
    confirmationModal.result
      .then((result) => {
        if (result) {
          this.apiLavandierService.postLavandierQRCode(this.lavandierDetails.id)
            .subscribe((qrCodeToken: any) => {
              const qrcodeAPIKeyModal = this.modalService.open(QRCodeAPIKeyModalComponent, {
                size: 'lg',
                centered: true,
                backdrop: 'static',
                keyboard: false,
              });
              qrcodeAPIKeyModal.componentInstance.pdfUrlObservable = this.apiLavandierService.getDocQRCodeTokenType(qrCodeToken.id, {type: 'pdf'});
              confirmationModal.result
                .then(() => this.ngOnInit())
                .catch(() => this.ngOnInit());
            });
        }
      })
      .catch(() => null);
  }
}
