import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lm-order-stored-modal',
  templateUrl: './order-stored-modal.component.html',
  styleUrls: ['./order-stored-modal.component.scss']
})
export class OrderStoredModalComponent implements OnInit {
  public order;
  public trackingSheetStorageList = [];

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
  }
}
