import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { EditShopHoursModalComponent } from '../../modals/edit-shop-hours-modal/edit-shop-hours-modal.component';

@Component({
  selector: 'lm-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})
export class StoresComponent implements OnInit {
  public storeList = [];
  public addStore = false;

  constructor(
    private apiLavandierService: ApiLavandierService,
    public sanitizer: DomSanitizer,
    public modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.getShopList();
  }

  getShopList() {
    this.apiLavandierService.getShops().subscribe(
      (storeList: Object[]) => this.storeList = storeList
    );
  }

  /**
   * Method called after a click in add new store in add store component
   * @param event
   */
  onAddStore(event) {
    this.ngOnInit();
    this.addStore = false;
  }

  onEditHours(shop) {
    const editShopHoursModal = this.modalService.open(EditShopHoursModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    editShopHoursModal.componentInstance.shop = shop;
    editShopHoursModal.result
      .then((hours) => {
        this.apiLavandierService.putShopHours(shop.id, {hours: hours})
          .subscribe(() => this.ngOnInit());
      })
      .catch(() => null);
  }
}
