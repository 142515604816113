import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { FormControl } from '@angular/forms';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ErrorService } from '../../../../lib/lib-ngx/services/error.service';
import { ErrorMessage } from '../../../../lib/lib-shared/ErrorMessage';

@Component({
  selector: 'lm-quarterback',
  templateUrl: './quarterback.component.html',
  styleUrls: ['./quarterback.component.scss']
})
export class QuarterbackComponent implements OnInit {
  @ViewChild('trackingSheetNumberInput') public trackingSheetNumberInput: ElementRef;

  @Input() public orderId = null;
  public order = null;
  @Input() public orderTransportId = null;
  public orderTransport = null;

  public trackingSheetStorageList: any[] = [];

  public trackingSheetFormControl = new FormControl();

  @Output() allTsArePrepared = new EventEmitter<boolean>();
  @Output() updateOrder = new EventEmitter<any>();

  constructor(
    private apiLavandierService: ApiLavandierService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.loadData();

    this.trackingSheetFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(ref => {
          const trackingSheetId = parseInt(ref, 10);
          this.trackingSheetFormControl.disable({emitEvent: false});

          let trackingSheetStorage;
          if (ref && ref !== '' && (trackingSheetStorage = this.trackingSheetStorageList.find(trackingSheetStorage => trackingSheetStorage.trackingSheetId === trackingSheetId))) {
            return this.apiLavandierService.putTrackingSheetStorageIdPrepared(trackingSheetStorage.id, {missing: false});
          }
          return of(null);
        }),
      )
      .subscribe(data => {
        this.trackingSheetFormControl.enable({emitEvent: false});
        this.trackingSheetFormControl.reset();
        if (data && !this.errorService.manageError(data)) {
          this.loadData();
        }
      });
  }

  loadData() {
    if (this.orderId) {
      return forkJoin([
        this.apiLavandierService.getOrderIdDetails(this.orderId),
      ])
        .subscribe(([order]: [any]) => {
          this.setOrder(order);
          const trackingSheetStorageList = [];

          this.apiLavandierService.getTrackingSheetOrderId(order.id)
            .subscribe((trackingSheetList: any[]) => {
              trackingSheetList.forEach(trackingSheet => {
                trackingSheetStorageList.push(trackingSheet.trackingSheetStorageList[trackingSheet.trackingSheetStorageList.length - 1]);
              });
              this.trackingSheetStorageList = trackingSheetStorageList;
              this.allTsArePrepared.emit(!this.trackingSheetStorageList.some(ts => !ts.preparedAt));
              this.getTrackingSheetNumberInputFocus();
            });
        });
    }

    if (this.orderTransportId) {
      return this.apiLavandierService.getOrderTransportDetails(this.orderTransportId)
        .subscribe((orderTransport: any) => {
          if (orderTransport.canceledAt || orderTransport.doneAt) {
            this.errorService.showModal(ErrorMessage.ORDERTRANSPORT_NOT_QUARTERBACKABLE);
            return;
          }
          this.orderTransport = orderTransport;
          this.trackingSheetStorageList = orderTransport.trackingSheetStorageList;
          this.allTsArePrepared.emit(!this.trackingSheetStorageList.some(ts => !ts.preparedAt));
          return this.apiLavandierService.getOrderIdDetails(orderTransport.orderId)
            .subscribe((order: any) => {
              if (order.orderTransports[order.orderTransports.length - 1].id !== orderTransport.id) {
                this.errorService.showModal(ErrorMessage.ORDERTRANSPORT_NOT_QUARTERBACKABLE);
                return;
              }
              this.setOrder(order);
              this.getTrackingSheetNumberInputFocus();
            });
        });
    }
  }

  setOrder(order) {
    this.order = order;
    this.updateOrder.emit(order);
  }

  getTrackingSheetNumberInputFocus() {
    setTimeout(() => this.trackingSheetNumberInput.nativeElement.focus());
  }

  missingTrackingSheetStorage(trackingSheetStorage) {
    this.apiLavandierService.putTrackingSheetStorageIdPrepared(trackingSheetStorage.id, {missing: true})
      .subscribe((data: any) => {
        if (!this.errorService.manageError(data)) {
          this.loadData();
        }
      });
  }
}
