import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { ApiLavandierService } from '../../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { IArticleOptionType } from '../../../../../lib/lib-ngx/web-services/api-lavandier.service.type';

@Component({
  selector: 'lm-article-option-type',
  templateUrl: './article-option-type.component.html',
  styleUrls: ['./article-option-type.component.scss', '../article-setting.component.scss']
})
export class ArticleOptionTypeComponent implements OnInit {
  @Output('updateArticleOptionTypeList') updateArticleOptionTypeList = new EventEmitter<any>();

  public articleOptionTypeList = [];
  public articleOptionTypeFormControl = new FormControl('', Validators.required);
  public articleOptionTypeToEdit = null;
  public onAddArticleOptionType = false;

  constructor(
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.loadArticleOptionTypeList();
  }

  /**
   * Method called to get the article option type list
   */
  loadArticleOptionTypeList() {
    this.resetArticleOptionTypeFormControl();
    this.apiLavandierService.getArticleOptionTypeList()
      .subscribe(
        (data: Object[]) => {
          this.articleOptionTypeList = data;
          this.updateArticleOptionTypeList.emit(this.articleOptionTypeList);
        }
      );
  }

  /**
   * Method called to reset the articleOptionType formControl
   */
  resetArticleOptionTypeFormControl() {
    this.articleOptionTypeFormControl = new FormControl('', Validators.required);
    this.articleOptionTypeToEdit = null;
    this.onAddArticleOptionType = false;
  }

  /**
   * Method called to open the edit template, also patch value for articleOptionType formControl
   * @param articleOptionType
   */
  openEditArticleOptionType(articleOptionType) {
    this.articleOptionTypeToEdit = articleOptionType;
    this.articleOptionTypeFormControl.patchValue(this.articleOptionTypeToEdit.name);
  }

  /**
   * Method called to create an articleOptionType; then it will post or put depending on onAdding variable
   * @param onAdding true to post and false to put
   */
  onValidateArticleOptionType(onAdding: boolean) {
    const articleOptionType: IArticleOptionType = {
      name: this.articleOptionTypeFormControl.value
    };
    onAdding ? this.onNewArticleOptionType(articleOptionType) : this.onEditArticleOptionType(articleOptionType);
  }

  /**
   * Method called to create a new articleOptionType
   * @param articleOptionType
   */
  onNewArticleOptionType(articleOptionType) {
    this.apiLavandierService.postArticleOptionType(articleOptionType)
      .subscribe(() => this.ngOnInit());
  }

  /**
   * method called to edit an articleOptionType
   * @param articleOptionType
   */
  onEditArticleOptionType(articleOptionType) {
    this.apiLavandierService.putArticleOptionType(this.articleOptionTypeToEdit.id, articleOptionType)
      .subscribe(() => this.ngOnInit());
  }
}
