import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { ConfigInvoiceTemplate } from '../../../../lib/lib-shared/types/ConfigInvoiceTemplate';
import { PRICEMULTIPLIER } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-user-type-setting',
  templateUrl: './user-type-setting.component.html',
  styleUrls: ['./user-type-setting.component.scss']
})
export class UserTypeSettingComponent implements OnInit {
  public PRICEMULTIPLIER = PRICEMULTIPLIER;
  public ConfigInvoiceTemplate = ConfigInvoiceTemplate;

  public userTypeForm: FormGroup;
  public userTypeMap = new Map();
  public onShowForm = false;

  constructor(
    private apiLavandierService: ApiLavandierService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.loadUserTypeMap();
  }

  buildForm() {
    this.onShowForm = false;
    this.userTypeForm = this.fb.group({
      parentUserType: [''],
      name: ['', Validators.required],
      configInvoiceTemplate: [null, Validators.required],
    });
  }

  /**
   * Method called to get the userType map
   */
  loadUserTypeMap() {
    this.apiLavandierService.getUserTypeList()
      .pipe(map(ListToMap.convert))
      .subscribe((userTypeMap: Map<number, Object>) => this.userTypeMap = userTypeMap);
  }

  /**
   * Method called to reset the userTypeForm and open the userType form
   */
  openAddUserType() {
    this.buildForm();
    this.onShowForm = true;
  }

  /**
   * Method called to create a userType Object, then it'll post
   */
  onValidateUserType() {
    this.apiLavandierService.postUserType({
      parentUserTypeId: this.userTypeForm.value.parentUserType.id,
      name: this.userTypeForm.value.name,
      sponsorship: false,
      important: 0,
      noShowException: 0,
      noShowPrice: 0,
      storageException: 0,
      storagePrice: 0,
      storageDeadLine: 0,
      storageDeadLineEvent1: 0,
      storageDeadLineEvent2: 0,
      sponsorshipGodfatherDiscount: 0,
      sponsorshipGodsonDiscount: 0,
      sponsorshipMinOrder: 0,
      configInvoiceTemplate: this.userTypeForm.value.configInvoiceTemplate,
    })
      .subscribe((() => this.ngOnInit()));
  }
}
