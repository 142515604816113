import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { ArticleUnity } from '../../../../lib/lib-shared/types/ArticleUnity';

@Component({
  selector: 'lm-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss']
})
export class ArticleListComponent implements OnInit {
  public ArticleUnity = ArticleUnity;

  public articleList = [];
  public articleTempList = [];
  public articleFamilyMap = new Map();
  public articleForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.loadArticleList();
    this.loadArticleFamilyMap();
  }

  buildForm() {
    this.articleForm = this.fb.group({
      articleName: [''],
      articleFamily: [''],
      articleUnity: ['']
    });
  }

  /**
   * Method called to get article list
   */
  loadArticleList() {
    this.apiLavandierService.getArticleList()
      .subscribe(
        (data: Object[]) => {
          this.articleList = data;
          this.articleTempList = [...this.articleList];
        }
      );
  }

  /**
   * Method called to get article family map
   */
  loadArticleFamilyMap() {
    this.apiLavandierService.getArticleFamilyList()
      .pipe(map(ListToMap.convert))
      .subscribe(
        (data: Map<number, Object>) => this.articleFamilyMap = data
      );
  }

  /**
   * Method which will filter article list by name, family and unit
   */
  onFilterArticleList() {
    const filterValue = this.articleForm.value;
    this.articleList = this.articleTempList.filter((article) => {
      return (filterValue.articleName === '' || article.name.toLowerCase().includes(filterValue.articleName.toLowerCase())) &&
        (filterValue.articleFamily === '' || filterValue.articleFamily === article.articleFamilyId.toString()) &&
        (filterValue.articleUnity === '' || filterValue.articleUnity === article.unity);
    });
  }
}
